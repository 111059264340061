import { Tabs } from 'antd';
import TabPane from 'antd/es/tabs/TabPane';
import { Layout } from 'commons';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import BalanceTab from './tabs/BalanceTab';
import CashFlowTab from './tabs/CashFlowTab';
import MainBookTab from './tabs/MainBookTab';

const AccountPlanPage = ({ intl }) => {
    return (
        <Layout className='entries_journal_page' title={<FormattedMessage id='navigation.account_plan' />}>
            <Tabs>
                <TabPane key='mainBook' tab={<FormattedMessage id='account_plan.main_book' />}>
                    <MainBookTab />
                </TabPane>
                <TabPane key='balance' disabled tab={<FormattedMessage id='account_plan.balance' />}>
                    <BalanceTab />
                </TabPane>
                <TabPane key='cashFlow' disabled tab={<FormattedMessage id='account_plan.cash_flow' />}>
                    <CashFlowTab />
                </TabPane>
            </Tabs>
        </Layout>
    );
};

export default injectIntl(AccountPlanPage);
