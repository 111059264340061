import { PlusOutlined } from '@ant-design/icons';
import { Input, Modal, notification, Table } from 'antd';
import { Catcher } from 'commons';
import { Barcode } from 'components';
import { MODALS, resetModal, setModal } from 'core/modals/duck';
import { StoreProductModal } from 'modals';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { fetchAPI } from 'utils';
import Styles from './styles.m.css';

const mapStateToProps = state => ({
    user: state.auth
});

const mapDispatchToProps = {
    setModal,
    resetModal
};

@withRouter
@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class SetBarcodeModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            modalInput: '',
            modalVisible: false,
            confirmAction: undefined,
            table: undefined,
            modalData: [],
            selectedRowId: undefined,
            tables: []
        };

        this._addNewProduct = this._addNewProduct.bind(this);

        this.columns = [
            {
                key: 'id',
                dataIndex: 'id',
                render: (id, row) => {
                    return row.displayId || id;
                }
            },
            {
                key: 'name',
                dataIndex: 'name'
            },
            {
                key: 'additional',
                dataIndex: 'additional'
            },
            {
                key: 'barcode',
                dataIndex: 'barcode'
            }
        ];
    }

    _showModal = async () => {
        let modalData = [];
        const tableData = await fetchAPI('GET', 'store_products');
        modalData = tableData.list.map(elem => {
            return {
                id: elem.id,
                displayId: elem.code,
                name: elem.name,
                additional: elem.brand.name,
                barcode: elem.barcode
            };
        });
        this.setState({
            modalVisible: true,
            modalData
        });
    };

    _hideModal = async () => {
        this.setState({
            modalInput: '',
            modalVisible: false,
            confirmAction: undefined,
            table: undefined,
            modalData: [],
            selectedRowId: undefined
        });
        this.props.hideModal();
    };

    _setBarcode = async () => {
        const { selectedRowId } = this.state;
        const { barcode } = this.props;
        try {
            await fetchAPI(
                'POST',
                'barcodes',
                undefined,
                [
                    {
                        referenceId: String(selectedRowId),
                        table: 'STORE_PRODUCTS',
                        customCode: barcode
                    }
                ],
                { handleErrorInternally: true }
            );
            notification.success({
                message: this.props.intl.formatMessage({ id: 'barcode.barcode_setted' })
            });
        } catch (e) {
            notification.error({
                message: this.props.intl.formatMessage({ id: 'barcode.barcode_already_set' })
            });
        }
    };

    _handleOk = async () => {
        const { selectedRowId } = this.state;
        await this._setBarcode();
        await this.props.confirmAction(selectedRowId);
        await this._hideModal();
    };

    _addNewProduct = async id => {
        await this.props.confirmAction(id);
        this._hideModal();
    };

    componentDidUpdate(prevProps, prevState) {
        if (this.props.visible && !prevProps.visible) {
            this._showModal();
        }
    }

    render() {
        const {
            user,
            intl: { formatMessage },
            barcode,
            visible,
            setModal
        } = this.props;
        const { modalInput, modalVisible, confirmAction, modalData, selectedRowId } = this.state;

        return (
            <Catcher>
                <Modal
                    destroyOnClose
                    okButtonProps={{
                        disabled: !selectedRowId
                    }}
                    onCancel={this._hideModal}
                    onOk={this._handleOk}
                    style={{
                        minWidth: 580
                    }}
                    title={<FormattedMessage id='barcode.search' />}
                    visible={visible}
                    width='fit-content'
                    zIndex={300}
                >
                    <div className={Styles.modalInput}>
                        <Input
                            data-qa='input_modalInput_set_barcode_modal'
                            autoFocus
                            onChange={({ target }) => {
                                this.setState({
                                    modalInput: target.value
                                });
                            }}
                            placeholder={formatMessage({ id: 'barcode.search_by_fields' })}
                            value={modalInput}
                        />
                        <Barcode
                            data-qa='barcode_modalInput_set_barcode_modal'
                            iconStyle={{
                                marginLeft: 8,
                                fontSize: 24
                            }}
                            onConfirm={value =>
                                this.setState({
                                    modalInput: value
                                })
                            }
                            value={modalInput}
                            zIndex={500}
                        />
                        <PlusOutlined
                            data-qa='icon_onSubmit_set_barcode_modal'
                            onClick={() => {
                                this._hideModal();
                                setModal(MODALS.STORE_PRODUCT, {
                                    barcode,
                                    onSubmit: this._addNewProduct
                                });
                            }}
                            style={{
                                fontSize: 24,
                                marginLeft: 8
                            }}
                        />
                    </div>
                    <div>
                        <Table
                            bordered
                            columns={this.columns}
                            dataSource={
                                modalInput
                                    ? modalData.filter(
                                          ({ id, displayId, name, additional, barcode }) => {
                                              const input = modalInput.toLowerCase();

                                              return (
                                                  String(id)
                                                      .toLowerCase()
                                                      .replace(/\W/g, '')
                                                      .includes(input) ||
                                                  String(displayId)
                                                      .toLowerCase()
                                                      .replace(/\W/g, '')
                                                      .includes(input) ||
                                                  String(name)
                                                      .toLowerCase()
                                                      .replace(' ', '')
                                                      .includes(input) ||
                                                  String(additional)
                                                      .toLowerCase()
                                                      .replace(' ', '')
                                                      .includes(input) ||
                                                  String(barcode).toLowerCase().includes(input)
                                              );
                                          }
                                      )
                                    : modalData
                            }
                            onRow={(record, rowIndex) => {
                                return {
                                    onClick: event => {
                                        this.setState({
                                            selectedRowId: record.id
                                        });
                                    }
                                };
                            }}
                            rowClassName={(record, index) => {
                                if (record.id == selectedRowId) {
                                    return Styles.selectedRow;
                                }
                            }}
                            rowKey='id'
                            style={{
                                whiteSpace: 'pre'
                            }}
                        />
                    </div>
                </Modal>
                <StoreProductModal />
            </Catcher>
        );
    }
}
