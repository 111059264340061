import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Radio, Select } from 'antd';
import classNames from 'classnames/bind';
import { Layout } from 'commons';
import RTLContainer from 'containers/RTLContainer';
import { fetchUniversalFiltersForm } from 'core/forms/universalFiltersForm/duck';
import { setModal } from 'core/modals/duck';
import { fetchOrders, setOrdersDaterangeFilter, setOrdersStatusFilter, setUniversalFilter } from 'core/orders/duck';
import _ from 'lodash';
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import book from 'routes/book';
import { fetchAPI, goTo } from 'utils';
import { accesses, grants, isGrantAccessed } from 'utils/grants';
import Styles from './styles.m.css';

const RadioButton = Radio.Button;
const RadioGroup = Radio.Group;
const { Option } = Select;

const cx = classNames.bind(Styles);

const filterSelectOptions = [
    {
        message: 'all',
        value: 'not_complete,required,call,reserve,processing,approve,progress,success'
    },
    {
        message: 'records',
        value: 'approve'
    },
    {
        message: 'not_complete',
        value: 'not_complete,required,call,reserve'
    },
    {
        message: 'repairs',
        value: 'progress'
    },
    {
        message: 'done',
        value: 'success'
    },
    {
        message: 'required',
        value: 'required'
    },
    {
        message: 'reserve',
        value: 'reserve'
    },
    {
        message: 'call',
        value: 'call'
    },
    {
        message: 'cancels',
        value: 'cancel'
    },
    {
        message: 'stop',
        value: 'stop'
    },
    {
        message: 'processing',
        value: 'processing'
    }
];

const mapStateToProps = state => ({
    ordersDaterangeFilter: state.orders.filter.daterange,
    filter: state.orders.filter,
    universalStats: state.orders.universalStats,
    universalFilter: state.orders.universalFilter,
    collapsed: state.ui.collapsed,
    user: state.auth,
    daterange: state.orders.daterange,
    isMobile: state.ui.views.isMobile
});

const mapDispatchToProps = {
    fetchOrders,
    setOrdersDaterangeFilter,
    setModal,
    fetchUniversalFiltersForm,
    setUniversalFilter,
    setOrdersStatusFilter
};

@withRouter
@connect(mapStateToProps, mapDispatchToProps)
export default class RTLPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            indicateStatus: 'not_complete',
            loading: false
        };
    }

    createRtlDoc = async () => {
        const res = await fetchAPI('POST', '/retails', undefined, undefined, {
            handleErrorInternally: true
        });

        if (res && res.id) {
            goTo(`${book.rtl}/${res.id}`);
        }
    };

    fetchRtlDocs = async payoad => {
        this.setState({
            loading: true
        });
        const res = await fetchAPI('GET', '/retails', { ...payoad }, undefined, {
            handleErrorInternally: true
        });
        this.setState({
            rtlDocs: res.orders,
            rtsStats: res.stats.countOrders,
            loading: false
        });
    };

    changeStatusAction = async st => {
        this.setState({
            indicateStatus: st
        });
        await this.fetchRtlDocs({ status: st, page: 1, pageSize: 25 });
    };

    handleSearch = _.debounce(query => {
        this.fetchRtlDocs({ query, page: 1, pageSize: 25 });
    }, 1000);

    // eslint-disable-next-line
    render() {
        const { user, isMobile } = this.props;
        const { rtlDocs, rtsStats, indicateStatus, loading } = this.state;

        return (
            <Layout
                controls={
                    <div className={Styles.controls}>
                        <div className={Styles.buttonGroup}>
                            <Button
                                onClick={() => this.changeStatusAction('not_complete')}
                                type={indicateStatus == 'not_complete' ? 'primary' : undefined}
                            >
                                {!isMobile ? <FormattedMessage id='not_complete' /> : <FormattedMessage id='storage_document.status_created' />}
                            </Button>
                            <Button
                                onClick={() => this.changeStatusAction('success')}
                                type={indicateStatus == 'success' ? 'primary' : undefined}
                            >
                                {!isMobile ? <FormattedMessage id='order_docs.status_done' /> : <FormattedMessage id='storage_document.status_confirmed' />}
                            </Button>
                            <Button
                                icon={isMobile && <DeleteOutlined />}
                                onClick={() => this.changeStatusAction('cancel')}
                                type={indicateStatus == 'cancel' ? 'primary' : undefined}
                            >
                                {!isMobile && <FormattedMessage id='details_table.deleted' />}
                            </Button>
                            <Button
                                icon={isMobile && <PlusOutlined />}
                                disabled={!isGrantAccessed(user, grants.OPERATIONS_ORDER_DOCUMENT, accesses.ROWO)}
                                onClick={() => this.createRtlDoc()}
                                type='primary'
                            >
                                {!isMobile && <FormattedMessage id='rtl.add_rtl' />}
                            </Button>
                        </div>
                    </div>
                }
                // description={<FormattedMessage id='orders-page.description' />}
                title={<FormattedMessage id='new-document-page.item.rtl' />}
            >
                <React.Fragment>
                    <RTLContainer
                        fetchRtlDocs={this.fetchRtlDocs}
                        handleSearch={this.handleSearch}
                        loading={loading}
                        rtlDocs={rtlDocs}
                        rtsStats={rtsStats}
                    />
                </React.Fragment>
            </Layout>
        );
    }
}
