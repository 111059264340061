/* eslint-disable react/sort-comp */
/* eslint-disable max-classes-per-file */
import { Form } from '@ant-design/compatible';
import {
    CheckOutlined,
    EditOutlined,
    ExclamationOutlined,
    FormOutlined,
    MenuOutlined,
    MessageOutlined,
    MinusOutlined,
    PlusOutlined
} from '@ant-design/icons';
import { Button, Input, InputNumber, Modal, Popover, Select, Tabs, notification } from 'antd';
import {
    addNewDiagnosticRow,
    getPartProblems,
    sendDiagnosticAnswer,
    sendMessage
} from 'core/forms/orderDiagnosticForm/saga';
import { onChangeOrderForm, selectCashSum, setClientSelection } from 'core/forms/orderForm/duck';
import { MODALS, resetModal, setModal } from 'core/modals/duck';
import ChassisDiagnosticTab from 'forms/OrderForm/OrderFormTabs/components/ChassisDiagnosticTab';
import _ from 'lodash';
import { ToSuccessModal, VinCodeModal } from 'modals';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { buildStoreGroupsTree, fetchAPI, isForbidden, permissions, withReduxForm } from 'utils';
import { grants, isGrantAccessed } from 'utils/grants';
import {
    DetailsTable,
    DiscountPanel,
    HistoryTable,
    IntakeTab,
    OrderMediaTab,
    RequestsTable,
    ServicesTable
} from '../OrderForm/OrderFormTables';
import { PhotoButton } from '../OrderForm/OrderFormTables/DiagnosticTable';
import { OrderMobileFormFields } from './OrderMobileFormFields';
import Styles from './styles.m.css';

const { Option } = Select;
const { TabPane } = Tabs;

@injectIntl
@withReduxForm({
    name: 'orderForm',
    // debouncedFields: [
    //     "comment",
    //     "recommendation",
    //     "vehicleCondition",
    //     "businessComment",
    // ],
    actions: {
        change: onChangeOrderForm,
        setClientSelection,
        setModal,
        resetModal
    },
    mapStateToProps: state => ({
        modal: state.modals.modal,
        user: state.auth,
        cashSum: selectCashSum(state),
        schedule: state.forms.orderForm.schedule,
        stationLoads: state.forms.orderForm.stationLoads,
        addClientFormData: state.forms.addClientForm.data,
        isMobile: state.ui.views.isMobile
    })
})
export class MobileRecordForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            detailsTreeData: undefined,
            fetchedOrder: undefined,
            details: undefined,
            labors: [],
            suppliers: [],
            crews: []
        };

        this.setActiveTab = this.setActiveTab.bind(this);
    }

    componentDidMount() {
        document.querySelector('.ant-tabs-nav').scrollIntoView({ behavior: 'smooth', block: 'end' });
        this.fetchSuppliersOptions();
        this.fetchLaborsAndDetails();
        if (isGrantAccessed(this.props.user, grants.CREWS)) {
            this.fetchCrewsOptions();
        }
        this.fetchLabors();
        this._reloadOrderForm();
    }

    setActiveTab(tab, action) {
        this.setState({
            activeKey: tab,
            action
        });
    }

    fetchSuppliersOptions = async (query, all = true) => {
        const suppliers = await fetchAPI(
            'GET',
            'business_suppliers',
            {
                showHidden: false,
                query,
                all
            },
            null,
            { handleErrorInternally: true }
        );
        this.setState({
            suppliers
        });
    };

    fetchCrewsOptions = async crewName => {
        const res = await fetchAPI(
            'GET',
            'crews',
            {
                filters: {
                    crewName
                }
            },
            null,
            { handleErrorInternally: true }
        );
        this.setState({
            crews: res && res.crews.filter(({ crewMembers }) => _.get(crewMembers, 'length'))
        });
    };

    fetchLaborsAndDetails = async () => {
        const details = await fetchAPI('GET', '/store_groups', undefined, undefined, {
            handleErrorInternally: true
        });
        const { labors } = await fetchAPI('GET', 'labors');
        await this.setState({ details, labors, detailsTreeData: buildStoreGroupsTree(details) });
    };

    _updateOrderField = field => {
        if (field == 'duration') {
            let hours = 0;
            this.orderServices.map(elem => {
                if (elem.agreement != 'REJECTED') hours += elem.count;
            });

            if (hours > 8) {
                message.warning('Кількість годин більше 8. ');
                hours = 8;
            }

            field = { duration: Math.round(hours * 10) / 10 };
        }

        const that = this;
        const token = localStorage.getItem('_my.carbook.pro_token');
        let url = __API_URL__;
        const params = `/orders/${this.props.orderId}`;
        url += params;
        fetch(url, {
            method: 'PUT',
            headers: {
                Authorization: token,
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(field)
        })
            .then(function (response) {
                if (response.status !== 200) {
                    return Promise.reject(new Error(response.statusText));
                }

                return Promise.resolve(response);
            })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                that._reloadOrderForm();
                // that.props.fetchOrderForm(that.props.orderId);
            })
            .catch(function (error) {
                console.log('error', error);
            });
    };

    _reloadOrderForm = callback => {
        const that = this;
        const token = localStorage.getItem('_my.carbook.pro_token');
        let url = __API_URL__;
        const params = `/orders/${this.props.orderId}?onlyLabors=true&onlyDetails=true`;
        url += params;
        fetch(url, {
            method: 'GET',
            headers: {
                Authorization: token
            }
        })
            .then(function (response) {
                if (response.status !== 200) {
                    return Promise.reject(new Error(response.statusText));
                }

                return Promise.resolve(response);
            })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                if (callback) callback(data);
                that.setState({
                    fetchedOrder: data
                });
            })
            .catch(function (error) {
                console.log('error', error);
            });
    };

    fetchLabors = async query => {
        const { labors } = await fetchAPI('GET', 'labors', { search: query, page: 1, pageSize: 25, all: true }, null, {
            handleErrorInternally: true
        });
        this.setState({ labors });
    };

    componentDidUpdate(prevProps, prevState) {
        // if (!this.state.fetchedOrder) {
        //     this._reloadOrderForm();
        // }
        // if (!this.state.details) {
        //     this.fetchLaborsAndDetails();
        // }
        // if (!this.state.detailsTreeData && this.state.details && this.state.details.length) {
        //     this.buildStoreGroupsTree();
        // }
    }

    render() {
        const {
            orderFetching,
            isMobile,
            orderStatus,
            selectedClient,
            orderFormRef,
            onStatusChange,
            user,
            orderTasks,
            orderHistory,
            orderId,
            allDetails,
            onClose,
            employees,
            fetchOrderForm,
            setAddClientModal,
            form,
            schedule,
            modal,
            setModal,
            resetModal,
            addClientFormData,
            stationLoads,
            searchClientsResult,
            managers,
            stations,
            cashSum,
            setClientSelection,
            orderDiagnostic,
            modificationId,
            normHourPrice,
            errors,
            vehicles,
            handleSearchClientVehicles,
            isRetailOrder,
            orderNum
        } = this.props;
        const { formatMessage } = this.props.intl;

        const { fetchedOrder, labors, suppliers } = this.state;
        const order = _.get(fetchedOrder, 'order', {});

        const clientVehicleTypeId = _.get(fetchedOrder, 'order.clientVehicleTypeId');
        const clientVehicleRadius = _.get(fetchedOrder, 'order.clientVehicleRadius');

        const vehicle = vehicles.find(vehicle => vehicle.id == this.props.order.clientVehicleId) || undefined;
        const clientVehicleVin = order.clientVehicleVin || _.get(vehicle, 'vin');

        const {
            totalSum,
            totalSumWithTax,
            isTaxPayer,
            servicesDiscount,
            servicesSum,
            servicesSumDiscount,
            servicesTotalSum,
            detailsDiscount,
            detailsSum,
            detailsSumDiscount,
            detailsTotalSum
        } = order;

        const remainPrice = isTaxPayer
            ? Math.round((totalSumWithTax - cashSum) * 100) / 100
            : Math.round((totalSum - cashSum) * 100) / 100;

        const orderServices = _.get(fetchedOrder, 'orderServices', []);
        const orderDetails = _.get(fetchedOrder, 'orderDetails', []);
        const orderLaborsRequest = _.get(fetchedOrder, 'orderLaborsRequest', []);

        const formFieldsValues = form.getFieldsValue();
        const orderFormFields = _.pick(formFieldsValues, [
            'comment',
            'clientVehicle',
            'clientEmail',
            'clientPhone',
            'searchClientQuery',
            'clientVehicleTypeId'
        ]);

        orderFormRef(form);

        const clientVehicleId = _.get(formFieldsValues, 'clientVehicle');

        const closedEditing = this.props.orderStatus == 'success' || this.props.orderStatus == 'cancel';

        return (
            <Form id='orderForm' layout='horizontal'>
                <Tabs
                    onTabClick={key => {
                        this.setState({
                            activeKey: key,
                            action: undefined
                        });
                    }}
                    size='default'
                    type='line'
                >
                    <TabPane
                        key='general'
                        forceRender
                        tab={formatMessage({
                            id: 'add_order_form.general',
                            defaultMessage: 'General'
                        })}
                    >
                        <OrderMobileFormFields
                            addClientFormData={addClientFormData}
                            allDetails={allDetails}
                            cashSum={cashSum}
                            employees={employees}
                            errors={errors}
                            fetchedOrder={fetchedOrder}
                            fields={orderFormFields}
                            form={form}
                            handleSearchClientVehicles={handleSearchClientVehicles}
                            isMobile={isMobile}
                            isRetailOrder={isRetailOrder}
                            isTaxPayer={isTaxPayer}
                            managers={managers}
                            modal={modal}
                            onClose={onClose}
                            onStatusChange={onStatusChange}
                            order={order}
                            orderHistory={orderHistory}
                            orderId={orderId}
                            orderStatus={orderStatus}
                            orderTasks={orderTasks}
                            resetModal={resetModal}
                            schedule={schedule}
                            searchClientsResult={searchClientsResult}
                            selectedClient={selectedClient}
                            setAddClientModal={setAddClientModal}
                            setClientSelection={cl => {
                                form.setFieldsValue({
                                    clientVehicle: _.get(cl, 'id'),
                                    clientPhone: _.get(cl, 'phones[0]')
                                });
                                setClientSelection(cl);
                            }}
                            stationLoads={stationLoads}
                            stations={stations}
                            totalSum={totalSum}
                            totalSumWithTax={totalSumWithTax}
                            updateOrderField={this._updateOrderField}
                            user={user}
                            vehicles={vehicles}
                            wrappedComponentRef={orderFormRef}
                        />
                    </TabPane>

                    {!isRetailOrder && (
                        <TabPane
                            key='intake'
                            tab={formatMessage({
                                id: 'vehicle_page.preview'
                            })}
                        >
                            <IntakeTab
                                disabled={orderStatus === 'success' || orderStatus === 'cancel'}
                                isMobile
                                orderId={orderId}
                                orderImgId={_.get(fetchedOrder, 'order.orderImgId')}
                                user={user}
                                vehicleId={_.get(fetchedOrder, 'order.clientVehicleId')}
                                vehicleImageId={_.get(fetchedOrder, 'order.orderVehicleImageId2')}
                            />
                        </TabPane>
                    )}

                    {!isRetailOrder && (
                        <React.Fragment>
                            <TabPane
                                key='diagnostic'
                                forceRender
                                tab={formatMessage({
                                    id: 'order_form_table.chassis_diagnostic'
                                })}
                            >
                                <ChassisDiagnosticTab
                                    isMobile
                                    orderId={orderId}
                                    orderNum={orderNum}
                                    reloadOrderForm={() => {
                                        this._reloadOrderForm();
                                    }}
                                    schemeId={1}
                                />
                                {/* <MobileDiagnostic
                                disabled={orderStatus == 'success' || orderStatus == 'cancel'}
                                orderDiagnostic={orderDiagnostic}
                                orderId={orderId}
                                setModal={setModal}
                                user={user}
                                vehicle={vehicle}
                            /> */}
                            </TabPane>
                            <TabPane
                                key='diagnostic_truck'
                                forceRender
                                tab={formatMessage({
                                    id: 'order_form_table.chassis_diagnostic_truck'
                                })}
                            >
                                <ChassisDiagnosticTab
                                    isMobile
                                    orderId={orderId}
                                    orderNum={orderNum}
                                    reloadOrderForm={() => {
                                        this._reloadOrderForm();
                                    }}
                                    schemeId={2}
                                />
                            </TabPane>
                        </React.Fragment>
                    )}
                    {!isRetailOrder && (
                        <TabPane
                            key='services'
                            forceRender
                            tab={`${formatMessage({
                                id: 'add_order_form.services',
                                defaultMessage: 'Services'
                            })} (${orderServices.length})`}
                        >
                            <ServicesTable
                                clientVehicleRadius={clientVehicleRadius}
                                clientVehicleTypeId={clientVehicleTypeId}
                                crews={this.state.crews}
                                defaultEmployeeId={this.props.order.employeeId}
                                details={this.details}
                                detailsTreeData={this.state.detailsTreeData}
                                employees={employees}
                                fetchCrewsOptions={this.fetchCrewsOptions}
                                fetchedOrder={fetchedOrder}
                                fetchLabors={this.fetchLabors}
                                fetchSuppliersOptions={this.fetchSuppliersOptions}
                                isMobile={isMobile}
                                labors={labors}
                                normHourPrice={normHourPrice}
                                orderFetching={orderFetching}
                                orderId={orderId}
                                orderServices={orderServices}
                                reloadOrderForm={() => {
                                    this._reloadOrderForm();
                                }}
                                selectedClient={selectedClient}
                                suppliers={suppliers}
                                user={user}
                            />
                            <DiscountPanel
                                discountFieldName='servicesDiscount'
                                fetchedOrder={fetchedOrder}
                                forbidden={isForbidden(user, permissions.ACCESS_ORDER_LABORS_DISCOUNTS)}
                                form={form}
                                isMobile={isMobile}
                                orderId={orderId}
                                price={servicesSum}
                                reloadOrderForm={() => {
                                    this._reloadOrderForm();
                                }}
                                servicesMode
                                totalServicesProfit={servicesSumDiscount}
                            />
                        </TabPane>
                    )}
                    <TabPane
                        key='details'
                        forceRender
                        tab={`${formatMessage({
                            id: 'add_order_form.details',
                            defaultMessage: 'Details'
                        })} (${orderDetails.length})`}
                    >
                        <DetailsTable
                            allDetails={allDetails}
                            clientId={selectedClient.clientId}
                            clientVehicleRadius={clientVehicleRadius}
                            clientVehicleTypeId={clientVehicleTypeId}
                            clientVehicleVin={clientVehicleVin}
                            defaultResponsibleId={this.props.order.appurtenanciesResponsibleId}
                            details={this.details}
                            detailsTreeData={this.state.detailsTreeData}
                            fetchSuppliers={this.fetchSuppliersOptions}
                            isMobile={isMobile}
                            modal={modal}
                            orderDetails={orderDetails}
                            orderFetching={orderFetching}
                            orderId={orderId}
                            orderNum={orderNum}
                            reloadOrderForm={callback => {
                                this._reloadOrderForm();
                            }}
                            resetModal={resetModal}
                            selectedClient={selectedClient}
                            setModal={setModal}
                            suppliers={suppliers}
                            tecdocId={modificationId}
                            user={user}
                        />
                        <DiscountPanel
                            detailsMode
                            discountFieldName='detailsDiscount'
                            fetchedOrder={fetchedOrder}
                            forbidden={isForbidden(user, permissions.ACCESS_ORDER_DETAILS_DISCOUNTS)}
                            form={form}
                            isMobile={isMobile}
                            orderId={orderId}
                            price={detailsSum}
                            reloadOrderForm={() => {
                                this._reloadOrderForm();
                            }}
                            totalServicesProfit={detailsSumDiscount}
                        />
                        <VinCodeModal
                            disabled={
                                isForbidden(user, permissions.ACCESS_ORDER_DETAILS_VIN) ||
                                isForbidden(user, permissions.ACCESS_ORDER_DETAILS_CRUD)
                            }
                            modal={modal}
                            resetModal={resetModal}
                            vin={clientVehicleVin}
                        />
                    </TabPane>

                    {!isRetailOrder && (
                        <TabPane
                            key='request'
                            tab={
                                <div className={Styles.tabStyle}>
                                    <div>
                                        {formatMessage({
                                            id: 'add_order_form.request'
                                        })}{' '}
                                    </div>
                                </div>
                            }
                        >
                            <RequestsTable
                                activeKey={this.state.activeKey}
                                defaultEmployeeId={this.props.defaultEmployeeId}
                                detailsTreeData={this.state.detailsTreeData}
                                disabled={
                                    closedEditing ||
                                    isForbidden(user, permissions.ACCESS_ORDER_LABORS_CRUD) ||
                                    orderStatus === 'success' ||
                                    orderStatus === 'cancel'
                                }
                                employees={employees}
                                errors={errors}
                                fetchLabors={this.fetchLabors}
                                fetchSuppliersOptions={this.fetchSuppliersOptions}
                                isMobile={isMobile}
                                labors={labors}
                                modal={modal}
                                orderId={orderId}
                                orderLaborsRequest={orderLaborsRequest}
                                reloadOrderForm={() => {
                                    this._reloadOrderForm();
                                }}
                                resetModal={resetModal}
                                setModal={setModal}
                                suppliers={suppliers}
                                user={user}
                            />
                        </TabPane>
                    )}

                    <TabPane
                        key='media'
                        tab={`${formatMessage({
                            id: 'gallary.media'
                        })}`}
                    >
                        <OrderMediaTab
                            activeKey={this.state.activeKey}
                            bsStats={this.state.bsStats}
                            fetchHelperLinks={this.fetchHelperLinks}
                            files={this.state.fileList}
                            orderId={orderId}
                            orderNum={orderNum}
                        />
                    </TabPane>

                    <TabPane
                        key='history'
                        tab={`${formatMessage({
                            id: 'order_form_table.history'
                        })}`}
                    >
                        <HistoryTable
                            clientVehicleId={_.get(fetchedOrder, 'order.clientVehicleId')}
                            fetchOrderForm={fetchOrderForm}
                            isMobile={isMobile}
                            setHistoryCount={() => {}}
                            user={user}
                        />
                    </TabPane>

                    {/* <TabPane
                        key='orderTasks'
                        tab={`${formatMessage({
                            id: 'order_form_table.order_tasks'
                        })}`}
                    >
                        <TasksTable
                            fetchHelperLinks={this.fetchHelperLinks}
                        />
                    </TabPane> */}

                    {/* <TabPane
                        key='workshop'
                        tab={`${formatMessage({
                            id: 'order_form_table.history'
                        })}`}
                    >
                        <WorkshopTable
                            activeKey={this.state.activeKey}
                            fetchHelperLinks={this.fetchHelperLinks}
                            orderId={orderId}
                            orderServices={orderServices}
                            reloadOrderForm={this.props.reloadOrderForm}
                            user={user}
                        />
                    </TabPane> */}
                </Tabs>
                <ToSuccessModal
                    clientId={selectedClient.clientId}
                    onStatusChange={this.props.onStatusChange}
                    orderId={orderId}
                    remainPrice={remainPrice}
                    resetModal={this.props.resetModal}
                    visible={this.props.modal}
                    wrappedComponentRef={this._saveFormRef}
                />
            </Form>
        );
    }
}

@injectIntl
class MobileDiagnostic extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            orderId: props.orderId,
            orderDiagnostic: props.orderDiagnostic,
            dataSource: []
        };
    }

    updateDataSource() {
        const { orderDiagnostic, orderId } = this.state;
        const dataSource = [];

        const { diagnosticTemplatesCount } = _.pick(orderDiagnostic, ['diagnosticTemplatesCount']);
        const { diagnosticTemplates } = _.pick(orderDiagnostic, ['diagnosticTemplates']);
        let key = 1;
        for (let i = 0; i < diagnosticTemplatesCount; i++) {
            const { groupsCount } = _.pick(diagnosticTemplates[i], ['groupsCount']);
            const { diagnosticTemplateTitle } = _.pick(diagnosticTemplates[i], ['diagnosticTemplateTitle']);
            const { diagnosticTemplateId } = _.pick(diagnosticTemplates[i], ['diagnosticTemplateId']);
            const { groups } = _.pick(diagnosticTemplates[i], ['groups']);
            for (let j = 0; j < groupsCount; j++) {
                const { groupTitle } = _.pick(groups[j], ['groupTitle']);
                const { groupId } = _.pick(groups[j], ['groupId']);
                const { partsCount } = _.pick(groups[j], ['partsCount']);
                const { parts } = _.pick(groups[j], ['parts']);
                for (let k = 0; k < partsCount; k++) {
                    const { index } = _.pick(parts[k], ['index']);
                    const { partTitle } = _.pick(parts[k], ['partTitle']);
                    const { actionTitle } = _.pick(parts[k], ['actionTitle']);
                    const { partId } = _.pick(parts[k], ['partId']);
                    const { answer } = _.pick(parts[k], ['answer']);
                    let { comment } = _.pick(parts[k], ['comment']);
                    const { calcDone } = _.pick(parts[k], ['calcDone']);
                    const { photoHashes } = _.pick(parts[k], ['photoHashes']);
                    const { oeCode } = _.pick(parts[k], ['oeCode']);
                    const { storeGroupId } = _.pick(parts[k], ['storeGroupId']);
                    if (comment == null) {
                        comment = {
                            comment: undefined,
                            positions: []
                        };
                    }
                    dataSource.push({
                        templateIndex: index,
                        key,
                        partId,
                        plan: diagnosticTemplateTitle,
                        stage: groupTitle,
                        detail: partTitle,
                        actionTitle,
                        status: answer,
                        commentary: comment,
                        orderId,
                        diagnosticTemplateId,
                        groupId,
                        photoHashes,
                        disabled: calcDone,
                        oeCode,
                        storeGroupId
                    });
                    key++;
                }
            }
        }
        this.setState({
            dataSource
        });
        this.forceUpdate();
    }

    getCurrentDiagnostic = () => {
        const that = this;
        const token = localStorage.getItem('_my.carbook.pro_token');
        let url = __API_URL__;
        const params = `/orders/diagnostics?orderId=${this.state.orderId}`;
        url += params;
        fetch(url, {
            method: 'GET',
            headers: {
                Authorization: token
            }
        })
            .then(function (response) {
                if (response.status !== 200) {
                    return Promise.reject(new Error(response.statusText));
                }

                return Promise.resolve(response);
            })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {
                that.state.orderDiagnostic = data.diagnosis;
                that.updateDataSource();
            })
            .catch(function (error) {
                console.log('error', error);
            });
    };

    getDiagnosticHeader() {
        const { vehicle } = this.props;
        if (typeof vehicle !== 'undefined') {
            return (
                <React.Fragment>
                    <div className={Styles.diagnostic_header}>
                        <div className={Styles.diagnostic_vehicle_number}>{vehicle.number}</div>
                        <div className={Styles.diagnostic_vehicle_info}>
                            {vehicle.make} {vehicle.model} {vehicle.modification}
                        </div>
                    </div>
                    <Button
                        disabled={isForbidden(this.props.user, permissions.ACCESS_DIAGNOSTICS_COMPLETE)}
                        onClick={() => {
                            notification.success({
                                message: this.props.intl.formatMessage({
                                    id: 'message_sent'
                                })
                            });
                            sendMessage(this.props.orderId);
                        }}
                        style={{ marginTop: 15, width: '100%' }}
                        type='primary'
                    >
                        <FormattedMessage id='end' />
                    </Button>
                </React.Fragment>
            );
        }
    }

    getDiagnosticElementsByTitle(title) {
        const { dataSource } = this.state;

        return dataSource.map((data, key) => {
            const popoverContent = (
                <div className={Styles.actionBlock}>
                    <Button
                        disabled={
                            this.props.disabled ||
                            data.disabled ||
                            isForbidden(this.props.user, permissions.ACCESS_ORDER_DETAILS_VIN)
                        }
                        onClick={() => {
                            this.props.setModal(MODALS.VIN_MODAL, {
                                storeGroupId: data.storeGroupId,
                                setVinDetail: async product => {
                                    await fetchAPI(
                                        'PUT',
                                        'orders/diagnostics/answer',
                                        null,
                                        {
                                            orderId: data.orderId,
                                            templateId: data.diagnosticTemplateId,
                                            groupId: data.groupId,
                                            partId: data.partId,
                                            index: data.templateIndex,
                                            answer: String(data.status),
                                            comment: data.comment,
                                            oeCode: product.oem
                                        },
                                        { handleErrorInternally: true }
                                    );
                                    this.getCurrentDiagnostic();
                                }
                            });
                        }}
                        style={{
                            fontWeight: 500
                        }}
                        type={data.oeCode ? 'primary' : 'default'}
                    >
                        VIN
                    </Button>
                    <CommentaryButton
                        commentary={
                            data.commentary || {
                                comment: undefined,
                                positions: [],
                                problems: [],
                                mm: 0,
                                percent: 0,
                                deg: 0
                            }
                        }
                        disabled={this.props.disabled || data.disabled}
                        getCurrentDiagnostic={this.getCurrentDiagnostic}
                        rowProp={data}
                    />
                    <Button
                        disabled={
                            this.props.disabled ||
                            data.disabled ||
                            isForbidden(this.props.user, permissions.ACCESS_DIAGNOSTICS_ADD_ELEMENTS)
                        }
                        onClick={async () => {
                            await addNewDiagnosticRow(
                                data.orderId,
                                data.diagnosticTemplateId,
                                data.groupId,
                                data.partId,
                                data.templateIndex
                            );
                            this.getCurrentDiagnostic();
                        }}
                        title={this.props.intl.formatMessage({
                            id: 'order_form_table.diagnostic.duplicate_line'
                        })}
                    >
                        <PlusOutlined />
                    </Button>
                    <PhotoButton
                        disabled={this.props.disabled || data.disabled}
                        getCurrentDiagnostic={this.getCurrentDiagnostic}
                        rowProp={data}
                    />
                </div>
            );

            let color = '';
            if (data.status == 1) {
                color = 'rgb(200,225,180)';
            } else if (data.status == 2) {
                color = 'rgb(255,240,180)';
            } else if (data.status == 3) {
                color = 'rgb(250,175,175)';
            }
            if (data.plan == title) {
                return (
                    <div key={key} className={Styles.diagnostic} style={{ backgroundColor: color }}>
                        <div className={Styles.diagnostic_key}>{data.key}</div>
                        <div className={Styles.diagnistic_info}>
                            <div className={Styles.diagnistic_info_up}>
                                <div className={Styles.diagnostic_detail}>{data.detail}</div>
                                <div>{data.actionTitle}</div>
                            </div>
                            <div className={Styles.diagnostic_buttons}>
                                <Popover content={popoverContent} trigger='click'>
                                    <Button disabled={this.props.disabled || data.disabled}>
                                        <MenuOutlined />
                                    </Button>
                                </Popover>
                                <MobileDiagnosticStatusButton
                                    disabled={
                                        this.props.disabled ||
                                        data.disabled ||
                                        isForbidden(this.props.user, permissions.ACCESS_DIAGNOSTICS_UPDATE_ELEMENTS)
                                    }
                                    getCurrentDiagnostic={this.getCurrentDiagnostic}
                                    rowProp={data}
                                    status={data.status}
                                />
                            </div>
                        </div>
                    </div>
                );
            }
        });
    }

    getDiagnosticElements() {
        const { dataSource } = this.state;
        const diagnosicTitles = [];
        for (let i = 0; i < dataSource.length; i++) {
            if (diagnosicTitles.indexOf(dataSource[i].plan) == -1) {
                diagnosicTitles.push(dataSource[i].plan);
            }
        }

        return diagnosicTitles.map((data, key) => (
            <div key={key}>
                <div className={Styles.diagnostic_title}>{data}</div>
                {this.getDiagnosticElementsByTitle(data)}
            </div>
        ));
    }

    componentDidMount() {
        this._isMounted = true;
        this.getCurrentDiagnostic();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    showModal = () => {
        this.setState({
            visible: true
        });
    };

    handleCancel = () => {
        this.setState({ visible: false });
    };

    render() {
        const { vehicle } = this.props;
        const { visible, dataSource } = this.state;

        return (
            <div>
                {this.getDiagnosticHeader()}
                {this.getDiagnosticElements()}
                <Button
                    disabled={isForbidden(this.props.user, permissions.ACCESS_DIAGNOSTICS_COMPLETE)}
                    onClick={() => {
                        notification.success({
                            message: this.props.intl.formatMessage({
                                id: 'message_sent'
                            })
                        });
                        sendMessage(this.props.orderId);
                    }}
                    style={{ marginTop: 15, width: '100%' }}
                    type='primary'
                >
                    <FormattedMessage id='end' />
                </Button>
            </div>
        );
    }
}

class MobileDiagnosticStatusButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            status: props.status
        };
    }

    handleClick = async status => {
        const { rowProp, getCurrentDiagnostic } = this.props;
        await sendDiagnosticAnswer(
            rowProp.orderId,
            rowProp.diagnosticTemplateId,
            rowProp.groupId,
            rowProp.partId,
            rowProp.templateIndex,
            status,
            rowProp.commentary,
            rowProp.oeCode
        );
        await this.setState({ status });
        getCurrentDiagnostic();
    };

    render() {
        const { status } = this.state;

        return status > 0 ? (
            !this.props.disabled && (
                <Button
                    className={Styles.diagnostic_status_button_edit}
                    disabled={this.props.disabled}
                    onClick={() => this.handleClick(0)}
                >
                    <EditOutlined />
                </Button>
            )
        ) : (
            <React.Fragment>
                <Button
                    className={Styles.diagnostic_status_button_ok}
                    disabled={this.props.disabled}
                    onClick={() => this.handleClick(1)}
                    style={{ background: 'var(--green)' }}
                >
                    <CheckOutlined />
                </Button>
                <Button
                    className={Styles.diagnostic_status_button_bad}
                    disabled={this.props.disabled}
                    onClick={() => this.handleClick(2)}
                    style={{ background: 'rgb(255, 255, 0)' }}
                >
                    <MinusOutlined />
                </Button>
                <Button
                    className={Styles.diagnostic_status_button_critical}
                    disabled={this.props.disabled}
                    onClick={() => this.handleClick(3)}
                    style={{ background: 'rgb(255, 126, 126)', color: 'black' }}
                    type='danger'
                >
                    <ExclamationOutlined />
                </Button>
            </React.Fragment>
        );
    }
}

@injectIntl
class CommentaryButton extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            visible: false,
            currentCommentaryProps: {
                name: props.rowProp.detail,
                positions: [],
                problems: [],
                params: {
                    mm: 0,
                    percent: 0,
                    deg: 0
                }
            },
            currentCommentary: undefined
        };
        this.commentaryInput = React.createRef();
        this.positions = [
            'front_axle',
            'ahead',
            'overhead',
            'rear_axle',
            'behind',
            'down_below',
            'Right_wheel',
            'on_right',
            'outside',
            'left_wheel',
            'left',
            'inside',
            'lever_arm',
            'at_both_sides',
            'centered'
        ];
        this.problems = [];
        this.params = [
            { name: 'mm', symbol: 'mm' },
            { name: 'percent', symbol: '%' },
            { name: 'deg', symbol: '°' }
        ];
        this._isMounted = false;
    }

    getPositions() {
        const that = this;
        const token = localStorage.getItem('_my.carbook.pro_token');
        let url = __API_URL__;
        const params = `/diagnostics/positions?partId=${this.props.rowProp.partId}`;
        url += params;

        fetch(url, {
            method: 'GET',
            headers: {
                Authorization: token
            }
        })
            .then(function (response) {
                if (response.status !== 200) {
                    return Promise.reject(new Error(response.statusText));
                }

                return Promise.resolve(response);
            })
            .then(function (response) {
                return response.json();
            })
            .then(function (data) {})
            .catch(function (error) {
                console.log('error', error);
            });
    }

    showModal = async () => {
        const { commentary, rowProp } = this.props;
        await getPartProblems(rowProp.partId, data => {
            this.problems = data.map(elem => {
                return {
                    label: elem.description,
                    value: elem.code
                };
            });
        });
        await this.setState({
            currentCommentary: commentary.comment,
            currentCommentaryProps: {
                name: rowProp.detail,
                positions: commentary.positions || [],
                problems: commentary.problems || [],
                params: {
                    mm: commentary.mm || 0,
                    percent: commentary.percent || 0,
                    deg: commentary.deg || 0
                }
            },
            visible: true
        });
        if (this.commentaryInput.current != undefined) {
            this.commentaryInput.current.focus();
        }
    };

    handleOk = async () => {
        const { currentCommentary, currentCommentaryProps } = this.state;
        this.setState({
            loading: true
        });
        const { rowProp } = this.props;
        await sendDiagnosticAnswer(
            rowProp.orderId,
            rowProp.diagnosticTemplateId,
            rowProp.groupId,
            rowProp.partId,
            rowProp.templateIndex,
            rowProp.status,
            {
                comment: currentCommentary,
                positions: currentCommentaryProps.positions,
                problems: currentCommentaryProps.problems,
                mm: currentCommentaryProps.params.mm,
                percent: currentCommentaryProps.params.percent,
                deg: currentCommentaryProps.params.deg
            },
            rowProp.oeCode
        );
        await this.props.getCurrentDiagnostic();
        setTimeout(() => {
            this.setState({ loading: false, visible: false });
        }, 500);
    };

    handleCancel = () => {
        this.setState({
            visible: false,
            currentCommentary: null
        });
    };

    renderHeader = () => {
        const { currentCommentaryProps } = this.state;
        const { problems } = this;

        return (
            <div>
                <p>{this.props.rowProp.detail}</p>
                <p
                    style={{
                        fontSize: '16px',
                        fontStyle: 'italic',
                        fontWeight: 'normal'
                    }}
                >
                    {
                        // this.props.rowProp.actionTitle
                        currentCommentaryProps.problems.map((data, index) => {
                            const punctuation = index == currentCommentaryProps.problems.length - 1 ? '' : ',';
                            const problemLable = problems.find(problem => problem.value == data);

                            return ` ${problemLable ? problemLable.label.toLowerCase() : null}${punctuation}`;
                        })
                    }
                </p>
            </div>
        );
    };

    getCommentary() {
        const { currentCommentaryProps } = this.state;
        const { problems, params } = this;
        const paramsValue = Object.entries(currentCommentaryProps.params).map((pair, key) => {
            if (pair[1] !== 0) return ` ${pair[1]}${params[key].symbol}`;
        });
        const isParamsSet = paramsValue.some(param => !_.isNil(param));
        let currentCommentary = this.props.rowProp.detail;

        if (currentCommentaryProps.positions.length || currentCommentaryProps.problems.length || isParamsSet) {
            currentCommentary += ' -';
            if (currentCommentaryProps.positions.length) {
                currentCommentary += `${currentCommentaryProps.positions.map(
                    data => ` ${this.props.intl.formatMessage({ id: data }).toLowerCase()}`
                )};`;
            }
            if (currentCommentaryProps.problems.length) {
                currentCommentary += `${currentCommentaryProps.problems.map(data => {
                    return ` ${problems.find(problem => problem.value == data).label.toLowerCase()}`;
                })};`;
            }
            if (isParamsSet) {
                currentCommentary += `${paramsValue.filter(param => !_.isNil(param))};`;
            }
        }
        this.setState({
            currentCommentary
        });
    }

    setCommentaryPosition(position) {
        const { currentCommentaryProps } = this.state;
        const positionIndex = currentCommentaryProps.positions.indexOf(position);
        if (positionIndex == -1) {
            currentCommentaryProps.positions.push(position);
        } else {
            currentCommentaryProps.positions = currentCommentaryProps.positions.filter(
                (value, index) => index != positionIndex
            );
        }
        this.getCommentary();
    }

    setCommentaryProblems(value) {
        const { currentCommentaryProps } = this.state;
        const problemIndex = currentCommentaryProps.problems.indexOf(value);
        if (problemIndex == -1) {
            currentCommentaryProps.problems.push(value);
        } else {
            currentCommentaryProps.problems = currentCommentaryProps.problems.filter(
                (value, index) => index != problemIndex
            );
        }
        this.getCommentary();
    }

    setCommetaryParams(param, value) {
        const { currentCommentaryProps } = this.state;
        currentCommentaryProps.params[param] = value;
        this.getCommentary();
    }

    async componentDidMount() {
        this._isMounted = true;
        const { currentCommentaryProps } = this.state;
        const { commentary, rowProp } = this.props;
        if (!this.problems.length && this.props.rowProp.partId) {
            await getPartProblems(this.props.rowProp.partId, data => {
                this.problems = data.map(elem => {
                    return {
                        label: elem.description,
                        value: elem.code
                    };
                });
            });
            if (this._isMounted) {
                this.setState({
                    currentCommentaryProps: {
                        name: rowProp.detail,
                        positions: commentary.positions || [],
                        problems: commentary.problems || [],
                        params: {
                            mm: commentary.mm || 0,
                            percent: commentary.percent || 0,
                            deg: commentary.deg || 0
                        }
                    }
                });
            }
            // await this.getPositions();
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        const { TextArea } = Input;
        const { visible, loading, currentCommentaryProps, currentCommentary } = this.state;
        const { disabled, commentary, rowProp } = this.props;
        const { positions, problems, params } = this;

        return (
            <div style={{ width: '49%' }}>
                {commentary.comment ? (
                    <Button className={Styles.commentaryButton} onClick={this.showModal} type='primary'>
                        <FormOutlined className={Styles.commentaryButtonIcon} />
                    </Button>
                ) : (
                    <Button disabled={disabled} onClick={this.showModal}>
                        <MessageOutlined />
                    </Button>
                )}
                <Modal
                    footer={
                        disabled
                            ? null
                            : [
                                  <Button key='back' onClick={this.handleCancel}>
                                      <FormattedMessage id='cancel' />
                                  </Button>,
                                  <Button key='submit' loading={loading} onClick={this.handleOk} type='primary'>
                                      <FormattedMessage id='save' />
                                  </Button>
                              ]
                    }
                    maskClosable={false}
                    onCancel={this.handleCancel}
                    onOk={this.handleOk}
                    title={this.renderHeader()}
                    visible={visible}
                >
                    <React.Fragment>
                        {rowProp.oeCode && (
                            <div className={Styles.commentaryVehicleSchemeWrap}>
                                <p className={Styles.commentarySectionHeader}>
                                    <FormattedMessage id='order_form_table.OE_code' />
                                </p>
                                <Input
                                    disabled
                                    style={{
                                        color: 'var(--text)'
                                    }}
                                    value={rowProp.oeCode}
                                />
                            </div>
                        )}
                        <div className={Styles.commentaryVehicleSchemeWrap}>
                            <p className={Styles.commentarySectionHeader}>
                                <FormattedMessage id='commentary_modal.where' />?
                            </p>
                            <div className={Styles.blockButtonsWrap}>
                                {positions.map((position, key) => {
                                    return (
                                        <Button
                                            key={key}
                                            className={Styles.commentaryBlockButton}
                                            onClick={() => {
                                                this.setCommentaryPosition(position);
                                            }}
                                            type={
                                                currentCommentaryProps.positions.findIndex(elem => position == elem) ===
                                                -1
                                                    ? 'default'
                                                    : 'primary'
                                            }
                                        >
                                            <FormattedMessage id={position} />
                                        </Button>
                                    );
                                })}
                            </div>
                        </div>
                        <div className={Styles.commentaryVehicleSchemeWrap}>
                            <p className={Styles.commentarySectionHeader}>
                                <FormattedMessage id='commentary_modal.what' />?
                            </p>
                            <div className={Styles.blockButtonsWrap}>
                                {problems.map((problem, key) => {
                                    return (
                                        <Button
                                            key={key}
                                            className={Styles.commentaryBlockButton}
                                            onClick={() => {
                                                this.setCommentaryProblems(problem.value);
                                            }}
                                            type={
                                                currentCommentaryProps.problems.findIndex(
                                                    elem => problem.value == elem
                                                ) === -1
                                                    ? 'default'
                                                    : 'primary'
                                            }
                                        >
                                            <span>{problem.label}</span>
                                        </Button>
                                    );
                                })}
                            </div>
                        </div>
                        <div className={Styles.commentaryVehicleSchemeWrap}>
                            <p className={Styles.commentarySectionHeader}>
                                <FormattedMessage id='commentary_modal.parameters' />
                            </p>
                            <div className={Styles.blockButtonsWrap}>
                                {params.map((param, key) => {
                                    return (
                                        <InputNumber
                                            key={key}
                                            className={Styles.commentaryBlockButton}
                                            decimalSeparator=','
                                            formatter={value => `${value} ${param.symbol}`}
                                            onChange={value => {
                                                this.setCommetaryParams(param.name, value);
                                            }}
                                            parser={value => value.replace(` ${param.symbol}`, '')}
                                            value={currentCommentaryProps.params[param.name]}
                                        />
                                    );
                                })}
                            </div>
                        </div>
                        <div>
                            <p className={Styles.commentarySectionHeader}>
                                <FormattedMessage id='order_form_table.diagnostic.commentary' />
                            </p>
                            <TextArea
                                ref={this.commentaryInput}
                                autoFocus
                                disabled={disabled}
                                onChange={() => {
                                    this.setState({
                                        currentCommentary: event.target.value
                                    });
                                }}
                                placeholder={`${this.props.intl.formatMessage({
                                    id: 'comment'
                                })}...`}
                                style={{
                                    width: '100%',
                                    minHeight: '150px',
                                    resize: 'none'
                                }}
                                value={currentCommentary}
                            />
                        </div>
                    </React.Fragment>
                </Modal>
            </div>
        );
    }
}
