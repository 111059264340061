import { Checkbox, Modal, Select, TimePicker } from 'antd';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { fetchAPI } from 'utils';
import Styles from './styles.m.css';

const { Option } = Select;

const format = 'HH:mm';

const DayModal = ({ modalProps, daySchedule, dayObject, fetchCalendar, employee, fetchTableCalendar, intl: { formatMessage } }) => {
    const [dayOff, setDayOff] = useState(false);
    const [typeOfDayOff, setTypeOfDayOff] = useState();
    const [workStart, setWorkStart] = useState();
    const [workEnd, setWorkEnd] = useState();

    useEffect(() => {
        if (dayObject) {
            setDayOff(dayObject.plannedDayOff);
            if (!dayObject.plannedDayOff) {
                setWorkStart(
                    dayjs()
                        .set('h', dayObject.plannedStartTime.split(':')[0])
                        .set('m', dayObject.plannedStartTime.split(':')[1])
                );
                setWorkEnd(
                    dayjs()
                        .set('h', dayObject.plannedEndTime.split(':')[0])
                        .set('m', dayObject.plannedEndTime.split(':')[1])
                );
            }
        } else {
            setDayOff(false);
            setWorkStart(undefined);
            setWorkEnd(undefined);
            setTypeOfDayOff(undefined);
        }
    }, [dayObject]);

    const createDaySchedule = async () => {
        if (workEnd && workEnd.isBefore(workStart)) {
            const body1 = {
                employeeId: employee[0].id,
                date: daySchedule.toISOString(),
                ...(dayOff
                    ? { plannedDayOff: true, typeOfDayOff }
                    : {
                          plannedStartTime: workStart.format(format),
                          plannedEndTime: dayjs().set('h', 23).set('m', 59).format(format)
                      })
            };
            const body2 = {
                employeeId: employee[0].id,
                date: daySchedule.add(1, 'day').toISOString(),
                ...(dayOff
                    ? { plannedDayOff: true, typeOfDayOff }
                    : {
                          plannedStartTime: dayjs().set('h', 0).set('m', 0).format(format),
                          plannedEndTime: workEnd.format(format)
                      })
            };
            await Promise.all([
                await fetchAPI('POST', '/employee_schedule', null, body1, { handleErrorInternally: true }),
                await fetchAPI('POST', '/employee_schedule', null, body2, { handleErrorInternally: true })
            ]);
        } else {
            const body = {
                employeeId: employee[0].id,
                date: daySchedule.toISOString(),
                ...(dayOff
                    ? { plannedDayOff: true, typeOfDayOff }
                    : { plannedStartTime: workStart.format(format), plannedEndTime: workEnd.format(format) })
            };
            await fetchAPI('POST', '/employee_schedule', null, body, { handleErrorInternally: true });
        }
        fetchCalendar();
        if(fetchTableCalendar) fetchTableCalendar();
        modalProps.onClose();
    };

    const updateDaySchedule = async () => {
        const body = {
            // date: daySchedule.toISOString(),
            ...(dayOff
                ? { plannedDayOff: true, typeOfDayOff }
                : { plannedStartTime: workStart.format(format), plannedEndTime: workEnd.format(format), plannedDayOff: false })
        };
        await fetchAPI('PUT', `/employee_schedule/${dayObject.id}`, null, body, {
            handleErrorInternally: true
        });
        fetchCalendar();
        if(fetchTableCalendar) fetchTableCalendar();
        modalProps.onClose();
    };

    return (
        <Modal
            destroyOnClose
            open={!!daySchedule}
            {...modalProps}
            okButtonProps={{ disabled: !dayOff && !(workStart && workEnd) }}
            onOk={dayObject ? updateDaySchedule : createDaySchedule}
        >
            <div className={Styles.container}>
                <div className={Styles.inputWrapper}>
                    <FormattedMessage id='reports_page.day_off' />
                    <Checkbox checked={dayOff} onChange={() => setDayOff(prev => !prev)} />
                </div>
                <div className={Styles.inputWrapper}>
                    <FormattedMessage id='reports_page.day_off_type' />
                    <Select
                        disabled={!dayOff}
                        onChange={setTypeOfDayOff}
                        placeholder={formatMessage({ id: 'reports_page.day_off_type' })}
                        value={typeOfDayOff}
                    >
                        <Option value='dayOff'>
                            {formatMessage({ id: 'reports_page.day_off' })}
                        </Option>
                        <Option value='vacation'>
                            {formatMessage({ id: 'reports_page.vacation' })}
                        </Option>
                        <Option value='sick'>
                            {formatMessage({ id: 'reports_page.sick_leave' })}
                        </Option>
                        <Option value='other'>
                            {formatMessage({ id: 'reports_page.other' })}
                        </Option>
                    </Select>
                </div>
                <div className={Styles.inputWrapper}>
                    <FormattedMessage id='reports_page.work_start' />
                    <TimePicker
                        disabled={dayOff}
                        format={format}
                        minuteStep={30}
                        onChange={value => setWorkStart(value)}
                        value={workStart}
                    />
                </div>
                <div className={Styles.inputWrapper}>
                    <FormattedMessage id='reports_page.work_end' />
                    <TimePicker
                        disabled={dayOff}
                        format={format}
                        minuteStep={30}
                        onChange={value => setWorkEnd(value)}
                        value={workEnd}
                    />
                </div>
            </div>
        </Modal>
    );
};
export default injectIntl(DayModal);
