import { PrinterOutlined } from '@ant-design/icons';
import { Button, InputNumber, Modal, Table } from 'antd';
import {
    closeShift,
    fetchCashboxesBalance,
    fetchXReport,
    openShift,
    selectCashAccountingFilters,
    setCashAccountingFilters,
    setCashOrdersFilters,
    setShiftTxt,
    setXReportTxt
} from 'core/cash/duck';
import { MODALS, resetModal, setModal } from 'core/modals/duck';
import dayjs from 'dayjs';
import { DatePickerField } from 'forms/_formkit';
import _ from 'lodash';
import { RefactoredCashOrderModal } from 'modals';
import { DEF_BACK_DATE } from 'modals/DebtZeroingModal/constants';
import { SERVICE_OPERATION_TYPES } from 'modals/RefactoredCashOrderModal/constants';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { fetchAPI, getCurrency } from 'utils';
import { v4 } from 'uuid';
import { columnsConfig } from './config';
import Styles from './styles.m.css';

const mapStateToProps = state => ({
    user: state.auth,
    data: state.cash.balance,
    loading: state.cash.isFetchCashboxesBalance,
    filters: selectCashAccountingFilters(state),
    isMobile: state.ui.views.isMobile,
    shiftTxt: state.cash.shiftTxt,
    xReportTxt: state.cash.xReportTxt
});

const mapDispatchToProps = {
    fetchCashboxesBalance,
    setCashAccountingFilters,
    setCashOrdersFilters,
    openShift,
    closeShift,
    fetchXReport,
    setModal,
    resetModal,
    setShiftTxt,
    setXReportTxt
};

/**
 * Table shows balance on the cahbox at the specific date, also contains functionality to work with RST cashboxes.
 */
@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export class CashBalanceTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            contributionSum: 0,
            loadingOk: false
        };
    }

    componentDidMount() {
        this.props.fetchCashboxesBalance();
    }

    /**
     * Open modal to make service input into cashbox with RST
     * @param {*} cashboxId
     */
    onOpenServiceInputModal = cashboxId => {
        this.props.setModal(MODALS.REFACTORED_CASH_ORDER, {
            initValues: {
                cashBoxId: cashboxId,
                serviceOperationType: SERVICE_OPERATION_TYPES.SERVICE_INPUT
            },
            onCashOrderCreatedCallback: () => this.props.fetchCashboxesBalance()
        });
    };

    /**
     * Open modal to make service output from cashbox with RST
     * @param {*} cashboxId
     */
    onOpenServiceOutputModal = cashboxId => {
        this.props.setModal(MODALS.REFACTORED_CASH_ORDER, {
            initValues: {
                cashBoxId: cashboxId,
                serviceOperationType: SERVICE_OPERATION_TYPES.SERVICE_OUTPUT
            },
            onCashOrderCreatedCallback: () => this.props.fetchCashboxesBalance()
        });
    };

    onOpenServiceTransferModal = cashboxId => {
        this.props.setModal(MODALS.REFACTORED_CASH_ORDER, {
            initValues: {
                cashBoxId: cashboxId,
                serviceOperationType: SERVICE_OPERATION_TYPES.TRANSFER
            },
            onCashOrderCreatedCallback: () => this.props.fetchCashboxesBalance()
        });
    };

    /**
     * Open cash order modal to create new cash order
     * @param { Number } param.cashboxId - initial cash box
     */
    onOpenCashOrderModal = ({ cashboxId }) => {
        this.props.setModal(MODALS.REFACTORED_CASH_ORDER, {
            initValues: {
                cashBoxId: cashboxId
            },
            onCashOrderCreatedCallback: () => this.props.fetchCashboxesBalance()
        });
    };

    _handleDatePicker = date => {
        this.props.setCashAccountingFilters({ date });
        this.props.fetchCashboxesBalance();
    };

    _openShiftModal = (id, type) => {
        if (type === 'CASH') {
            this.setState({
                openShiftId: id,
                contributionSum: 0
            });
        } else {
            this.props.openShift(id);
        }
    };

    _closeShiftModal = (id, sum) => {
        this.setState({
            closeShiftId: id,
            contributionSum: sum
        });
    };

    printDiv = id => {
        const divContents = document.getElementById(id).innerHTML;
        const a = window.open('', '', 'height=500, width=500');
        a.document.write('<html>');
        a.document.write('<body >');
        a.document.write(`<div style="
            display: flex;
            justify-content: center;
            white-space: pre-wrap;
            word-wrap: break-word;
            text-align: justify;
            font-family: monospace,monospace;
        ">${divContents}</div>`);
        a.document.write('</body></html>');
        a.document.close();
        a.print();
    };

    _postCashOrder = async (id, type) => {
        await this.setState({
            loadingOk: true
        });
        const { openShift, closeShift, fetchCashboxesBalance } = this.props;
        const { contributionSum } = this.state;

        if (type === 'INCOME') {
            const callback = async () => {
                const response = await fetchAPI(
                    'POST',
                    'cash_orders',
                    null,
                    {
                        cashBoxId: id,
                        description: '',
                        increase: contributionSum,
                        otherCounterparty: 'Service input',
                        analyticsUniqueId: 1202,
                        datetime: dayjs().format(DEF_BACK_DATE),
                        type
                    },
                    { handleErrorInternally: true }
                );
                await fetchAPI(
                    'POST',
                    'cashdesk/service_input_cash_order',
                    null,
                    { localNumber: response.id },
                    { handleErrorInternally: true }
                );
            };
            await openShift(id, callback);
        } else if (type === 'EXPENSE') {
            const response = await fetchAPI(
                'POST',
                'cash_orders',
                null,
                {
                    cashBoxId: id,
                    description: '',
                    decrease: contributionSum,
                    otherCounterparty: 'Service output',
                    analyticsUniqueId: 1202,
                    datetime: dayjs().format(DEF_BACK_DATE),
                    type
                },
                { handleErrorInternally: true }
            );
            await fetchAPI(
                'POST',
                'cashdesk/service_output_cash_order',
                null,
                { localNumber: response.id },
                { handleErrorInternally: true }
            );
            await closeShift(id);
        }
        this.setState({ closeShiftId: undefined, openShiftId: undefined, loadingOk: false });
        // fetchCashboxesBalance();
    };

    render() {
        const {
            loading,
            data,
            filters,
            fetchXReport,
            user,
            isMobile,
            shiftTxt,
            setShiftTxt,
            xReportTxt,
            setXReportTxt
        } = this.props;

        const { openShiftId, closeShiftId, contributionSum, loadingOk } = this.state;

        const columns = columnsConfig({
            onOpenServiceInputModal: this.onOpenServiceInputModal,
            onOpenServiceOutputModal: this.onOpenServiceOutputModal,
            onOpenCashOrderModal: this.onOpenCashOrderModal,
            onOpenServiceTransferModal: this.onOpenServiceTransferModal,
            openShift: this._openShiftModal,
            closeShift: this._closeShiftModal,
            fetchXReport,
            user,
            isMobile
        });

        return (
            <div className={Styles.tableWrapper}>
                <div className={Styles.tableHead}>
                    <DatePickerField
                        allowClear={false}
                        date={_.get(filters, 'date')}
                        onChange={this._handleDatePicker}
                    />
                </div>

                <Table
                    bordered
                    className={Styles.table}
                    columns={columns}
                    dataSource={data}
                    loading={loading}
                    locale={{
                        emptyText: <FormattedMessage id='no_data' />
                    }}
                    pagination={false}
                    rowKey={() => v4()}
                    size='small'
                />

                <RefactoredCashOrderModal />

                <Modal
                    footer={
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'end'
                            }}
                        >
                            <Button
                                onClick={() => {
                                    this.setState({ openShiftId: undefined });
                                }}
                            >
                                <FormattedMessage id='stock_table.button.cancel' />
                            </Button>
                            <Button
                                loading={loadingOk}
                                onClick={async () => {
                                    await this._postCashOrder(openShiftId, 'INCOME');
                                }}
                                type='primary'
                            >
                                <FormattedMessage id='ok' />
                            </Button>
                        </div>
                    }
                    onCancel={() => this.setState({ openShiftId: undefined })}
                    title={<FormattedMessage id='cash-table.contribution' />}
                    visible={Boolean(openShiftId)}
                >
                    <FormattedMessage id='cash-table.contribution_sum' />
                    <InputNumber
                        decimalSeparator=','
                        min={0}
                        onChange={contributionSum => {
                            this.setState({
                                contributionSum
                            });
                        }}
                        precision={2}
                        style={{
                            margin: '0px 8px'
                        }}
                        value={contributionSum}
                    />
                    {getCurrency()} ?
                </Modal>

                <Modal
                    footer={
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'end'
                            }}
                        >
                            <Button
                                onClick={() => {
                                    this.setState({ closeShiftId: undefined });
                                }}
                            >
                                <FormattedMessage id='stock_table.button.cancel' />
                            </Button>
                            <Button
                                loading={loadingOk}
                                onClick={async () => {
                                    await this._postCashOrder(closeShiftId, 'EXPENSE');
                                }}
                                type='primary'
                            >
                                <FormattedMessage id='ok' />
                            </Button>
                        </div>
                    }
                    onCancel={() => this.setState({ closeShiftId: undefined })}
                    open={Boolean(closeShiftId)}
                    title={<FormattedMessage id='cash-table.withdraw' />}
                    width={666}
                >
                    <FormattedMessage id='cash-table.withdraw_sum' />
                    <InputNumber
                        decimalSeparator=','
                        min={0}
                        onChange={contributionSum => {
                            this.setState({
                                contributionSum
                            });
                        }}
                        precision={2}
                        style={{
                            margin: '0px 8px'
                        }}
                        value={contributionSum}
                    />
                    {getCurrency()} ?
                </Modal>

                <Modal
                    cancelButtonProps={{
                        icon: <PrinterOutlined />,
                        onClick: () => this.printDiv(Styles.shiftTxtPrint)
                    }}
                    cancelText={this.props.intl.formatMessage({ id: 'print' })}
                    onCancel={() => {
                        setShiftTxt(undefined);
                    }}
                    onOk={() => {
                        setShiftTxt(undefined);
                    }}
                    open={shiftTxt}
                    title={<FormattedMessage id='cash-table.contribution' />}
                >
                    <div id={Styles.shiftTxtPrint}>{shiftTxt}</div>
                </Modal>

                <Modal
                    cancelButtonProps={{
                        icon: <PrinterOutlined />,
                        onClick: () => this.printDiv(Styles.setXReportTxt)
                    }}
                    cancelText={this.props.intl.formatMessage({ id: 'print' })}
                    onCancel={() => {
                        setXReportTxt(undefined);
                    }}
                    onOk={() => {
                        setXReportTxt(undefined);
                    }}
                    open={xReportTxt}
                    title={<FormattedMessage id='cash-table.contribution' />}
                >
                    <div id={Styles.setXReportTxt}>{xReportTxt}</div>
                </Modal>
            </div>
        );
    }
}
