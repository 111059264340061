export const VEHICLE = 'VEHICLE';
export const SALE_AUTO_PARTS = 'SALE_AUTO_PARTS';
export const UNIT_REPAIR = 'UNIT_REPAIR';
export const SET_GCE = 'SET_GCE';
export const TIRE_FITTING = 'TIRE_FITTING';
export const CAR_WASH = 'CAR_WASH';
export const WARRANTY_REPAIR = 'WARRANTY_REPAIR';
export const WARRANTY_UNIT_REPAIR = 'WARRANTY_UNIT_REPAIR';

export const orderTypes = [
    VEHICLE,
    SALE_AUTO_PARTS,
    UNIT_REPAIR,
    SET_GCE,
    TIRE_FITTING,
    CAR_WASH,
    WARRANTY_REPAIR,
    WARRANTY_UNIT_REPAIR
];

export const orderTypesForRules = [
    VEHICLE,
    SALE_AUTO_PARTS,
    UNIT_REPAIR,
    SET_GCE,
    TIRE_FITTING,
    CAR_WASH,
    WARRANTY_REPAIR,
    WARRANTY_UNIT_REPAIR
];

export const orderTypeMapper = {
    [VEHICLE]: {
        tabs: [
            'map',
            'acceptance',
            'diagnostic',
            'services',
            'details',
            'workshop',
            'comments',
            'history',
            'calls',
            'orderTasks',
            'station',
            'media'
        ],
        reports: [
            'actOfAcceptanceReport',
            'requestOrderReport',
            'diagnosticsReport',
            'diagnosticsBlankReport',
            'diagnosticsResultReport',
            'calculationReport',
            'invoiceReport',
            'businessOrderReport',
            'clientOrderReport',
            'packingListWithAddressesReport',
            'diagnosticsReportXLSX',
            'warrantyReport',
            'technicalConclusionReport',
            'serviceAgreementReport',
            'partsAgreementReport',
            'salesInvoiceReport',
            'completedWorkReport'
        ]
    },
    [WARRANTY_REPAIR]: {
        tabs: [
            'map',
            'acceptance',
            'diagnostic',
            'services',
            'details',
            'workshop',
            'comments',
            'history',
            'calls',
            'orderTasks',
            'station',
            'media'
        ],
        reports: [
            'actOfAcceptanceReport',
            'requestOrderReport',
            'diagnosticsReport',
            'diagnosticsBlankReport',
            'diagnosticsResultReport',
            'calculationReport',
            'invoiceReport',
            'businessOrderReport',
            'clientOrderReport',
            'packingListWithAddressesReport',
            'diagnosticsReportXLSX',
            'warrantyReport',
            'technicalConclusionReport',
            'serviceAgreementReport',
            'partsAgreementReport',
            'salesInvoiceReport',
            'completedWorkReport'
        ]
    },
    [UNIT_REPAIR]: {
        tabs: ['services', 'details', 'comments', 'media', 'orderTasks', 'calls'],
        reports: [
            'actOfAcceptanceReport',
            'requestOrderReport',
            'diagnosticsReport',
            'diagnosticsBlankReport',
            'diagnosticsResultReport',
            'calculationReport',
            'invoiceReport',
            'businessOrderReport',
            'clientOrderReport',
            'packingListWithAddressesReport',
            'diagnosticsReportXLSX',
            'warrantyReport',
            'technicalConclusionReport',
            'serviceAgreementReport',
            'partsAgreementReport',
            'salesInvoiceReport',
            'completedWorkReport'
        ]
    },
    [WARRANTY_UNIT_REPAIR]: {
        tabs: ['services', 'details', 'comments', 'media', 'orderTasks', 'calls'],
        reports: [
            'actOfAcceptanceReport',
            'requestOrderReport',
            'diagnosticsReport',
            'diagnosticsBlankReport',
            'diagnosticsResultReport',
            'calculationReport',
            'invoiceReport',
            'businessOrderReport',
            'clientOrderReport',
            'packingListWithAddressesReport',
            'diagnosticsReportXLSX',
            'warrantyReport',
            'technicalConclusionReport',
            'serviceAgreementReport',
            'partsAgreementReport',
            'salesInvoiceReport',
            'completedWorkReport'
        ]
    },
    [SALE_AUTO_PARTS]: {
        tabs: ['details', 'comments', 'history', 'media', 'calls', 'orderTasks'],
        reports: [
            'diagnosticsBlankReport',
            'diagnosticsResultReport',
            'calculationReport',
            'invoiceReport',
            'packingListWithAddressesReport',
            'diagnosticsReportXLSX',
            'warrantyReport',
            'partsAgreementReport',
            'salesInvoiceReport'
        ]
    },
    [SET_GCE]: {
        tabs: [
            'map',
            'acceptance',
            'diagnostic',
            'services',
            'details',

            'workshop',
            'comments',
            'history',
            'calls',
            'media',
            'orderTasks',
            'station'
        ],
        reports: [
            'actOfAcceptanceReport',
            'requestOrderReport',
            'diagnosticsBlankReport',
            'diagnosticsReport',
            'diagnosticsBlankReport',
            'diagnosticsResultReport',
            'calculationReport',
            'invoiceReport',
            'businessOrderReport',
            'clientOrderReport',
            'packingListWithAddressesReport',
            'diagnosticsReportXLSX',
            'warrantyReport',
            'technicalConclusionReport',
            'serviceAgreementReport',
            'partsAgreementReport',
            'salesInvoiceReport',
            'completedWorkReport'
        ]
    },
    [TIRE_FITTING]: {
        tabs: ['services', 'details', 'comments', 'media', 'calls', 'orderTasks'],
        reports: [
            'actOfAcceptanceReport',
            'requestOrderReport',
            'invoiceReport',
            'diagnosticsReportXLSX',
            'warrantyReport',
            'completedWorkReport'
        ]
    },
    [CAR_WASH]: {
        tabs: ['services', 'comments', 'media', 'calls', 'orderTasks'],
        reports: [
            'actOfAcceptanceReport',
            'requestOrderReport',
            'invoiceReport',
            'diagnosticsReportXLSX',
            'warrantyReport',
            'completedWorkReport'
        ]
    }
};

('actOfAcceptanceReport'); // actOfAcceptanceReport -> акт приема работ
('requestOrderReport'); // requestOrderReport - заявка в цех
('businessOrderReport'); // businessOrderReport -> наряд заказ в цех
('diagnosticsBlankReport'); // diagnosticsBlankReport - діагностичний бланк
('diagnosticsResultReport'); // diagnosticsResultReport - результат діагностики
('calculationReport'); // calculationReport - калькуляция
('clientOrderReport'); // clientOrderReport -> наряд заказ
('completedWorkReport'); // completedWorkReport -> акт выполненых работ
('diagnosticsReport'); // diagnosticsReport -> акт диагностики
('invoiceReport'); // invoiceReport -> счет-фактура
('warrantyReport'); // Гарантийный талон
('technicalConclusionReport'); // Техническое заключение
('salesInvoiceReport'); // Расходная накладная
('serviceAgreementReport'); // Договор про услуги
('partsAgreementReport'); // Договор про продажу автозапчастей
('packingListWithAddressesReport'); // Збірний список по з/ч
('diagnosticsReportXLSX'); // Экспорт н/з в .xlsx
