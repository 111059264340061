import { Button, Col, DatePicker, Input, Modal, Row, TimePicker } from 'antd';
import dayjs from 'dayjs';
import _ from 'lodash';
import {
    createOrder,
    DEF_DATE_FORMAT,
    DEF_TIME_FORMAT,
    selectBeginDate,
    selectBeginTime,
    selectComment,
    selectRegisterForServiceModalVisibility,
    setBeginDate,
    setBeginTime,
    setComment,
    setRegisterForServiceModalVisibility
} from 'pages/OnlineServiceBookPage/redux/duck';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import Styles from './styles.m.css';

const { TextArea } = Input;

const mapStateToProps = state => ({
    visible: selectRegisterForServiceModalVisibility(state),
    beginDate: selectBeginDate(state),
    beginTime: selectBeginTime(state),
    comment: selectComment(state)
});

const mapDispatchToProps = {
    createOrder,

    setBeginDate,
    setBeginTime,
    setComment,
    setRegisterForServiceModalVisibility
};

/**
 * This modal is used to register the user for service.
 * It creates a new order.
 * This is adapted copy of a global model, it works with auth used in this project.
 *
 * @property {*} clientId - ID of a client to create order for
 * @property {*} [vehicleId] - ID of a vehicle of a client
 * @property {*} managerId - ID of a client to create order for
 * @property {*} businessId - ID of a business to create order for
 */
@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class RegisterForServiceModal extends Component {
    handleSubmit = e => {
        e.preventDefault();

        const {
            createOrder,

            clientId,
            vehicleId,
            managerId,
            businessId
        } = this.props;

        createOrder({ clientId, vehicleId, managerId, businessId });
        this.onClose();
    };

    onClose = () => {
        this.props.setRegisterForServiceModalVisibility(false);
    };

    render() {
        const {
            visible,
            beginDate,
            beginTime,
            comment,
            setBeginDate,
            setBeginTime,
            setComment,
            intl: { formatMessage }
        } = this.props;

        return (
            <div>
                <Modal
                    destroyOnClose
                    footer={
                        <div>
                            <Button onClick={this.handleSubmit} type='primary'>
                                <FormattedMessage id='online_service_book_page.register_for_service' />
                            </Button>
                        </div>
                    }
                    onCancel={this.onClose}
                    title={
                        <div className={Styles.title}>
                            <FormattedMessage id='online_service_book_page.register_for_service' />
                        </div>
                    }
                    visible={visible}
                    width='95%'
                >
                    <Row>
                        <Col className={Styles.row} span={24}>
                            <DatePicker
                                format='DD-MM-YYYY'
                                onChange={date =>
                                    setBeginDate({ beginDate: date.format(DEF_DATE_FORMAT) })
                                }
                                popupStyle={{ zIndex: 9999 }}
                                value={dayjs(beginDate, DEF_DATE_FORMAT)}
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col className={Styles.row} span={24}>
                            <TimePicker
                                defaultOpenValue={dayjs('00:00:00', 'HH:mm:ss')}
                                format='HH'
                                onChange={time =>
                                    setBeginTime({ beginTime: time.format(DEF_TIME_FORMAT) })
                                }
                                popupStyle={{ zIndex: 9999 }}
                                value={dayjs(beginTime, DEF_TIME_FORMAT)}
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col className={Styles.row} span={24}>
                            <TextArea
                                onChange={e => setComment({ comment: _.get(e, 'target.value') })}
                                placeholder={formatMessage({
                                    id: 'online_service_book_page.comment'
                                })}
                                rows={4}
                                value={comment}
                            />
                        </Col>
                    </Row>
                </Modal>
            </div>
        );
    }
}
