import { HomeOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Modal, Select, notification } from 'antd';
import { Layout } from 'commons';
import { BusinessPackageContainer } from 'containers';
import { setBusiness } from 'core/forms/switchBusinessForm/duck';
import { MODALS, setModal } from 'core/modals/duck';
import { BusinessSearchField } from 'forms/_formkit';
import { SwitchBusinessModal } from 'modals';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { fetchAPI } from 'utils';
import { accesses, grants, isGrantAccessed } from 'utils/grants';

const { Option } = Select;

const mapStateToProps = state => ({
    user: state.auth,
    isMobile: state.ui.views.isMobile
});

const mapDispatchToProps = {
    setBusiness,
    setModal
};

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class BusinessPackagePage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false,
            managerId: undefined,
            businessId: undefined,
            managers: [],
            token: undefined
        };
    }

    handleCancel = () => {
        this.setState({
            visible: false,
            managerId: undefined,
            businessId: undefined,
            managers: [],
            token: undefined
        });
    };

    handleOk = async () => {
        const { managerId, businessId } = this.state;

        const { token } = await fetchAPI(
            'POST',
            'generate_access_token',
            undefined,
            {
                managerId,
                businessId
            },
            { handleErrorInternally: true }
        );

        this.setState({ token });
    };

    fetchManagers = async () => {
        const { businessId } = this.state;

        const query = await fetchAPI(
            'GET',
            'managers_by_businessId',
            {
                businessId
            },
            undefined,
            { handleErrorInternally: true }
        );

        this.setState({ managers: query });
    };

    render() {
        const { visible, businessId, managers, managerId, token } = this.state;

        const { setModal, user, isMobile } = this.props;

        const controls = (
            <div>
                <Button icon={<PlusOutlined />} onClick={() => this.setState({ visible: true })} />

                {isMobile && (
                    <Button
                        disabled={
                            !isGrantAccessed(
                                user,
                                grants.SETTINGS_BUSINESS_SETTINGS_OTHER_BUSINESS_NETWORK_ACCESS,
                                accesses.ROWO
                            )
                        }
                        icon={<HomeOutlined />}
                        onClick={() => {
                            setModal(MODALS.SWITCH_BUSINESS);
                        }}
                        style={{ marginLeft: 4 }}
                    />
                )}
            </div>
        );

        return (
            <Layout controls={controls} title={<FormattedMessage id='business_packages' />}>
                <BusinessPackageContainer />
                <Modal
                    destroyOnClose
                    okButtonProps={{ disabled: !businessId || !managerId }}
                    onCancel={this.handleCancel}
                    onOk={this.handleOk}
                    open={visible}
                    title={this.props.intl.formatMessage({ id: 'token' })}
                    width='fit-content'
                    zIndex={300}
                >
                    <div>
                        <BusinessSearchField
                            businessId={businessId}
                            onSelect={async businessId => {
                                await this.setState({ businessId }), this.fetchManagers();
                            }}
                            style={{ marginBottom: 8, display: 'block', minWidth: 350 }}
                        />
                        <Select
                            allowClear
                            onChange={value => {
                                this.setState({
                                    managerId: value
                                });
                            }}
                            placeholder={this.props.intl.formatMessage({
                                id: 'navigation.managers'
                            })}
                            style={{ marginBottom: 8, display: 'block', minWidth: 350 }}
                            value={managerId}
                        >
                            {managers.map(({ id, managerName, managerSurname, phone }) => (
                                <Option key={id} value={id}>
                                    {`${managerSurname} ${managerName} (${phone})`}
                                </Option>
                            ))}
                        </Select>
                        {token && <p>{token}</p>}
                        <Button
                            disabled={!token}
                            onClick={() => {
                                navigator.clipboard.writeText(token),
                                    notification.success({
                                        message: 'Copied to clipboard'
                                    });
                            }}
                        >
                            COPY
                        </Button>
                    </div>
                </Modal>
                <SwitchBusinessModal setBusiness={this.props.setBusiness} />
            </Layout>
        );
    }
}
