import dayjs from 'dayjs';

/**
 * Constants
 * */
export const moduleName = 'cash';
const prefix = `cpb/${moduleName}`;

export const FETCH_CASHBOXES = `${prefix}/FETCH_CASHBOXES`;
export const FETCH_CASHBOXES_SUCCESS = `${prefix}/FETCH_CASHBOXES_SUCCESS`;

export const FETCH_CASH_ORDERS = `${prefix}/FETCH_CASH_ORDERS`;
export const FETCH_CASH_ORDERS_SUCCESS = `${prefix}/FETCH_CASH_ORDERS_SUCCESS`;

export const FETCH_CASHBOXES_BALANCE = `${prefix}/FETCH_CASHBOXES_BALANCE`;
export const FETCH_CASHBOXES_BALANCE_SUCCESS = `${prefix}/FETCH_CASHBOXES_BALANCE_SUCCESS`;
export const SET_FETCHING_CASHBOXES_BALANCE = `${prefix}/SET_FETCHING_CASHBOXES_BALANCE`;

export const FETCH_CASHBOXES_ACTIVITY = `${prefix}/FETCH_CASHBOXES_ACTIVITY`;
export const FETCH_CASHBOXES_ACTIVITY_SUCCESS = `${prefix}/FETCH_CASHBOXES_ACTIVITY_SUCCESS`;

export const FETCH_ANALYTICS = `${prefix}/FETCH_ANALYTICS`;
export const FETCH_ANALYTICS_SUCCESS = `${prefix}/FETCH_ANALYTICS_SUCCESS`;

export const CREATE_CASHBOX = `${prefix}/CREATE_CASHBOX`;
export const CREATE_CASHBOX_SUCCESS = `${prefix}/CREATE_CASHBOX_SUCCESS`;

export const UPDATE_CASHBOX = `${prefix}/UPDATE_CASHBOX`;
export const UPDATE_CASHBOX_SUCCESS = `${prefix}/UPDATE_CASHBOX_SUCCESS`;

export const DELETE_CASHBOX = `${prefix}/DELETE_CASHBOX`;
export const DELETE_CASHBOX_SUCCESS = `${prefix}/DELETE_CASHBOX_SUCCESS`;

export const SET_CASH_ORDERS_FILTERS = `${prefix}/SET_CASH_ORDERS_FILTERS`;
export const SET_CASH_ORDERS_PAGE = `${prefix}/SET_CASH_ORDERS_PAGE`;
export const SET_CASH_ACCOUNTING_FILTERS = `${prefix}/SET_CASH_ACCOUNTING_FILTERS`;

export const SET_SEARCH_QUERY = `${prefix}/SET_SEARCH_QUERY`;
export const SET_ANALYTICS_FETCHING_STATE = `${prefix}/SET_ANALYTICS_FETCHING_STATE`;

export const PRINT_CASH_ORDERS = `${prefix}/PRINT_CASH_ORDERS`;
export const PRINT_CASH_ORDERS_SUCCESS = `${prefix}/PRINT_CASH_ORDERS_SUCCESS`;

// Робота з касами які підключені до податкової
export const GET_RST_STATUSES = `${prefix}/GET_RST_STATUSES`; // Відкрити зміну(касу) для здійснення внесень коштів в неї(валідне тільки для кас з РРО)
export const GET_RST_STATUSES_SUCCESS = `${prefix}/GET_RST_STATUSES_SUCCESS`;

export const OPEN_SHIFT = `${prefix}/OPEN_SHIFT`; // Відкрити зміну(касу) для здійснення внесень коштів в неї(валідне тільки для кас з РРО)
export const OPEN_SHIFT_SUCCESS = `${prefix}/OPEN_SHIFT_SUCCESS`;

export const CLOSE_SHIFT = `${prefix}/CLOSE_SHIFT`; // Закрити зміну(касу), валідне тільки для кас з РРО
export const CLOSE_SHIFT_SUCCESS = `${prefix}/CLOSE_SHIFT_SUCCESS`;

export const SERVICE_INPUT = `${prefix}/SERVICE_INPUT`; // Внести гроші в касу
export const SERVICE_INPUT_SUCCESS = `${prefix}/SERVICE_INPUT_SUCCESS`;

export const SERVICE_OUTPUT = `${prefix}/SERVICE_OUTPUT`; // Видати гроші з каси
export const SERVICE_OUTPUT_SUCCESS = `${prefix}/SERVICE_OUTPUT_SUCCESS`;

export const FETCH_X_REPORT = `${prefix}/FETCH_X_REPORT`; // Отримати xReport
export const FETCH_X_REPORT_SUCCESS = `${prefix}/FETCH_X_REPORT_SUCCESS`;

export const SET_SHIFT_TXT = `${prefix}/SET_SHIFT_TXT`;
export const SET_X_REPORT_TXT = `${prefix}/SET_X_REPORT_TXT`;

export const REGISTER_CASH_ORDER_IN_CASHDESK = `${prefix}/REGISTER_CASH_ORDER_IN_CASHDESK`;
export const REGISTER_SERVICE_INPUT_CASH_ORDER_IN_CASHDESK = `${prefix}/REGISTER_SERVICE_INPUT_CASH_ORDER_IN_CASHDESK`;
export const REGISTER_SERVICE_OUTPUT_CASH_ORDER_IN_CASHDESK = `${prefix}/REGISTER_SERVICE_OUTPUT_CASH_ORDER_IN_CASHDESK`;

export const SEND_EMAIL_WITH_RECEIPT = `${prefix}/SEND_EMAIL_WITH_RECEIPT`;
export const SEND_SMS_WITH_RECEIPT = `${prefix}/SEND_SMS_WITH_RECEIPT`;
export const DOWNLOAD_RECEIPT = `${prefix}/DOWNLOAD_RECEIPT`;

/**
 * Reducer
 * */
const ReducerState = {
    cashboxes: [],
    analytics: [],
    activity: [],
    balance: [],
    stats: {},
    cashOrders: [],
    cashOrdersFilters: {
        startDate: dayjs().subtract(30, 'days').format('YYYY-MM-DD'),
        endDate: dayjs().format('YYYY-MM-DD'),
        query: '',
        sortField: 'id',
        sortOrder: 'desc',
        analyticsUniqueId: undefined,
        page: 1,
        pageSize: 25
    },
    cashAccountingFilters: {
        date: dayjs(),
        startDate: dayjs().startOf('month').format('YYYY-MM-DD'),
        endDate: dayjs().format('YYYY-MM-DD')
    },
    analyticsFetchingState: false,
    isFetchCashboxesBalance: false,
    shiftTxt: '',
    xReportTxt: ''
};
// eslint-disable-next-line
export default function reducer(state = ReducerState, action) {
    const { type, payload } = action;

    switch (type) {
        case FETCH_CASHBOXES_SUCCESS:
            return {
                ...state,
                cashboxes: payload
            };

        case FETCH_CASHBOXES_BALANCE_SUCCESS:
            return {
                ...state,
                balance: [...payload]
            };

        case SET_FETCHING_CASHBOXES_BALANCE:
            return {
                ...state,
                isFetchCashboxesBalance: payload
            };

        case FETCH_CASHBOXES_ACTIVITY_SUCCESS:
            return {
                ...state,
                activity: [...payload]
            };

        case FETCH_ANALYTICS_SUCCESS:
            return {
                ...state,
                analytics: payload
            };

        case CREATE_CASHBOX_SUCCESS:
            return {
                ...state,
                cashboxes: [...state.cashboxes, payload]
            };

        case UPDATE_CASHBOX_SUCCESS:
            return {
                ...state,
                cashboxes: [...state.cashboxes, payload]
            };

        case DELETE_CASHBOX_SUCCESS:
            return {
                ...state,
                cashboxes: [...state.cashboxes, payload]
            };

        case FETCH_CASH_ORDERS:
            return {
                ...state,
                cashOrdersFilters: {
                    ...state.cashOrdersFilters,
                    ...payload
                }
            };

        case FETCH_CASH_ORDERS_SUCCESS:
            return {
                ...state,
                cashOrders: payload.list,
                stats: payload.stats
            };

        case SET_CASH_ORDERS_FILTERS:
            return {
                ...state,
                cashOrdersFilters: {
                    ...state.cashOrdersFilters,
                    ...payload,
                    page: 1,
                    pageSize: 25
                }
            };

        case SET_CASH_ORDERS_PAGE:
            return {
                ...state,
                cashOrdersFilters: {
                    ...state.cashOrdersFilters,
                    page: payload,
                    pageSize: payload
                }
            };

        case SET_SEARCH_QUERY:
            return {
                ...state,
                cashOrdersFilters: {
                    ...state.cashOrdersFilters,
                    query: payload,
                    page: 1,
                    pageSize: 25
                }
            };

        case SET_CASH_ACCOUNTING_FILTERS:
            return {
                ...state,
                cashAccountingFilters: {
                    ...state.cashAccountingFilters,
                    ...payload
                }
            };

        case SET_ANALYTICS_FETCHING_STATE:
            return {
                ...state,
                analyticsFetchingState: payload
            };

        case SET_SHIFT_TXT:
            return {
                ...state,
                shiftTxt: payload
            };

        case SET_X_REPORT_TXT:
            return {
                ...state,
                xReportTxt: payload
            };

        case GET_RST_STATUSES_SUCCESS:
            return {
                ...state,
                cashboxes: payload.cashboxes,
                balance: payload.balance
            };

        default:
            return state;
    }
}

/**
 * Selectors
 * */

export const stateSelector = state => state[moduleName];
export const selectCashStats = state => ({
    increase: state.cash.stats.increase,
    decrease: state.cash.stats.decrease,
    balance: Number(state.cash.stats.increase - state.cash.stats.decrease)
});
export const selectCashOrdersFilters = state => state.cash.cashOrdersFilters;

export const selectCashAccountingFilters = state => state.cash.cashAccountingFilters;

export const selectCashboxes = state => state.cash.cashboxes;
export const selectCashboxesBalance = state => state.cash.balance;

/**
 * Action Creators
 * */

// cashboxes
export const fetchCashboxes = () => ({
    type: FETCH_CASHBOXES
});

export const fetchCashboxesSuccess = cashboxes => ({
    type: FETCH_CASHBOXES_SUCCESS,
    payload: cashboxes
});

export const fetchCashboxesBalance = () => ({
    type: FETCH_CASHBOXES_BALANCE
});

export const fetchRSTStatusesSuccess = payload => ({
    type: GET_RST_STATUSES_SUCCESS,
    payload
});

export const setFetchingCashboxesBalance = status => ({
    type: SET_FETCHING_CASHBOXES_BALANCE,
    payload: status
});

export const fetchCashboxesBalanceSuccess = balance => ({
    type: FETCH_CASHBOXES_BALANCE_SUCCESS,
    payload: balance
});

export const fetchCashboxesActivity = () => ({
    type: FETCH_CASHBOXES_ACTIVITY
});

export const fetchCashboxesActivitySuccess = activity => ({
    type: FETCH_CASHBOXES_ACTIVITY_SUCCESS,
    payload: activity
});

export const fetchAnalytics = () => ({
    type: FETCH_ANALYTICS
});

export const fetchAnalyticsSuccess = analytics => ({
    type: FETCH_ANALYTICS_SUCCESS,
    payload: analytics
});

export const createCashbox = cashbox => ({
    type: CREATE_CASHBOX,
    payload: cashbox
});

export const createCashboxSuccess = cashboxes => ({
    type: CREATE_CASHBOX_SUCCESS,
    payload: cashboxes
});

export const updateCashbox = cashbox => ({
    type: UPDATE_CASHBOX,
    payload: cashbox
});

export const updateCashboxSuccess = cashboxes => ({
    type: UPDATE_CASHBOX_SUCCESS,
    payload: cashboxes
});

export const deleteCashbox = id => ({
    type: DELETE_CASHBOX,
    payload: id
});

export const deleteCashboxSuccess = cashbox => ({
    type: DELETE_CASHBOX_SUCCESS,
    payload: cashbox
});

export const setCashOrdersFilters = filters => ({
    type: SET_CASH_ORDERS_FILTERS,
    payload: filters
});

export const setCashOrdersPage = ({ page, pageSize }) => ({
    type: SET_CASH_ORDERS_PAGE,
    payload: { page, pageSize }
});

export const setCashAccountingFilters = filters => ({
    type: SET_CASH_ACCOUNTING_FILTERS,
    payload: filters
});

export const setAnalyticsFetchingState = val => ({
    type: SET_ANALYTICS_FETCHING_STATE,
    payload: val
});

// cash orders

export const fetchCashOrders = filters => ({
    type: FETCH_CASH_ORDERS,
    payload: filters
});

export const fetchCashOrdersSuccess = cashOrders => ({
    type: FETCH_CASH_ORDERS_SUCCESS,
    payload: cashOrders
});

export const printCashOrder = () => ({
    type: PRINT_CASH_ORDERS
});

export const printCashOrderSuccess = doc => ({
    type: PRINT_CASH_ORDERS_SUCCESS,
    payload: doc
});

export const setSearchQuery = searchQuery => ({
    type: SET_SEARCH_QUERY,
    payload: searchQuery
});

// RST cashboxes

export const getRSTStatuses = () => ({
    type: GET_RST_STATUSES
});

export const openShift = (cashboxId, callback) => ({
    type: OPEN_SHIFT,
    payload: { cashboxId, callback }
});

export const openShiftSuccess = () => ({
    type: OPEN_SHIFT_SUCCESS
});

export const closeShift = cashboxId => ({
    type: CLOSE_SHIFT,
    payload: cashboxId
});

export const setShiftTxt = text => ({
    type: SET_SHIFT_TXT,
    payload: text
});

export const setXReportTxt = text => ({
    type: SET_X_REPORT_TXT,
    payload: text
});

export const closeShiftSuccess = () => ({
    type: CLOSE_SHIFT_SUCCESS
});

/**
 * Внести гроші в касу
 * @returns
 */
export const serviceInput = ({ cashboxId, serviceInputSum }) => ({
    type: SERVICE_INPUT,
    payload: { cashboxId, serviceInputSum }
});

export const serviceInputSuccess = () => ({
    type: SERVICE_INPUT_SUCCESS
});

/**
 * Інкасація(видача) частини грошей з каси
 * @returns
 */
export const serviceOutput = ({ cashboxId, serviceOutputSum }) => ({
    type: SERVICE_OUTPUT,
    payload: { cashboxId, serviceOutputSum }
});

export const serviceOutputSuccess = () => ({
    type: SERVICE_OUTPUT_SUCCESS
});

export const fetchXReport = cashboxId => ({
    type: FETCH_X_REPORT,
    payload: cashboxId
});

export const fetchXReportSuccess = () => ({
    type: FETCH_X_REPORT_SUCCESS
});

/**
 * For cashboxes with rst we can register them in cashdesk,
 * this action is used to register sale and return
 * @param cashOrderId - cashOrder will be used to generate data and register int on cashdesk service
 */
export const registerCashOrderInCashdesk = cashOrderId => ({
    type: REGISTER_CASH_ORDER_IN_CASHDESK,
    payload: cashOrderId
});

/**
 * Register cashOrder in cashdesk if it is service input(cashorder of type "INCOME" without clientId but with otherCounterparty)
 * @param {*} params.cashOrderId
 * @returns
 */
export const registerServiceInputCashOrderInCashdesk = ({ cashOrderId }) => ({
    type: REGISTER_SERVICE_INPUT_CASH_ORDER_IN_CASHDESK,
    payload: { cashOrderId }
});

/**
 * Register cashOrder in cashdesk if it is service output(cashorder of type "EXPENSE")
 * @param {*} params.cashOrderId
 */
export const registerServiceOutputCashOrderInCashdesk = ({ cashOrderId }) => ({
    type: REGISTER_SERVICE_OUTPUT_CASH_ORDER_IN_CASHDESK,
    payload: { cashOrderId }
});

/**
 * Send email to a client from cash order, that email contains receipth
 * @param {Object} params
 * @param {String|Number} params.cashOrderId - Cash order to generate data from
 */
export const sendEmailWithReceipt = ({ cashOrderId }) => ({
    type: SEND_EMAIL_WITH_RECEIPT,
    payload: { cashOrderId }
});

/**
 * Send sms to a client from cashorder, that sms contains receipt info
 * @param {Object} params
 * @param {String|Number} params.cashOrderId - Cash order to generate data from
 * @returns
 */
export const sendSmsWithReceipt = ({ cashOrderId }) => ({
    type: SEND_SMS_WITH_RECEIPT,
    payload: { cashOrderId }
});

/**
 * Download receipt for an cash order which was made through cashbox with RST,
 * other types of cashboxes do not have this ability
 * @param {*} param.cashOrderId - cash order registgred via RST cashbox
 * @returns
 */
export const downloadReceipt = ({ cashOrderId }) => ({
    type: DOWNLOAD_RECEIPT,
    payload: { cashOrderId }
});
