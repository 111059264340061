import { DatePicker, InputNumber, Modal } from 'antd';
import { MODALS, resetModal, selectModal, selectModalProps } from 'core/modals/duck';
import dayjs from 'dayjs';
import _ from 'lodash';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import {
    DEF_BACK_DATE_FORMAT,
    DEF_UI_DATE_FORMAT,
    resetFields,
    saveMileage,
    selectMileage,
    selectMileageDateTime,
    setMileage,
    setMileageDateTime
} from './redux/duck';
import Styles from './styles.m.css';

const mapStateToProps = state => ({
    modalProps: selectModalProps(state),
    visible: selectModal(state),

    mileageDateTime: selectMileageDateTime(state),
    mileage: selectMileage(state)
});

const mapDispatchToProps = {
    resetModal,
    setMileage,
    setMileageDateTime,
    saveMileage,
    resetFields
};

/**
 * This modal is supposed to update vehicle mileage value.
 * @property { String | Number } modalProps.vehicleId - id of a vehicle to set mileage for
 */
@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class ChangeVehicleMileageModal extends Component {
    onSubmit = () => {
        const { saveMileage, modalProps } = this.props;
        saveMileage({
            vehicleId: _.get(modalProps, 'vehicleId')
        });
        this.closeModal();
    };

    closeModal = () => {
        const { resetModal, resetFields } = this.props;
        resetModal();
        resetFields();
    };

    render() {
        const {
            visible,
            mileage,
            setMileage,
            setMileageDateTime,
            mileageDateTime,
            intl: { formatMessage }
        } = this.props;

        return (
            <div>
                <Modal
                    destroyOnClose
                    okButtonProps={{
                        disabled: !mileage
                    }}
                    onCancel={this.closeModal}
                    onOk={this.onSubmit}
                    title={
                        <div className={Styles.title}>
                            <FormattedMessage id='change_vehicle_mileage_modal.title' />
                        </div>
                    }
                    visible={visible === MODALS.CHANGE_VEHICLE_MILEAGE_MODAL}
                >
                    <DatePicker
                        className={Styles.datePicker}
                        format={DEF_UI_DATE_FORMAT}
                        onChange={val => setMileageDateTime(val.format(DEF_BACK_DATE_FORMAT))}
                        popupStyle={{ zIndex: '5000' }}
                        style={{ width: '100%' }}
                        value={dayjs(mileageDateTime)}
                    />

                    <InputNumber
                        className={Styles.input}
                        decimalSeparator=','
                        onChange={value => {
                            setMileage(value);
                        }}
                        placeholder={formatMessage({ id: 'change_vehicle_mileage_modal.mileage' })}
                        value={mileage}
                    />
                </Modal>
            </div>
        );
    }
}
