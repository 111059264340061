import { Checkbox, Modal } from 'antd';
import { DateRangePicker } from 'components';
import dayjs from 'dayjs';
import { saveAs } from 'file-saver';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { fetchAPI } from 'utils';
import { grants, isGrantAccessed } from 'utils/grants';
import Styles from '../styles.m.css';

const mapStateToProps = state => {
    return {
        user: state.auth,
        isMobile: state.ui.views.isMobile
    };
};

const requiredField = () => <b style={{ color: 'var(--required)' }}> *</b>;

@injectIntl
@connect(mapStateToProps, void 0)
export class DownloadReportModal extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    handleCancel = () => {
        const { hideModal } = this.props;
        this.setState({
            dateFrom: undefined,
            dateTo: undefined,
            showAll: false
        });
        hideModal();
    };

    handleOk = async () => {
        await this.downloadReport();
        this.handleCancel();
    };

    downloadReport = async () => {
        const { dateFrom, dateTo, showAll } = this.state;
        console.log(dateFrom, dateTo);
        const url = '/report/excel/licenses';
        try {
            const response = await fetchAPI(
                'GET',
                url,
                {
                    dateFrom: dateFrom ? dateFrom.toISOString() : undefined,
                    dateTo: dateTo ? dateTo.toISOString() : undefined,
                    showAll
                },
                null,
                {
                    rawResponse: true,
                    handleErrorInternally: true
                }
            );

            const reportFile = await response.blob();

            const contentDispositionHeader = response.headers.get('content-disposition');
            const fileName = contentDispositionHeader.match(/^attachment; filename="(.*)"/)[1];
            await saveAs(reportFile, fileName);
            this.handleCancel();
        } catch (error) {
            console.error('ERROR:', error);
        }
    };

    render() {
        const { visible, user,isMobile} = this.props;
        const { dateFrom, dateTo, showAll } = this.state;

        return (
            <Modal
                okButtonProps={{
                    disabled: !dateFrom || !dateTo || !isGrantAccessed(user, grants.ADMINISTRATION_BUSINESS_PACKAGES)
                }}
                onCancel={this.handleCancel}
                onOk={this.handleOk}
                open={visible}
                title={
                    <div>
                        <FormattedMessage id='business-package-container.download_report' />
                    </div>
                }
                width={isMobile ? '100%' : '25%'}
            >
                <div className={Styles.blockStyle}>
                    <div>
                        <div className={Styles.rowStyles}>
                            <FormattedMessage id='setting-salary.period' />
                            {requiredField()}
                            <DateRangePicker
                                allowClear
                                dateRange={[dateFrom ? dayjs(dateFrom) : undefined, dateTo ? dayjs(dateTo) : undefined]}
                                onDateChange={async dateRange => {
                                    await this.setState({
                                        dateFrom: dateRange[0],
                                        dateTo: dateRange[1]
                                    });
                                }}
                                popupStyle={{
                                    maxHeight: 400,
                                    overflow: 'auto',
                                    zIndex: '9999',
                                    minWidth: 220
                                }}
                                style={{ width: '100%' }}
                            />

                            {/* <DatePicker
                                allowClear
                                format='YYYY-MM-DD '
                                getPopupContainer={trigger => trigger.parentNode}
                                onChange={value => {
                                    this.setState({
                                        dateFrom: value
                                    });
                                }}
                                placeholder={this.props.intl.formatMessage({
                                    id: 'select_date'
                                })}
                                style={{
                                    width: '100%',
                                    marginTop: 4
                                }}
                                value={dateFrom}
                            /> */}
                        </div>
                        <div className={Styles.checkboxStyle}>
                            <Checkbox
                                checked={showAll}
                                onClick={() => {
                                    this.setState({
                                        showAll: !showAll
                                    });
                                }}
                            />
                            <span className={Styles.checkBoxMargin}>
                                <FormattedMessage id='business-package-container.download_report.show_all' />
                            </span>
                        </div>
                        {/* <div className={Styles.rowStyles}>
                            <FormattedMessage id='employee_salary_rules.end_period' />
                            {requiredField()}
                            <DatePicker
                                allowClear
                                format='YYYY-MM-DD '
                                getPopupContainer={trigger => trigger.parentNode}
                                onChange={value => {
                                    this.setState({
                                        dateTo: value
                                    });
                                }}
                                placeholder={this.props.intl.formatMessage({
                                    id: 'select_date'
                                })}
                                style={{
                                    width: '100%',
                                    marginTop: 4
                                }}
                                value={dateTo}
                            />
                        </div> */}
                    </div>
                </div>
            </Modal>
        );
    }
}
