import { Modal, Select } from 'antd';
import { fetchClients, setClientsSearchFilter } from 'core/clients/duck';
import { MODALS, resetModal, selectModal, selectModalProps } from 'core/modals/duck';
import _ from 'lodash';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { fetchAPI, toCyrillicTransliterate } from 'utils';

const { Option } = Select;

const mapStateToProps = state => ({
    modalProps: selectModalProps(state),
    visible: selectModal(state),
    clients: state.clients.clients,
    user: state.auth
});

const mapDispatchToProps = {
    resetModal,
    fetchClients,
    setClientsSearchFilter
};

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class OrderClientChangeModal extends Component {
    constructor(props) {
        super(props);
        this.state = {};

        this.handleSearchClients = _.debounce(value => {
            const { setClientsSearchFilter, fetchClients } = this.props;
            setClientsSearchFilter(value);
            fetchClients();
        }, 500).bind(this);
    }

    handleOk = async () => {
        const { resetModal, orderId, onSubmit } = this.props;
        const { vehicleId, clientId, phone } = this.state;

        const response = await fetchAPI(
            'PUT',
            `orders/${orderId}`,
            null,
            {
                clientId,
                clientVehicleId: vehicleId || null,
                clientPhone: phone,
                clientRequisiteId: null
            },
            { handleErrorInternally: true }
        );
        // onSubmit();
        window.location.reload();
        resetModal();
    };

    componentDidUpdate(prevProps) {
        const { visible } = this.props;
        if (visible === MODALS.ORDER_CLIENT_CHANGE && prevProps.visible !== visible) {
            this.props.fetchClients();
        }
    }

    render() {
        const { resetModal, visible, clients } = this.props;

        const { vehicleId, clientId, vehicles } = this.state;

        return (
            <div>
                <Modal
                    destroyOnClose
                    onCancel={() => resetModal()}
                    onOk={this.handleOk}
                    open={visible === MODALS.ORDER_CLIENT_CHANGE}
                    title={<FormattedMessage id='add_order_form.search_client' />}
                >
                    <Select
                        allowClear
                        dropdownStyle={{ zIndex: '9999' }}
                        filterOption={false}
                        onChange={(clientId, option) => {
                            if (option) {
                                this.setState({
                                    clientId,
                                    vehicles: option.props.vehicles,
                                    vehicleId:
                                        option.props.vehicles && option.props.vehicles.length
                                            ? option.props.vehicles[0].id
                                            : undefined,
                                    phone: option.props.phone
                                });
                            } else {
                                this.setState({
                                    clientId: undefined,
                                    vehicles: undefined,
                                    phone: undefined
                                });
                            }
                        }}
                        onSearch={this.handleSearchClients}
                        placeholder={this.props.intl.formatMessage({ id: 'add_order_form.client' })}
                        showSearch
                        style={{
                            marginBottom: 8
                        }}
                        value={clientId}
                    >
                        {clients.map(client => (
                            <Option
                                key={client.clientId}
                                phone={_.get(client, 'phones[0]')}
                                value={client.clientId}
                                vehicles={_.get(client, 'vehicles')}
                            >
                                {`${client.surname || ''} ${client.name} ${client.middleName || ''} ${_.get(
                                    client,
                                    'phones[0]',
                                    ''
                                )}`}
                            </Option>
                        ))}
                    </Select>
                    <Select
                        allowClear
                        disabled={!vehicles}
                        dropdownStyle={{ zIndex: '9999' }}
                        filterOption={(inputValue, option) => {
                            const optionText = option.children.join('').toLowerCase();
                            const inputText = inputValue.toLowerCase();

                            return (
                                optionText.includes(toCyrillicTransliterate(inputText)) ||
                                optionText.includes(inputText)
                            );
                        }}
                        onChange={vehicleId => {
                            this.setState({ vehicleId });
                        }}
                        // optionFilterProp='children'
                        placeholder={this.props.intl.formatMessage({ id: 'locations.vehicle' })}
                        showSearch
                        style={{
                            marginBottom: 8
                        }}
                        value={vehicleId}
                    >
                        {(vehicles || []).map(({ id, make, model, vin, number }) => (
                            <Option key={id} value={id}>
                                {make} {model} {number} {vin}
                            </Option>
                        ))}
                    </Select>
                </Modal>
            </div>
        );
    }
}
