import { Checkbox, Modal } from 'antd';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';

const RecalculateSettingsModal = ({ open, close, updateOrderField }) => {
    const [recalculateOrderDuration, setRecalculateOrderDuration] = useState(false);
    const [recalculateDuration, setRecalculateDuration] = useState(false);
    const onOk = () => {
        localStorage.setItem('_my.carbook.recalculateOrderDuration', JSON.stringify(recalculateOrderDuration));
        localStorage.setItem('_my.carbook.recalculateDuration', JSON.stringify(recalculateDuration));
        updateOrderField(recalculateOrderDuration ? 'duration' : null, recalculateDuration);
        close();
    };

    useEffect(() => {
        setRecalculateOrderDuration(JSON.parse(localStorage.getItem('_my.carbook.recalculateOrderDuration')));
        setRecalculateDuration(JSON.parse(localStorage.getItem('_my.carbook.recalculateDuration')));
    }, [open]);

    return (
        <Modal
            destroyOnClose
            onCancel={close}
            onClose={close}
            onOk={onOk}
            open={open}
            title={<FormattedMessage id='order_form_table.recalculation' />}
        >
            <div>
                <Checkbox
                    checked={recalculateOrderDuration}
                    onChange={() => setRecalculateOrderDuration(!recalculateOrderDuration)}
                >
                    <FormattedMessage id='order_form_table.recalculate' />
                </Checkbox>
            </div>
            <div>
                <Checkbox checked={recalculateDuration} onChange={() => setRecalculateDuration(!recalculateDuration)}>
                    <FormattedMessage id='order_form_table.recalculate_each' />
                </Checkbox>
            </div>
        </Modal>
    );
};

export default RecalculateSettingsModal;
