import { Form } from '@ant-design/compatible';
import { Col, DatePicker, Input, InputNumber, Modal, Row, Select } from 'antd';
import React from 'react';

import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { fetchAPI } from 'utils';
import Styles from './styles.m.css';

const mapStateToProps = state => ({
    user: state.auth,
    modalProps: state.modals.modalProps
});

const { Option } = Select;

const dateFormat = 'MM.YYYY';

@injectIntl
@connect(mapStateToProps)
export default class OwnVehicleModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            bodyTypes: [],
            fuelTypes: [],
            years: [],
            makes: [],
            models: [],
            modifications: []
        };
    }

    componentDidMount() {
        this.fetchAttributes();
    }

    componentDidUpdate(prevProps) {
        const { visible, fields, years, makes, models, modifications, modelSearch, modificationSearch } = this.props;
        if (!prevProps.visible && visible) {
            this.setState({
                ...fields,
                years,
                makes,
                models,
                modifications,
                model: modelSearch.trim(),
                modelId: modelSearch ? undefined : fields.modelId,
                modification: modificationSearch ? modificationSearch.trim() : undefined,
                modificationId: undefined,

                modelSearch,
                modificationSearch
            });
        }
    }

    fetchData = async () => {
        const { year, makeId, modelId, modificationId } = this.state;
        if (!year) {
            const { years } = await fetchAPI('GET', 'vehicles_info');
            this.setState({ years });
        } else if (!makeId) {
            const { makes } = await fetchAPI('GET', 'vehicles_info', { year });
            this.setState({ makes });
        } else if (!modelId) {
            const { models } = await fetchAPI('GET', 'vehicles_info', { year, makeId });
            this.setState({ models });
        } else if (!modificationId) {
            const { modifications } = await fetchAPI('GET', 'vehicles_info', {
                year,
                makeId,
                modelId
            });
            this.setState({ modifications });
        }
    };

    fetchAttributes = async () => {
        const { body, fuel } = await fetchAPI('GET', '/custom/vehicle/attributes', null, null, {
            handleErrorInternally: true
        });

        this.setState({
            bodyTypes: body,
            fuelTypes: fuel
        });
    };

    onOk = async () => {
        const { updateModels, updateModifications } = this.props;
        const {
            year,
            makes,
            makeId,
            modelId,
            models,
            modificationId,
            modification,
            vehicleYearFrom,
            vehicleYearTo,
            bodyId,
            bodyType,
            fuelId,
            fuelType,
            hp,
            capacity
        } = this.state;

        const make = makes.find(({ id }) => id === makeId).name;
        const model = this.state.model || models.find(({ id }) => id === modelId).name;

        await fetchAPI(
            'POST',
            '/add/custom/vehicle',
            null,
            {
                makeId,
                make,
                modelId,
                model,
                modificationId,
                modification,
                vehicleYearFrom: vehicleYearFrom ? vehicleYearFrom.format('MM.YYYY') : undefined,
                vehicleYearTo: vehicleYearTo ? vehicleYearTo.format('MM.YYYY') : undefined,
                bodyId,
                bodyType,
                fuelId,
                fuelType,
                hp,
                capacity
            },
            {
                handleErrorInternally: true
            }
        );

        let modelIdNew;

        if (!modelId) {
            const { models } = await fetchAPI('GET', 'vehicles_info', { year, makeId });
            const modelId = models.reverse().find(({ name }) => name.includes(model)).id;
            modelIdNew = modelId;
            updateModels(modelId);
        }
        if (!modificationId) {
            const { modifications } = await fetchAPI('GET', 'vehicles_info', {
                year,
                makeId,
                modelId: modelIdNew || modelId
            });
            const modificationId = modifications.reverse().find(({ name }) => name.includes(modification)).id;
            updateModifications(modificationId);
        }

        this.handleCancel();
    };

    handleCancel = () => {
        this.setState({
            makes: [],
            models: [],
            modifications: [],
            makeId: undefined,
            modelId: undefined,
            modificationId: undefined,
            vehicleYearFrom: undefined,
            vehicleYearTo: undefined,
            bodyId: undefined,
            bodyType: undefined,
            fuelId: undefined,
            fuelType: undefined,
            hp: undefined,
            capacity: undefined
        });
        this.props.hideModal();
    };

    render() {
        const {
            visible,

            intl: { formatMessage }
        } = this.props;

        const {
            years,
            makes,
            models,
            modifications,
            bodyTypes,
            fuelTypes,
            bodyId,
            fuelId,
            year,
            makeId,
            modelId,
            modificationId,
            model,
            modification,
            hp,
            capacity,

            modelSearch,
            modificationSearch,
            vehicleYearFrom,
            vehicleYearTo
        } = this.state;

        return (
            <div>
                <Modal
                    onCancel={this.handleCancel}
                    onOk={this.onOk}
                    title={
                        <React.Fragment>
                            <FormattedMessage id='add_client_form.own_vehicle_modal' />
                        </React.Fragment>
                    }
                    visible={visible}
                    width='50%'
                >
                    <Form>
                        <Row className={Styles.row}>
                            <Col span={6}>
                                <div className={Styles.colText}>
                                    <FormattedMessage id='add_client_form.body_type' />
                                </div>
                            </Col>
                            <Col span={12}>
                                <Select
                                    allowClear
                                    getPopupContainer={trigger => trigger.parentNode}
                                    onChange={(bodyId, option) => {
                                        if (!bodyId) {
                                            this.setState({
                                                bodyId,
                                                bodyType: undefined
                                            });
                                        } else {
                                            this.setState({ bodyId, bodyType: option.type });
                                        }
                                    }}
                                    optionFilterProp='children'
                                    showSearch
                                    value={bodyId}
                                >
                                    {bodyTypes.map(({ id, type }) => (
                                        <Option key={`${id}-${type}`} type={type} value={id}>
                                            {type}
                                        </Option>
                                    ))}
                                </Select>
                            </Col>
                        </Row>
                        <Row className={Styles.row}>
                            <Col span={6}>
                                <div className={Styles.colText}>
                                    <FormattedMessage id='add_client_form.fuel_type' />
                                </div>
                            </Col>
                            <Col span={12}>
                                <Select
                                    allowClear
                                    getPopupContainer={trigger => trigger.parentNode}
                                    onChange={(fuelId, option) => {
                                        if (!fuelId) {
                                            this.setState({
                                                fuelId,
                                                fuelType: undefined
                                            });
                                        } else {
                                            this.setState({ fuelId, fuelType: option.type });
                                        }
                                    }}
                                    optionFilterProp='children'
                                    showSearch
                                    value={fuelId}
                                >
                                    {fuelTypes.map(({ id, type }) => (
                                        <Option key={`${id}-${type}`} type={type} value={id}>
                                            {type}
                                        </Option>
                                    ))}
                                </Select>
                            </Col>
                        </Row>

                        <Row className={Styles.row}>
                            <Col span={6}>
                                <div className={Styles.colText}>
                                    <FormattedMessage id='add_client_form.year' />
                                </div>
                            </Col>
                            <Col span={12}>
                                <Select
                                    allowClear
                                    getPopupContainer={trigger => trigger.parentNode}
                                    onChange={async year => {
                                        if (!year) {
                                            await this.setState({
                                                year,
                                                makeId: undefined,
                                                modelId: undefined,
                                                model: undefined,
                                                modificationId: undefined,
                                                modification: undefined
                                            });
                                        } else {
                                            await this.setState({ year });
                                            this.fetchData();
                                        }
                                    }}
                                    optionFilterProp='children'
                                    showSearch
                                    value={year}
                                >
                                    {years.map(year => (
                                        <Option key={year} value={year}>
                                            {year}
                                        </Option>
                                    ))}
                                </Select>
                            </Col>
                        </Row>
                        <Row className={Styles.row}>
                            <Col span={6}>
                                <div className={Styles.colText}>
                                    <FormattedMessage id='add_client_form.make' />
                                </div>
                            </Col>
                            <Col span={12}>
                                <Select
                                    allowClear
                                    disabled={!year}
                                    getPopupContainer={trigger => trigger.parentNode}
                                    onChange={async (makeId, option) => {
                                        if (!makeId) {
                                            await this.setState({
                                                makeId,
                                                modelId: undefined,
                                                model: undefined,
                                                modificationId: undefined,
                                                modification: undefined
                                            });
                                        } else {
                                            await this.setState({ makeId, make: option.children });
                                            this.fetchData();
                                        }
                                    }}
                                    optionFilterProp='children'
                                    showSearch
                                    value={makeId}
                                >
                                    {makes.map(({ id, name, bodyId }) => (
                                        <Option key={id} bodyId={bodyId} value={id}>
                                            {name}
                                        </Option>
                                    ))}
                                </Select>
                            </Col>
                        </Row>

                        <Row className={Styles.row}>
                            <Col span={6}>
                                <div className={Styles.colText}>
                                    <FormattedMessage id='add_client_form.model' />
                                </div>
                            </Col>
                            <Col span={12}>
                                {modelSearch ? (
                                    <Input
                                        onChange={e =>
                                            this.setState({
                                                model: e.target.value,
                                                modelId: undefined,
                                                modificationId: undefined,
                                                modification: undefined
                                            })
                                        }
                                        value={model}
                                    />
                                ) : (
                                    <Select
                                        allowClear
                                        disabled={!makeId}
                                        getPopupContainer={trigger => trigger.parentNode}
                                        onChange={async (modelId, option) => {
                                            if (!modelId) {
                                                await this.setState({
                                                    modelId,
                                                    model: undefined,
                                                    modificationId: undefined,
                                                    modification: undefined
                                                });
                                            } else {
                                                await this.setState({
                                                    modelId,
                                                    model: option.children
                                                });
                                                this.fetchData();
                                            }
                                        }}
                                        optionFilterProp='children'
                                        showSearch
                                        value={modelId}
                                    >
                                        {models.map(({ id, name }) => (
                                            <Option key={id} value={id}>
                                                {name}
                                            </Option>
                                        ))}
                                    </Select>
                                )}
                            </Col>
                        </Row>

                        <Row className={Styles.row}>
                            <Col span={6}>
                                <div className={Styles.colText}>
                                    <FormattedMessage id='add_client_form.modification' />
                                </div>
                            </Col>
                            <Col span={12}>
                                {modelSearch || modificationSearch ? (
                                    <Input
                                        onChange={e =>
                                            this.setState({
                                                modification: e.target.value,
                                                modificationId: undefined
                                            })
                                        }
                                        value={modification}
                                    />
                                ) : (
                                    <Select
                                        allowClear
                                        disabled={!modelId}
                                        getPopupContainer={trigger => trigger.parentNode}
                                        onChange={async modificationId => {
                                            await this.setState({ modificationId });
                                            this.fetchData();
                                        }}
                                        optionFilterProp='children'
                                        showSearch
                                        value={modificationId}
                                    >
                                        {modifications.map(({ id, name }) => (
                                            <Option key={id} value={id}>
                                                {name}
                                            </Option>
                                        ))}
                                    </Select>
                                )}
                            </Col>
                        </Row>

                        <Row className={Styles.row}>
                            <Col span={6}>
                                <div className={Styles.colText}>
                                    <FormattedMessage id='add_client_form.start_of_production' />
                                </div>
                            </Col>
                            <Col span={12}>
                                <div className={Styles.filterDatePicker} style={{ backgroundColor: 'white' }}>
                                    <DatePicker
                                        allowClear
                                        format={dateFormat}
                                        onChange={vehicleYearFrom => {
                                            this.setState({ vehicleYearFrom });
                                        }}
                                        picker='month'
                                        popupStyle={{
                                            maxHeight: 400,
                                            overflow: 'auto',
                                            zIndex: '9999',
                                            minWidth: 220
                                        }}
                                        style={{ width: '100%' }}
                                        value={vehicleYearFrom}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Row className={Styles.row}>
                            <Col span={6}>
                                <div className={Styles.colText}>
                                    <FormattedMessage id='add_client_form.end_of_production' />
                                </div>
                            </Col>
                            <Col span={12}>
                                <div className={Styles.filterDatePicker} style={{ backgroundColor: 'white' }}>
                                    <DatePicker
                                        allowClear
                                        format={dateFormat}
                                        onChange={vehicleYearTo => {
                                            this.setState({ vehicleYearTo });
                                        }}
                                        picker='month'
                                        popupStyle={{
                                            maxHeight: 400,
                                            overflow: 'auto',
                                            zIndex: '9999',
                                            minWidth: 220
                                        }}
                                        style={{ width: '100%' }}
                                        value={vehicleYearTo}
                                    />
                                </div>
                            </Col>
                        </Row>
                        <Row className={Styles.row}>
                            <Col span={6}>
                                <div className={Styles.colText}>
                                    <FormattedMessage id='add_client_form.horse_power' />
                                </div>
                            </Col>
                            <Col span={12}>
                                <InputNumber
                                    min={1}
                                    onChange={hp => this.setState({ hp })}
                                    precision={0}
                                    step={1}
                                    value={hp}
                                />
                            </Col>
                        </Row>
                        <Row className={Styles.row}>
                            <Col span={6}>
                                <div className={Styles.colText}>
                                    <FormattedMessage id='add_client_form.capacity' />
                                </div>
                            </Col>
                            <Col span={12}>
                                <Input
                                    onChange={e =>
                                        this.setState({
                                            capacity: e.target.value
                                        })
                                    }
                                    value={capacity}
                                />
                            </Col>
                        </Row>
                    </Form>
                </Modal>
            </div>
        );
    }
}
