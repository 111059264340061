import { CopyOutlined, EditOutlined, MenuOutlined, PhoneTwoTone, PlusOutlined } from '@ant-design/icons';
import { Form, Input, Popconfirm, Popover, Select } from 'antd';
import { Numeral } from 'commons';
import dayjs from 'dayjs';
import {
    DecoratedDatePicker,
    DecoratedInput,
    DecoratedSelect,
    DecoratedSlider,
    DecoratedTextArea,
    DecoratedTimePicker
} from 'forms/DecoratedFields';
import _ from 'lodash';
import { AddClientModal } from 'modals';
import React, { Component } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import book from 'routes/book';
import { getCurrency, isForbidden, permissions } from 'utils';
import { grants, isGrantAccessed } from 'utils/grants';
import { v4 } from 'uuid';
import { ClientsSearchTable } from '../../OrderForm/OrderFormTables';
import Styles from './styles.m.css';

const FormItem = Form.Item;
const { Option } = Select;

const formItemLayout = {
    labelCol: {
        xl: { span: 24 },
        xxl: { span: 4 }
    },
    wrapperCol: {
        xl: { span: 24 },
        xxl: { span: 20 }
    },
    colon: false
};

const getDisabledHours = (startTime = 0, endTime = 23) => {
    const availableHours = [];
    for (let i = Number(startTime); i <= Number(endTime); i++) {
        availableHours.push(i);
    }

    return _.difference(
        Array(24)
            .fill(1)
            .map((value, index) => index),
        availableHours
    );
};

@injectIntl
export class OrderMobileFormFields extends Component {
    constructor(props) {
        super(props);

        this.state = {
            workshopModalVisible: false,
            stockModalVisible: false
        };
    }

    bodyUpdateIsForbidden() {
        return isForbidden(this.props.user, permissions.ACCESS_ORDER_BODY);
    }

    _renderClientSearch = () => {
        const { getFieldDecorator } = this.props.form;
        const { user, fields, errors } = this.props;
        const { CREATE_EDIT_DELETE_CLIENTS } = permissions;

        const disabledClientSearch =
            (!_.get(this.props, 'order.status') || _.get(this.props, 'order.status') !== 'reserve') &&
            _.get(this.props, 'order.clientId');

        return !disabledClientSearch ? (
            <div className={Styles.client}>
                <DecoratedInput
                    className={Styles.clientSearchField}
                    colon={false}
                    defaultGetValueProps
                    disabled={Boolean(disabledClientSearch)}
                    errors={errors}
                    field='searchClientQuery'
                    fieldValue={_.get(fields, 'searchClientQuery')}
                    formItem
                    getFieldDecorator={getFieldDecorator}
                    label={this.props.intl.formatMessage({
                        id: 'add_order_form.search_client'
                    })}
                    placeholder={this.props.intl.formatMessage({
                        id: 'add_order_form.search_client.placeholder'
                    })}
                />
                {!isForbidden(user, CREATE_EDIT_DELETE_CLIENTS) ? (
                    <PlusOutlined className={Styles.addClientIcon} onClick={() => this.props.setAddClientModal()} />
                ) : null}
            </div>
        ) : null;
    };

    _renderClientSearchTable = () => {
        const {
            searchClientsResult: { searching: clientsSearching, clients },
            setClientSelection,
            form,
            isMobile
        } = this.props;

        const formFieldsValues = form.getFieldsValue();
        const searchClientQuery = _.get(formFieldsValues, 'searchClientQuery');

        return (
            <ClientsSearchTable
                clients={clients}
                clientsSearching={clientsSearching}
                isMobile={isMobile}
                setClientSelection={async cl => {
                    await form.setFieldsValue({ searchClientQuery: undefined });
                    await setClientSelection(cl);
                }}
                visible={searchClientQuery}
            />
        );
    };

    _renderClientColumn = () => {
        const {
            selectedClient,
            fetchedOrder,
            fields,
            errors,
            onStatusChange,
            orderStatus,
            intl: { formatMessage }
        } = this.props;
        const { getFieldDecorator } = this.props.form;
        const hasClient = _.get(fetchedOrder, 'order.clientPhone');

        return (
            <div className={Styles.bodyColumn}>
                <div className={Styles.bodyColumnContent}>
                    <div className={Styles.contentWrapper}>
                        <div className={Styles.comboFieldWrapper}>
                            <FormattedMessage id='add_order_form.client' />
                            <div className={Styles.comboField}>
                                {selectedClient.name || selectedClient.surname
                                    ? `${selectedClient.surname ? `${selectedClient.surname} ` : ''}${
                                          selectedClient.name
                                      }`
                                    : void 0}

                                {hasClient && (
                                    <Popover
                                        content={
                                            <div className={Styles.actionIconsWrap}>
                                                {onStatusChange ? (
                                                    <Popconfirm
                                                        onConfirm={() => {
                                                            onStatusChange(
                                                                orderStatus,
                                                                `${book.client}/${selectedClient.clientId}`
                                                            );
                                                        }}
                                                        title={<FormattedMessage id='save_order_changes' />}
                                                    >
                                                        <Link to={`${book.client}/${selectedClient.clientId}`}>
                                                            <EditOutlined className={Styles.icon} />
                                                        </Link>
                                                    </Popconfirm>
                                                ) : (
                                                    <Link to={`${book.client}/${selectedClient.clientId}`}>
                                                        <EditOutlined
                                                            className={Styles.icon}
                                                            title={formatMessage({ id: 'edit' })}
                                                        />
                                                    </Link>
                                                )}
                                                <CopyToClipboard text={hasClient}>
                                                    <CopyOutlined
                                                        className={Styles.icon}
                                                        title={formatMessage({ id: 'copy' })}
                                                    />
                                                </CopyToClipboard>
                                                <a
                                                    className={Styles.callLink}
                                                    href={`tel:${_.get(fields, 'clientPhone')}`}
                                                >
                                                    <PhoneTwoTone
                                                        className={Styles.icon}
                                                        title={formatMessage({ id: 'call' })}
                                                    />
                                                </a>
                                            </div>
                                        }
                                        placement='right'
                                        trigger='click'
                                    >
                                        <MenuOutlined className={Styles.actionIconsPopover} />
                                    </Popover>
                                )}
                            </div>
                        </div>
                        <DecoratedSelect
                            className={`${Styles.clientCol} ${Styles.comboFieldSelect}`}
                            colon={false}
                            defaultGetValueProps
                            disabled={this.bodyUpdateIsForbidden()}
                            dropdownStyle={this._clientPhoneBorderStyle}
                            errors={errors}
                            field='clientPhone'
                            fieldValue={_.get(fields, 'clientPhone')}
                            formItem
                            getFieldDecorator={getFieldDecorator}
                            initialValue={
                                _.get(fetchedOrder, 'order.clientPhone') ||
                                (this.bodyUpdateIsForbidden() ? void 0 : _.get(selectedClient, 'phones[0]'))
                            }
                            rules={this.requiredFieldRules}
                        >
                            {_.get(selectedClient, 'phones', [])
                                .filter(Boolean)
                                .map(phone => (
                                    <Option key={v4()} value={phone}>
                                        {phone}
                                    </Option>
                                ))}
                        </DecoratedSelect>
                    </div>
                </div>
            </div>
        );
    };

    _renderTotalBlock = () => {
        const { totalSum, totalSumWithTax, isTaxPayer, cashSum } = this.props;

        const remainPrice = isTaxPayer
            ? Math.round((totalSumWithTax - cashSum) * 100) / 100
            : Math.round((totalSum - cashSum) * 100) / 100;

        const mask = '0,0.00';

        return (
            <div className={Styles.totalBlock}>
                <div className={Styles.sum}>
                    <span className={Styles.sumWrapper}>
                        <FormattedMessage id='sum' />
                        <Numeral className={Styles.sumNumeral} currency={getCurrency()} mask={mask} nullText='0'>
                            {totalSum}
                        </Numeral>
                    </span>
                    {isTaxPayer && (
                        <span className={Styles.sumWrapper}>
                            <FormattedMessage id='with' /> <FormattedMessage id='VAT' />
                            <Numeral className={Styles.sumNumeral} currency={getCurrency()} mask={mask} nullText='0'>
                                {totalSumWithTax}
                            </Numeral>
                        </span>
                    )}
                    <span className={Styles.sumWrapper}>
                        <FormattedMessage id='paid' />
                        <Numeral className={Styles.sumNumeral} currency={getCurrency()} mask={mask} nullText='0'>
                            {cashSum}
                        </Numeral>
                    </span>
                </div>
                <div className={Styles.totalSumWrap}>
                    <FormattedMessage id='remain' />
                    <Numeral className={Styles.totalSum} currency={getCurrency()} mask={mask} nullText='0'>
                        {remainPrice || 0}
                    </Numeral>
                </div>
            </div>
        );
    };

    _renderVehicleColumn = () => {
        const { selectedClient, fetchedOrder, fields, onStatusChange, orderStatus, errors, vehicles } = this.props;
        const { getFieldDecorator } = this.props.form;
        const selectedVehicleId = fields.clientVehicle || _.get(fetchedOrder, 'order.clientVehicleId');
        const clientVehicleRadius = Math.round(_.get(fetchedOrder, 'order.clientVehicleRadius', 0));

        const selectedVehicle = selectedClient && selectedVehicleId && _.find(vehicles, { id: selectedVehicleId });

        return (
            <div className={Styles.bodyColumn}>
                <div className={Styles.bodyColumnContent}>
                    <div className={Styles.contentWrapper}>
                        <div className={Styles.comboFieldWrapper}>
                            <FormattedMessage id='add_order_form.car' />
                            <div className={Styles.comboField__vehicle}>
                                {_.get(selectedVehicle, 'number') && (
                                    <span>
                                        <FormattedMessage id='add_client_form.number' />
                                        {_.get(selectedVehicle, 'number')}
                                    </span>
                                )}
                                {_.get(selectedVehicle, 'vin') && (
                                    <span style={{ paddingLeft: 8 }}>
                                        <FormattedMessage id='add_client_form.vin' />
                                        {_.get(selectedVehicle, 'vin')}
                                    </span>
                                )}
                                {selectedVehicle && (
                                    <Popover
                                        content={
                                            <div className={Styles.actionIconsWrap}>
                                                {onStatusChange ? (
                                                    <Popconfirm
                                                        onConfirm={() => {
                                                            onStatusChange(
                                                                orderStatus,
                                                                `${book.client}/${selectedClient.clientId}`
                                                            );
                                                        }}
                                                        title={<FormattedMessage id='save_order_changes' />}
                                                    >
                                                        <Link to={`${book.client}/${selectedClient.clientId}`}>
                                                            <EditOutlined className={Styles.icon} />
                                                        </Link>
                                                    </Popconfirm>
                                                ) : (
                                                    <Link
                                                        to={`${book.vehicle}/${_.get(
                                                            fetchedOrder,
                                                            'order.clientVehicleId'
                                                        )}`}
                                                    >
                                                        <EditOutlined className={Styles.icon} />
                                                    </Link>
                                                )}
                                                <CopyToClipboard
                                                    text={`${selectedVehicle.make} ${selectedVehicle.model}`}
                                                >
                                                    <CopyOutlined className={Styles.icon} />
                                                </CopyToClipboard>
                                            </div>
                                        }
                                        placement='right'
                                        trigger='click'
                                    >
                                        <MenuOutlined className={Styles.actionIconsPopover} />
                                    </Popover>
                                )}
                            </div>
                        </div>
                        <DecoratedSelect
                            className={Styles.comboFieldSelect}
                            colon={false}
                            disabled={this.bodyUpdateIsForbidden()}
                            errors={errors}
                            field='clientVehicle'
                            formItem
                            getFieldDecorator={getFieldDecorator}
                            initialValue={
                                _.get(fetchedOrder, 'order.clientVehicleId') ||
                                (this.bodyUpdateIsForbidden() ? void 0 : _.get(selectedClient, 'vehicles[0].id'))
                            }
                            onSearch={input => this.props.handleSearchClientVehicles(input)}
                            {...formItemLayout}
                        >
                            {vehicles.map(vehicle => (
                                <Option key={vehicle.id} value={vehicle.id}>
                                    {`${vehicle.make} ${vehicle.model}`}
                                </Option>
                            ))}
                        </DecoratedSelect>
                    </div>
                </div>
            </div>
        );
    };

    _saveFormRef = formRef => {
        this.formRef = formRef;
    };

    render() {
        const {
            selectedClient,
            stations,
            onStatusChange,
            order: { status },
            schedule,
            fetchedOrder,
            form,
            modal,
            resetModal,
            user,
            addClientFormData,
            vehicles,
            isRetailOrder
        } = this.props;
        const { getFieldDecorator, getFieldsValue } = this.props.form;
        const { formatMessage } = this.props.intl;

        const formFieldsValues = form.getFieldsValue();
        const searchClientQuery = _.get(formFieldsValues, 'searchClientQuery');

        const vehicle = vehicles.find(vehicle => vehicle.id == this.props.order.clientVehicleId) || undefined;

        const dayNumber = dayjs(_.get(this.props, 'stationLoads[0].beginDate')).day();
        let disabledHours = [];
        if (schedule && dayNumber) {
            let index;
            switch (dayNumber) {
                case 6:
                    index = 1;
                    break;
                case 7:
                    index = 2;
                    break;
                default:
                    index = 0;
            }

            disabledHours = getDisabledHours(
                schedule[index] && schedule[index].beginTime ? schedule[index].beginTime.split(/[.:]/)[0] : 9,
                schedule[index] && schedule[index].endTime ? schedule[index].endTime.split(/[.:]/)[0] : 20
            );
        }

        const isDurationDisabled = _.every(
            getFieldsValue(['stationLoads[0].beginDate', 'stationLoads[0].beginTime', 'stationLoads[0].station'])
        );

        const totalBlock = this._renderTotalBlock();
        const clientSearch = this._renderClientSearch();
        const clientsSearchTable = this._renderClientSearchTable();
        const clientColumn = this._renderClientColumn();
        const vehicleColumn = this._renderVehicleColumn();

        return (
            <div>
                {clientSearch}
                {clientsSearchTable}
                {clientColumn}
                {vehicleColumn}
                <div style={{ display: 'none' }}>
                    <DecoratedInput
                        field='stationLoads[0].status'
                        formItem
                        getFieldDecorator={getFieldDecorator}
                        hiddeninput='hiddeninput'
                        initialValue='TO_DO'
                    />

                    <FormItem label={<FormattedMessage id='add_order_form.client' />} {...formItemLayout}>
                        <Input
                            disabled
                            placeholder={formatMessage({
                                id: 'add_order_form.select_name',
                                defaultMessage: 'Select client'
                            })}
                            style={{ color: 'var(--text)' }}
                            value={
                                selectedClient.name || selectedClient.surname
                                    ? `${selectedClient.surname ? `${selectedClient.surname} ` : ''}${
                                          selectedClient.name
                                      }`
                                    : void 0
                            }
                        />
                    </FormItem>
                    <DecoratedSelect
                        className={Styles.clientCol}
                        colon={false}
                        field='clientPhone'
                        formItem
                        formItemLayout={formItemLayout}
                        getFieldDecorator={getFieldDecorator}
                        initialValue={
                            _.get(fetchedOrder, 'order.clientPhone') ||
                            (this.bodyUpdateIsForbidden() ? void 0 : _.get(selectedClient, 'phones[0]'))
                        }
                        label={<FormattedMessage id='add_order_form.phone' />}
                        placeholder={formatMessage({
                            id: 'add_order_form.select_client_phone'
                        })}
                        rules={[
                            {
                                required: true,
                                message: formatMessage({
                                    id: 'required_field'
                                })
                            }
                        ]}
                    >
                        {_.get(selectedClient, 'phones', [])
                            .filter(Boolean)
                            .map(phone => (
                                <Option key={v4()} value={phone}>
                                    {phone}
                                </Option>
                            ))}
                    </DecoratedSelect>
                </div>
                <hr />
                {totalBlock}
                <hr />
                <div style={{ fontSize: '18px', marginBottom: '10px' }}>
                    <FormattedMessage id='add_order_form.appointment_details' />
                </div>
                {!isRetailOrder && (
                    <DecoratedSelect
                        className={Styles.datePanelItem}
                        colon={false}
                        field='stationLoads[0].station'
                        formItem
                        getFieldDecorator={getFieldDecorator}
                        initialValue={this.props.order.stationNum}
                        label={<FormattedMessage id='add_order_form.station' />}
                        optionLabel='name'
                        options={stations}
                        optionValue='num'
                        placeholder={<FormattedMessage id='add_order_form.select_station' />}
                    />
                )}

                {!isRetailOrder ? (
                    <DecoratedDatePicker
                        allowClear={false}
                        className={Styles.datePanelItem}
                        field='stationLoads[0].beginDate'
                        formatMessage={formatMessage}
                        formItem
                        getFieldDecorator={getFieldDecorator}
                        initialValue={dayjs(this.props.order.beginDatetime).toISOString()}
                        label={<FormattedMessage id='date' />}
                        {...formItemLayout}
                        rules={[
                            {
                                required: true,
                                message: formatMessage({
                                    id: 'required_field'
                                })
                            }
                        ]}
                    />
                ) : (
                    <DecoratedDatePicker
                        allowClear={false}
                        className={Styles.datePanelItem}
                        field='deliveryDatetime'
                        formatMessage={formatMessage}
                        formItem
                        getFieldDecorator={getFieldDecorator}
                        initialValue={
                            this.props.order.deliveryDatetime
                                ? dayjs(this.props.order.deliveryDatetime).toISOString()
                                : dayjs().toISOString()
                        }
                        label={<FormattedMessage id='add_order_form.delivery_date' />}
                        {...formItemLayout}
                        rules={[
                            {
                                required: true,
                                message: formatMessage({
                                    id: 'required_field'
                                })
                            }
                        ]}
                    />
                )}
                {!isRetailOrder && (
                    <DecoratedTimePicker
                        allowClear={false}
                        className={Styles.datePanelItem}
                        disabledHours={() => disabledHours}
                        field='stationLoads[0].beginTime'
                        formatMessage={formatMessage}
                        formItem
                        getFieldDecorator={getFieldDecorator}
                        hideDisabledOptions
                        initialValue={dayjs(this.props.order.beginDatetime).toISOString()}
                        inputReadOnly
                        label={<FormattedMessage id='time' />}
                        minuteStep={30}
                        rules={[
                            {
                                required: true,
                                message: formatMessage({
                                    id: 'required_field'
                                })
                            }
                        ]}
                    />
                )}
                {!isRetailOrder && (
                    <DecoratedSlider
                        disabled={!isDurationDisabled}
                        field='stationLoads[0].duration'
                        formItem
                        getFieldDecorator={getFieldDecorator}
                        initialValue={this.props.order.duration}
                        label={<FormattedMessage id='add_order_form.duration' />}
                        max={8}
                        min={0}
                        step={0.5}
                        {...formItemLayout}
                    />
                )}
                <DecoratedSelect
                    className={Styles.datePanelItem}
                    colon={false}
                    disabled={!isGrantAccessed(user, grants.OPERATIONS_ORDER_DOCUMENT_ORDER_HEADER_RESPONSIBLE)}
                    field='manager'
                    formItem
                    getFieldDecorator={getFieldDecorator}
                    initialValue={this.props.order.managerId}
                    label={<FormattedMessage id='add_order_form.manager' />}
                    placeholder={formatMessage({
                        id: 'add_order_form.select_manager'
                    })}
                    rules={[
                        {
                            required: true,
                            message: formatMessage({
                                id: 'required_field'
                            })
                        }
                    ]}
                    {...formItemLayout}
                >
                    {this.props.managers.map(manager => (
                        <Option key={v4()} disabled={manager.disabled} value={manager.id}>
                            {`${manager.managerSurname} ${manager.managerName}`}
                        </Option>
                    ))}
                </DecoratedSelect>
                {!isRetailOrder && (
                    <DecoratedSelect
                        className={Styles.datePanelItem}
                        defaultGetValueProps
                        field='employee'
                        formItem
                        getFieldDecorator={getFieldDecorator}
                        initialValue={
                            _.get(fetchedOrder, 'order.employeeId') ||
                            (location.state ? location.state.employeeId : undefined)
                        }
                        label={<FormattedMessage id='order_form_table.master' />}
                        placeholder={formatMessage({
                            id: 'order_form_table.select_master'
                        })}
                        {...formItemLayout}
                    >
                        {_.get(this.props, 'employees', []).map(employee => {
                            if (!employee.disabled) {
                                return (
                                    <Option
                                        key={`employee-${employee.id}`}
                                        disabled={employee.disabled}
                                        value={employee.id}
                                    >
                                        {`${employee.surname} ${employee.name}`}
                                    </Option>
                                );
                            }
                        })}
                    </DecoratedSelect>
                )}
                {!isRetailOrder && (
                    <DecoratedTextArea
                        autoSize={{ minRows: 2, maxRows: 6 }}
                        field='comment'
                        formItem
                        getFieldDecorator={getFieldDecorator}
                        initialValue={this.props.order.comment}
                        label={<FormattedMessage id='add_order_form.client_comments' />}
                        placeholder={formatMessage({
                            id: 'add_order_form.client_comments',
                            defaultMessage: 'Client_comments'
                        })}
                        rules={[
                            {
                                max: 2000,
                                message: formatMessage({
                                    id: 'field_should_be_below_2000_chars'
                                })
                            }
                        ]}
                    />
                )}
                {!isRetailOrder && (
                    <DecoratedTextArea
                        autoSize={{ minRows: 2, maxRows: 6 }}
                        field='systemComment'
                        formItem
                        getFieldDecorator={getFieldDecorator}
                        initialValue={this.props.order.systemComment}
                        label={<FormattedMessage id='add_order_form.system_comments' />}
                        placeholder={formatMessage({
                            id: 'add_order_form.system_comments',
                            defaultMessage: 'System_comments'
                        })}
                        rules={[
                            {
                                max: 2000,
                                message: formatMessage({
                                    id: 'field_should_be_below_2000_chars'
                                })
                            }
                        ]}
                    />
                )}
                {!isRetailOrder && (
                    <DecoratedTextArea
                        autoSize={{ minRows: 2, maxRows: 6 }}
                        field='vehicleCondition'
                        formItem
                        getFieldDecorator={getFieldDecorator}
                        initialValue={this.props.order.vehicleCondition}
                        label={<FormattedMessage id='add_order_form.vehicle_condition' />}
                        placeholder={formatMessage({
                            id: 'add_order_form.vehicle_condition',
                            defaultMessage: 'VehicleCondition_comments'
                        })}
                        rules={[
                            {
                                max: 2000,
                                message: formatMessage({
                                    id: 'field_should_be_below_2000_chars'
                                })
                            }
                        ]}
                    />
                )}
                {!isRetailOrder && (
                    <DecoratedTextArea
                        autoSize={{ minRows: 2, maxRows: 6 }}
                        field='recommendation'
                        formItem
                        getFieldDecorator={getFieldDecorator}
                        initialValue={this.props.order.recommendation}
                        label={<FormattedMessage id='add_order_form.service_recommendations' />}
                        placeholder={formatMessage({
                            id: 'add_order_form.service_recommendations',
                            defaultMessage: 'Recommendation_comments'
                        })}
                        rules={[
                            {
                                max: 2000,
                                message: formatMessage({
                                    id: 'field_should_be_below_2000_chars'
                                })
                            }
                        ]}
                    />
                )}
                <AddClientModal
                    addClientFormData={addClientFormData}
                    resetModal={resetModal}
                    searchQuery={searchClientQuery}
                    visible={modal}
                    wrappedComponentRef={this._saveFormRef}
                />
            </div>
        );
    }
}
