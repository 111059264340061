import { Modal } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { fetchAPI } from 'utils';
import RelatedEntriesTable from './RelatedEntriesTable';

const RelatedEntriesModal = ({ selectedRelatedEntry = {}, open, onClose }) => {
    const [filteredEntries, setFilteredEntries] = useState([]);
    const [pagination, setPagination] = useState({
        pageSize: 10,
        page: 1
    });
    const [totalEntries, setTotalEntries] = useState(0);

    const handlePaginationChange = useCallback(filterObj => {
        setPagination(prev => ({ ...prev, ...filterObj }));
    }, []);

    const onCloseModal = () => {
        setFilteredEntries([]);
        setPagination({ page: 1, pageSize: 10 });
        onClose();
    };

    const fetchRelatedEntries = useCallback(
        async (pagination = { page: 1 }) => {
            const res = await fetchAPI(
                'GET',
                '/general_ledger/entries',
                {
                    operationId: selectedRelatedEntry.operationId,
                    ...pagination
                },
                null,
                {
                    handleErrorInternally: true
                }
            );

            setFilteredEntries(res.list);
            setTotalEntries(res.count);
        },
        [selectedRelatedEntry.operationId]
    );

    const filteredEntriesEmpty = useMemo(() => !filteredEntries.length, [filteredEntries.length]);

    useEffect(() => {
        if (open) {
            fetchRelatedEntries();
        }
    }, [fetchRelatedEntries, open]);

    useEffect(() => {
        if (open && !filteredEntriesEmpty) {
            fetchRelatedEntries(pagination);
        }
    }, [fetchRelatedEntries, open, pagination]);

    return (
        <Modal
            destroyOnClose
            onCancel={onCloseModal}
            onOk={onCloseModal}
            open={open}
            title={<FormattedMessage id='entry_table.related_entries' />}
            width='90%'
        >
            <RelatedEntriesTable
                dataSource={filteredEntries}
                handlePaginationChange={handlePaginationChange}
                pagination={pagination}
                totalEntries={totalEntries}
            />
        </Modal>
    );
};

export default RelatedEntriesModal;
