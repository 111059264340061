import { Button, Modal, Select } from 'antd';
import { selectBrands } from 'core/brands/duck';
import { fetchSuppliers } from 'core/suppliers/duck';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';

const { Option } = Select;

const mapStateToProps = state => ({
    brands: selectBrands(state),
    user: state.auth,
    warehouses: state.warehouses.warehouses,
    suppliers: state.suppliers.suppliers,
    clients: state.clients.clients
});

const mapDispatchToProps = {
    fetchSuppliers
};

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export class ActionModal extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { visible, hideModal, list, visibleModal } = this.props;

        return (
            <Modal
                footer={null}
                onCancel={hideModal}
                title={<FormattedMessage id='order_form_table_intake_tab.add_problem' />}
                visible={visible}
                width='15%'
            >
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between'
                    }}
                >
                    <div>
                        <Button
                            onClick={() => {
                                visibleModal();
                            }}
                            type='primary'
                        >
                            <FormattedMessage id='edit' />
                        </Button>
                    </div>
                    <div>
                        <Button>
                            <FormattedMessage id='delete' />
                        </Button>
                    </div>
                </div>
            </Modal>
        );
    }
}
