import { Button, Checkbox, Modal, Space, Table } from 'antd';
import React, { useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import Styles from './styles.m.css';

const inputable = [6, 10, 17]; // ids of childs that have input

const OrderCheckModal = ({ orderChecks = [], open, onClose, onOk, blocked, intl, isMobile }) => {
    const [loading, setLoading] = useState(false);

    const expandedRowRender = parentRecord => {
        const columns = [
            {
                key: 'name',
                render: record => {
                    let color;
                    if (record.status == 'FAILED') {
                        color = 'red';
                    } else if (record.status == 'SUCCESS') {
                        color = 'green';
                    }

                    return <div style={{ color }}>{record.name}</div>;
                }
            },
            {
                dataIndex: 'enable',
                key: 'enable',
                width: '110px',
                align: 'center',
                render: (text, record) => <Checkbox checked={record.enable} disabled />
            },
            {
                dataIndex: 'block',
                key: 'block',
                width: '110px',
                align: 'center',
                render: (text, record) => <Checkbox checked={record.block} disabled />
            },
            {
                dataIndex: 'value',
                key: 'value',
                width: '300px',
                align: 'center',
                render: (text, record) => {
                    let color;
                    if (record.status == 'FAILED') {
                        color = 'red';
                    } else if (record.status == 'SUCCESS') {
                        color = 'green';
                    }

                    const comments = record.comments ? record.comments.slice(0, 3) : [];
                    const res = [];
                    comments.forEach(el => {
                        const znak = el.actual > el.expected ? '>' : el.actual < el.expected ? '<' : '=';
                        res.push(`${el.row ? `Ряд. ${el.row} ` : ''} ${el.code ? `(${el.code})` : ''}`);
                    });

                    return <div style={{ color }}>{res.join(', ')}</div>;
                }
            }
        ];

        return (
            <Table
                className={Styles.innerTable}
                columns={columns}
                dataSource={parentRecord.childs}
                pagination={false}
                rowKey={record => record.name}
                showHeader={false}
            />
        );
    };

    const columns = [
        {
            dataIndex: 'name',
            key: 'name',
            className: Styles.bold
        },
        {
            title: <FormattedMessage id='to_check'/>,
            dataIndex: 'check',
            key: 'check',
            align: 'center',
            width: '110px'
        },
        {
            title: <FormattedMessage id='repair_map_setting.block' />,
            dataIndex: 'block',
            key: 'block',
            align: 'center',
            width: '110px'
        },
        {
            title: <FormattedMessage id='info_modal.value' />,
            dataIndex: 'value',
            key: 'value',
            align: 'center',
            width: '300px'
        }
    ];

    return (
        <Modal
            okButtonProps={{
                disabled: blocked
            }}
            onCancel={onClose}
            onOk={() => {
                onOk();
                onClose();
            }}
            open={open}
            title={intl.formatMessage({
                id: 'order-page.status_check'
            })}
            width={isMobile ? '95%' : '60%'}
        >
            <Space className={Styles.buttonContainerStyles}>
                <Button onClick={onClose}>Скасувати</Button>
                <Button
                    disabled={blocked}
                    onClick={() => {
                        onOk();
                        onClose();
                    }}
                    type='primary'
                >
                    Гаразд
                </Button>
            </Space>
            <Table
                columns={columns}
                dataSource={orderChecks}
                expandable={{ expandedRowRender, defaultExpandAllRows: true, expandIcon: () => null }}
                loading={loading}
                pagination={false}
                rowKey={record => record.id}
                scroll={{ x: 100 }}
                size='small'
            />
        </Modal>
    );
};

export default injectIntl(OrderCheckModal);
