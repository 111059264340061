import { ClearOutlined } from '@ant-design/icons';
import { Button, Input, Select, Tooltip } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { injectIntl } from 'react-intl';
import { fetchAPI } from 'utils';
import LogsMainTable from './LogsMainTable';

import Styles from './styles.m.css';

const documentKeys = [
    'businessName',
    'requisiteName',
    'warehouseName',
    'recipientBusinessName',
    'recipientRequisiteName',
    'recipientWarehouseName',
    'businessSupplierRequisiteName',
    'counterpartBusinessSupplierName',
    'counterpartClientName',
    'counterpartEmployeeName',
    'status',
    'supplierDocNumber',
    'payUntilDatetime',
    'doneDatetime',
    'sum',
    'sellingSum',
    'sumTax',
    'sellingSumTax'
];
const productKeys = [
    'addToAddress',
    'productMeasureUnit',
    'uktz',
    'orderNum',
    'supplierPartNumber',
    'quantity',
    'stockPrice',
    'sellingPrice',
    'stockPriceSum',
    'sellingPriceSum',
    'productStatus',
    'brand',
    'code',
    'productName'
];
const serviceKeys = ['serviceName', 'serviceMeasureUnit', 'orderId', 'count', 'price', 'serviceSum', 'analytics'];

const TasksTab = ({ documentId, activeKey, employees, isServiceType, intl: { formatMessage } }) => {
    const [logs, setLogs] = useState([]);
    const [count, setCount] = useState(0);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [filters, setFilters] = useState({});

    const employeesOptions = useMemo(() => {
        return employees
            .map(({ name, surname, managerId }) => ({
                value: managerId,
                label: `${surname} ${name}`
            }))
            .filter(({ value }) => value);
    }, [employees]);

    const filterByKeyOptions = useMemo(() => {
        return documentKeys
            .concat(isServiceType ? serviceKeys : productKeys)
            .map(key => ({ value: key, label: formatMessage({ id: `logs.${key}` }) }));
    }, [formatMessage, isServiceType]);

    useEffect(() => {
        const fetchLogs = async () => {
            const { auditLogs, count } = await fetchAPI(
                'GET',
                '/store_doc/audit/log',
                { documentId, ...filters, page, pageSize },
                null,
                {
                    handleErrorInternally: true
                }
            );
            setLogs(auditLogs);
            setCount(count);
        };

        if (activeKey === 'logs') fetchLogs();
    }, [documentId, activeKey, filters, page, pageSize]);

    const changeFilters = useCallback(changeObj => {
        setFilters(prev => ({ ...prev, ...changeObj }));
    }, []);

    const clearFilters = useCallback(() => setFilters({}), []);

    return (
        <div>
            <div className={Styles.inputWrap}>
                <Tooltip title={formatMessage({ id: 'clear_filters' })}>
                    <Button icon={<ClearOutlined />} onClick={clearFilters} style={{ width: 30 }} type='secondary' />
                </Tooltip>
                <Select
                    allowClear
                    onClear={() => changeFilters({ filterKey: null })}
                    onSelect={value => changeFilters({ filterKey: value })}
                    options={filterByKeyOptions}
                    placeholder={formatMessage({ id: 'field' })}
                    value={filters.filterKey}
                />
                <Input
                    allowClear
                    onChange={e => changeFilters({ search: e.target.value })}
                    placeholder={formatMessage({ id: 'search' })}
                    value={filters.search}
                />
                <Select
                    allowClear
                    onClear={() => changeFilters({ type: null })}
                    onSelect={value => changeFilters({ type: value })}
                    options={[
                        { value: 'INSERT', label: formatMessage({ id: 'logs-table.v2.INSERT' }) },
                        { value: 'UPDATE', label: formatMessage({ id: 'logs-table.v2.UPDATE' }) },
                        { value: 'DELETE', label: formatMessage({ id: 'logs-table.v2.DELETE' }) }
                    ]}
                    placeholder={formatMessage({ id: 'locations.action' })}
                    value={filters.type}
                />
                <Select
                    allowClear
                    onClear={() => changeFilters({ responsibleId: null })}
                    onSelect={value => changeFilters({ responsibleId: value })}
                    options={employeesOptions}
                    placeholder={formatMessage({ id: 'responsible' })}
                    value={filters.responsibleId}
                />
                <Select
                    allowClear
                    onClear={() => changeFilters({ dataKey: null })}
                    onSelect={value => changeFilters({ dataKey: value })}
                    options={[
                        { value: 'DOCUMENT', label: formatMessage({ id: 'types.DOCUMENT' }) },
                        { value: 'PRODUCTS', label: formatMessage({ id: 'types.PRODUCTS' }) },
                        { value: 'SERVICES', label: formatMessage({ id: 'types.SERVICES' }) }
                    ]}
                    placeholder={formatMessage({ id: 'storage.type' })}
                    value={filters.dataKey}
                />
            </div>
            <LogsMainTable
                changeFilters={changeFilters}
                count={count}
                employees={employees}
                filters={filters}
                logs={logs}
                page={page}
                setPage={setPage}
                setPageSize={setPageSize}
            />
        </div>
    );
};

export default injectIntl(TasksTab);
