import { DeleteOutlined, EditOutlined, ShrinkOutlined, VideoCameraAddOutlined } from '@ant-design/icons';
import { Button, Image, Input, Modal, Pagination, Popconfirm, Tooltip, Upload, notification } from 'antd';
import { DateRangePicker } from 'components';
import dayjs from 'dayjs';
import _, { get } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { fetchAPI, getStorageToken } from 'utils';
import Styles from './styles.m.css';

const { Dragger } = Upload;

const PreviewMediaFilesModal = ({
    user,
    intl: { formatMessage },
    open,
    hideModal,
    mediaFilesRow,
    prdId,
    isMobile,
    diagnData,
    orderNum,
    orderId,
    zetInd
}) => {
    const [fileList, setFileList] = useState([]);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(25);
    const [displayType, setDisplayType] = useState('grid');
    const [query, setQuery] = useState('');
    const [startDate, setStartDate] = useState(dayjs().subtract(7, 'days'));
    const [endDate, setEndDate] = useState(dayjs());
    const [sortBy, setSortBy] = useState('date');
    const [stats, setStats] = useState('date');
    const [edit, setEdit] = useState();
    const [newName, setName] = useState();
    const [selectedPhotos, addSelectedPhotos] = useState([]);
    const [openEditName, setOpenEditName] = useState(false);
    const [images, setImages] = useState([]);
    const [imagesSM, setImagesSM] = useState([]);
    const [count, setCount] = useState(0);
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [loading, setLoading] = useState(false);
    const [hover, setHover] = useState({
        show: false,
        imgId: undefined
    });

    const formatToMilliseconds = date => {
        return date.valueOf();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps

    const onCancel = async () => {
        setImages([]);
        setFileList([]);
        setQuery(undefined);

        await hideModal();
    };

    const bussinessStats = useMemo(
        () => async () => {
            const res = await fetchAPI('GET', '/api/business/stats', null, null, {
                handleErrorInternally: true,
                url: __GALLARY_IMAGES_URL__,
                noAuth: true,
                headers: {
                    Authorization: getStorageToken()
                }
            });

            setStats(res);
        },
        []
    );

    const formatFilenameSM = filename => {
        const [base, extension] = filename.split('.');

        const chunks = [];
        for (let i = 0; i < base.length; i += 2) {
            chunks.push(base.slice(i, i + 2));
        }

        const formattedBase = chunks.slice(0, 4).join('/');

        const formattedFilename = `${formattedBase}/${base}_sm.${extension}`;

        return formattedFilename;
    };

    const formatFilename = filename => {
        const [base, extension] = filename.split('.');

        const chunks = [];
        for (let i = 0; i < base.length; i += 2) {
            chunks.push(base.slice(i, i + 2));
        }

        const formattedBase = chunks.slice(0, 4).join('/');

        const formattedFilename = `${formattedBase}/${base}.${extension}`;

        return formattedFilename;
    };

    const fetchImage = async src => {
        const res = await fetchAPI('GET', src, null, null, {
            handleErrorInternally: true,
            url: __GALLARY_IMAGES_URL__,
            noAuth: true,
            rawResponse: true,
            headers: {
                Authorization: getStorageToken(),
                'Content-Type': 'application/json'
            }
        });

        const blob = await res.blob();

        setImages({
            url: URL.createObjectURL(blob),
            blob
        });

        return {
            blob,
            url: URL.createObjectURL(blob) // Create a temporary URL for the blob
        };
    };

    const fetchImagesSM = useMemo(
        () => async () => {
            const results = await Promise.all(
                fileList.map((obj, index) => {
                    const formattedHash = formatFilenameSM(obj.path);

                    return fetchImage(`api/file/${formattedHash}`).then(res => ({
                        url: res.url,
                        blob: res.blob,
                        index
                    }));
                })
            );

            setImagesSM(results);
        },
        [fileList]
    );

    const fetchFileList = useMemo(
        () => async (query, stDate, endDate) => {
            const formattedStartDate = formatToMilliseconds(stDate || dayjs().subtract(7, 'days'));
            const formattedEndDate = formatToMilliseconds(endDate || dayjs());

            if (prdId || (diagnData && diagnData.id)) {
                const res = await fetchAPI(
                    'GET',
                    '/api/files',
                    {
                        query,
                        page,
                        pageSize,
                        startDate: formattedStartDate,
                        endDate: formattedEndDate,
                        sortBy,
                        prd: prdId || undefined,
                        dgn: diagnData ? diagnData.id : undefined,
                        ord: orderId || undefined
                    },
                    null,
                    {
                        handleErrorInternally: true,
                        url: __GALLARY_IMAGES_URL__,
                        noAuth: true,
                        headers: {
                            Authorization: getStorageToken()
                        }
                    }
                );

                setFileList(res && res.list);
                setCount(res && res.count);
            }
        },
        [prdId, diagnData, page, pageSize, sortBy, orderId]
    );

    const resizePhoto = useMemo(
        () => async hash => {
            await fetchAPI(
                'POST',
                '/api/files/async_operations',
                null,
                [
                    {
                        allFiles: false,
                        hash,
                        operation: {
                            type: 'RESIZE',
                            width: 1920,
                            height: 1080
                        },
                        type: 'img'
                    }
                ],
                {
                    handleErrorInternally: true,
                    url: __GALLARY_IMAGES_URL__,
                    noAuth: true,
                    headers: {
                        Authorization: getStorageToken(),
                        'Content-Type': 'application/json'
                    }
                }
            );
            await fetchFileList();
            await bussinessStats();

            notification.success({
                message: formatMessage({ id: 'barcode.success' })
            });
        },
        [bussinessStats, fetchFileList, formatMessage]
    );

    const resizePhotoGroup = useMemo(
        () => async () => {
            await fetchAPI(
                'POST',
                '/api/files/async_operations',
                null,
                [
                    ...(displayType === 'grid' ? selectedPhotos : selectedRows).map(({ hash, path }) => ({
                        allFiles: false,
                        hash: hash || path.split('.')[0],
                        operation: {
                            type: 'RESIZE',
                            width: 1920,
                            height: 1080
                        },
                        type: 'img'
                    }))
                ],
                {
                    handleErrorInternally: true,
                    url: __GALLARY_IMAGES_URL__,
                    noAuth: true,
                    headers: {
                        Authorization: getStorageToken(),
                        'Content-Type': 'application/json'
                    }
                }
            );
            await fetchFileList();

            notification.success({
                message: formatMessage({ id: 'barcode.success' })
            });
        },
        [displayType, fetchFileList, formatMessage, selectedPhotos, selectedRows]
    );

    const editPhoto = useMemo(
        () => async hash => {
            await fetchAPI(
                'PUT',
                '/api/file',
                null,
                {
                    hash,
                    name: newName
                },
                {
                    handleErrorInternally: true,
                    url: __GALLARY_IMAGES_URL__,
                    noAuth: true,
                    headers: {
                        Authorization: getStorageToken(),
                        'Content-Type': 'application/json'
                    }
                }
            );
            await fetchFileList();

            notification.success({
                message: formatMessage({ id: 'barcode.success' })
            });
        },
        [fetchFileList, formatMessage, newName]
    );

    const deletePhoto = useMemo(
        () => async hash => {
            await fetchAPI(
                'POST',
                '/api/files/async_operations',
                null,
                [
                    {
                        operation: {
                            type: 'DELETE'
                        },
                        type: 'img',
                        hash
                    }
                ],
                {
                    handleErrorInternally: true,
                    url: __GALLARY_IMAGES_URL__,
                    noAuth: true,
                    headers: {
                        Authorization: getStorageToken(),
                        'Content-Type': 'application/json'
                    }
                }
            );
            await fetchFileList();
            await bussinessStats();

            notification.success({
                message: formatMessage({ id: 'barcode.success' })
            });
        },

        [bussinessStats, fetchFileList, formatMessage]
    );

    const deletePhotoGroup = useMemo(
        () => async () => {
            await fetchAPI(
                'POST',
                '/api/files/async_operations',
                null,
                [
                    ...(displayType === 'grid' ? selectedPhotos : selectedRows).map(({ hash, path }) => ({
                        hash: hash || path.split('.')[0],
                        operation: {
                            type: 'DELETE'
                        },
                        type: 'img'
                    }))
                ],
                {
                    handleErrorInternally: true,
                    url: __GALLARY_IMAGES_URL__,
                    noAuth: true,
                    headers: {
                        Authorization: getStorageToken(),
                        'Content-Type': 'application/json'
                    }
                }
            );
            await fetchFileList();

            notification.success({
                message: formatMessage({ id: 'barcode.success' })
            });
        },

        [displayType, fetchFileList, formatMessage, selectedPhotos, selectedRows]
    );

    const handleSearchPhotos = useMemo(() => {
        const loadOptions = value => {
            fetchFileList(value);
        };

        return _.debounce(loadOptions, 500);
    }, [fetchFileList]);

    const downloadPhoto = async (file, id, text) => {
        setLoading(true);
        const url = `${__GALLARY_IMAGES_URL__}/api/upload_file`;

        const formData = new FormData();

        formData.append('file', file);
        formData.append('type', 'img');
        formData.append('name', file.name);
        formData.append('dgn', id);
        formData.append('dgnTxt', text);
        formData.append('ord', orderId);
        formData.append('ordTxt', orderNum);
        // formData.append('ord', 1);
        // formData.append('det', 2);
        // formData.append('dgn', 3);
        // formData.append('prd', 4);

        // formData.append('ordTxt', 'MRD-1174-1');
        // formData.append('detTxt', 'product-code-1 brand-1');
        // formData.append('dgnTxt', 'change left wheel');
        // formData.append('prdTxt', 'product-code-2 brand-2');

        await fetch(url, {
            method: 'POST',
            headers: {
                Authorization: getStorageToken()
            },
            body: formData
        }).then(function (response) {
            if (response.status !== 200) {
                return notification.error({
                    message: `${formatMessage({
                        id: 'gallary.not_enought_space'
                    })}!`
                });
            }

            return Promise.resolve(response);
        });

        setStartDate(dayjs().subtract(7, 'days'));
        setEndDate(dayjs());

        await fetchFileList();
        await bussinessStats();

        setLoading(false);
    };

    useEffect(() => {
        if (open) {
            fetchImagesSM();
        }
    }, [fetchImagesSM, open]);

    useEffect(() => {
        if (open) {
            fetchFileList();
            bussinessStats();
        }
    }, [bussinessStats, fetchFileList, open]);

    const formatMRD = mrd => {
        // Split the string by spaces
        const parts = mrd ? mrd.split(' ') : [];

        // Capitalize the first part and join with the other parts using hyphens
        const formattedMRD = parts
            .map((part, index) => {
                return index === 0 ? part.toUpperCase() : part;
            })
            .join('-');

        return formattedMRD;
    };

    const UnixTimestampToDate = unixTimestamp => {
        const formattedDate = dayjs
            .unix(unixTimestamp / 1000)
            .utc()
            .format('YYYY.MM.DD');

        return formattedDate;
    };

    const pagination = {
        pageSize,
        size: 'large',
        total: Math.ceil(count / pageSize) * pageSize,
        current: page,
        onChange: (page, pageSize) => {
            setPage(page);
            setPageSize(pageSize);
        }
    };

    // const menu = (
    //     <Menu>
    //         <Menu.Item
    //             onClick={async () => {
    //                 setSortBy('date');
    //             }}
    //             style={{
    //                 border: '1px solid lightgrey',
    //                 marginBottom: 8,
    //                 backgroundColor: sortBy == 'date' ? 'rgb(210, 190, 230)' : 'white'
    //             }}
    //         >
    //             <FormattedMessage id='date' />
    //         </Menu.Item>
    //         <Menu.Item
    //             onClick={async () => {
    //                 setSortBy('name');
    //             }}
    //             style={{
    //                 border: '1px solid lightgrey',
    //                 marginBottom: 8,
    //                 backgroundColor: sortBy == 'name' ? 'rgb(210, 190, 230)' : 'white'
    //             }}
    //         >
    //             <FormattedMessage id='detail_order.name' />
    //         </Menu.Item>
    //         <Menu.Item
    //             onClick={async () => {
    //                 setSortBy('prdTxt');
    //             }}
    //             style={{
    //                 border: '1px solid lightgrey',
    //                 marginBottom: 8,
    //                 backgroundColor: sortBy == 'prdTxt' ? 'rgb(210, 190, 230)' : 'white'
    //             }}
    //         >
    //             <FormattedMessage id='storage.product_code' />
    //         </Menu.Item>
    //         <Menu.Item
    //             onClick={async () => {
    //                 setSortBy('ordTxt');
    //             }}
    //             style={{
    //                 border: '1px solid lightgrey',
    //                 marginBottom: 8,
    //                 backgroundColor: sortBy == 'ordTxt' ? 'rgb(210, 190, 230)' : 'white'
    //             }}
    //         >
    //             <FormattedMessage id='client-mrds-table.mrd_number' />
    //         </Menu.Item>
    //     </Menu>
    // );

    // const groupActionMenu = (
    //     <Menu>
    //         {/* <Menu.Item
    //             onClick={async () => {
    //                 setSortBy('date');
    //             }}
    //             style={{
    //                 border: '1px solid lightgrey',
    //                 marginBottom: 8,
    //                 backgroundColor: sortBy == 'date' ? 'rgb(210, 190, 230)' : 'white'
    //             }}
    //         >
    //             <div>
    //                 <Button
    //                     icon={<EditOutlined />}
    //                     onClick={() => {
    //                         // setEdit(hash[0]);
    //                         // setName(properName[0]);
    //                         // setOpenEditName(true);
    //                     }}
    //                 />
    //                 <FormattedMessage id='gallary.edit_name' />
    //             </div>
    //         </Menu.Item> */}
    //         <Menu.Item
    //             onClick={() => {
    //                 resizePhotoGroup();
    //                 addSelectedPhotos([]);
    //             }}
    //             style={{
    //                 marginBottom: 8
    //             }}
    //         >
    //             <div>
    //                 <ShrinkOutlined
    //                     style={{
    //                         marginRight: 6
    //                     }}
    //                     type='text'
    //                 />
    //                 <FormattedMessage id='gallary.shrink_size' />
    //             </div>
    //         </Menu.Item>
    //         <Menu.Item
    //             danger
    //             onClick={() => {
    //                 deletePhotoGroup();
    //                 addSelectedPhotos([]);
    //             }}
    //             style={{
    //                 marginBottom: 8
    //             }}
    //         >
    //             <DeleteOutlined
    //                 style={{
    //                     marginRight: 6
    //                 }}
    //                 type='text'
    //             />

    //             <FormattedMessage id='delete' />
    //         </Menu.Item>
    //     </Menu>
    // );

    return (
        <Modal
            footer={null}
            loading={loading}
            onCancel={() => onCancel()}
            open={open}
            title={diagnData ? diagnData.name : undefined}
            width='75%'
            // zIndex={zetInd ? 3001 : undefined}
        >
            <div
                style={{
                    marginTop: 12
                }}
            >
                {/* <FormattedMessage id='all' />
                <Checkbox
                    checked={get(fileList, 'length') > 0 && get(fileList, 'length') == get(selectedPhotos, 'length')}
                    disabled={get(fileList, 'length') < 1}
                    onChange={() => {
                        if (get(fileList, 'length') !== get(selectedPhotos, 'length')) {
                            addSelectedPhotos(
                                fileList.map(({ path, name }) => ({
                                    name: name ? name.split('.')[0] : undefined,
                                    path,
                                    hash: path ? path.split('.')[0] : undefined
                                }))
                            );
                        } else {
                            addSelectedPhotos([]);
                        }
                    }}
                    style={{
                        marginLeft: 6
                    }}
                />

                <Dropdown disabled={!selectedPhotos} overlay={groupActionMenu}>
                    <Button
                        disabled={!selectedPhotos}
                        icon={<MenuOutlined />}
                        style={{ fontSize: 14, marginRight: 10 }}
                        type='text'
                    />
                </Dropdown> */}
                <Input
                    allowClear
                    controls={false}
                    onChange={async event => {
                        setQuery(event.target.value);

                        handleSearchPhotos(event.target.value);
                    }}
                    // parser={value => value.replace('-', '')}
                    placeholder={formatMessage({
                        id: 'search'
                    })}
                    style={{
                        width: isMobile ? '75%' : '90%'
                    }}
                    value={query}
                />
                <DateRangePicker
                    allowClear
                    dateRange={[dayjs(startDate), dayjs(endDate)]}
                    format='YYYY.MM.DD.HH.mm.SS'
                    getPopupContainer={trigger => trigger.parentNode}
                    minimize
                    onDateChange={dateRange => {
                        setStartDate(dateRange[0]);
                        setEndDate(dateRange[1]);
                        fetchFileList(query, dateRange[0], dateRange[1]);
                    }}
                    style={{
                        marginRight: 8
                    }}
                />
                {diagnData && (
                    <Upload
                        action={elem => downloadPhoto(elem, diagnData.id, diagnData.name)}
                        beforeUpload={file => {
                            const isPNG = file.type === 'image/png';
                            const isJPG = file.type === 'image/jpeg';
                            if (!isPNG && !isJPG) {
                                notification.error({
                                    message: `${file.name} ${formatMessage({
                                        id: 'photo_modal.is_not_an_image'
                                    })}!`
                                });
                            }

                            return isPNG || isJPG;
                        }}
                        showUploadList={false}
                    >
                        <Button icon={<VideoCameraAddOutlined />} type='text' />
                    </Upload>
                )}
            </div>

            {get(fileList, 'length') && displayType == 'grid' ? (
                fileList.map(({ path, name, date, prdTxt, ordTxt }, index) => {
                    const properName = name ? name.split('.') : undefined;
                    const formattedName =
                        properName && properName[0].length > 13 ? `${properName[0].slice(0, 13)}...` : properName[0];

                    const hash = path ? path.split('.') : undefined;

                    const properDate = date ? UnixTimestampToDate(date) : undefined;

                    const properUrl = imagesSM ? imagesSM.find((elem, indx) => indx == index) : undefined;

                    const order = formatMRD(ordTxt);
                    const code = prdTxt && prdTxt.length > 13 ? `${prdTxt.slice(0, 13)}...` : prdTxt;

                    const formattedHash = formatFilename(path);

                    return (
                        <div
                            className={
                                displayType == 'grid' && !isMobile ? Styles.displayGridImg : Styles.displayGridImgMobile
                            }
                            onMouseEnter={() => {
                                if (diagnData && diagnData.id) {
                                    setHover({
                                        show: true,
                                        imgId: hash[0]
                                    });
                                }
                            }}
                            onMouseLeave={() => {
                                if (diagnData && diagnData.id) {
                                    setHover({
                                        show: false,
                                        imgId: hash[0]
                                    });
                                }
                            }}
                        >
                            <div style={{ position: 'relative', display: 'inline-block' }}>
                                <Image
                                    key={hash[0]}
                                    height={250}
                                    onClick={() => fetchImage(`api/file/${formattedHash}`)}
                                    preview={{
                                        mask: hover && hover.show && hover.imgId === hash[0] && (
                                            <div className={Styles.actionBtnGrid}>
                                                <Tooltip title={<FormattedMessage id='gallary.edit_name' />}>
                                                    <Button
                                                        icon={<EditOutlined />}
                                                        onClick={e => {
                                                            setEdit(hash[0]);
                                                            setName(properName[0]);
                                                            setOpenEditName(true);

                                                            e.stopPropagation();
                                                        }}
                                                        style={{
                                                            marginRight: 6
                                                        }}
                                                    />
                                                </Tooltip>
                                                <Tooltip title={<FormattedMessage id='gallary.shrink_size' />}>
                                                    <Button
                                                        icon={<ShrinkOutlined />}
                                                        onClick={e => {
                                                            resizePhoto(hash[0]);
                                                            e.stopPropagation();
                                                        }}
                                                        style={{
                                                            marginRight: 6
                                                        }}
                                                    />
                                                </Tooltip>
                                                <Popconfirm
                                                    onConfirm={e => {
                                                        deletePhoto(hash[0]);
                                                        e.stopPropagation();
                                                    }}
                                                    title={<FormattedMessage id='general_settings_delete_question' />}
                                                    zIndex={3002}
                                                >
                                                    <Tooltip title={<FormattedMessage id='delete' />}>
                                                        <Button
                                                            danger
                                                            icon={<DeleteOutlined />}
                                                            onClick={e => {
                                                                e.stopPropagation();
                                                            }}
                                                            type='primary'
                                                        />
                                                    </Tooltip>
                                                </Popconfirm>
                                            </div>
                                        ),

                                        src: images ? images.url : undefined
                                    }}
                                    src={properUrl ? properUrl.url : undefined}
                                    width={!isMobile ? 420 : 372}
                                />

                                {/* <Checkbox
                                      checked={findSelected > -1}
                                      onClick={() => {
                                          if (findSelected < 0) {
                                              addSelectedPhotos([
                                                  ...selectedPhotos,
                                                  {
                                                      name: properName[0],
                                                      path,
                                                      hash: hash[0]
                                                  }
                                              ]);
                                          } else {
                                              const newSelectedPhotos = selectedPhotos.filter(
                                                  (item, idx) => item.hash !== hash[0]
                                              );
                                              addSelectedPhotos(newSelectedPhotos);
                                          }
                                      }}
                                      style={{
                                          position: 'absolute',
                                          top: '2px',
                                          right: displayType == 'grid' ? '10px' : undefined,

                                          padding: '5px',
                                          borderRadius: '3px'
                                      }}
                                  /> */}
                            </div>
                            <div>
                                <div
                                    style={{
                                        width: '100%',

                                        border: '1px solid lightgray',
                                        padding: 6,
                                        borderRadius: 4
                                    }}
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between'
                                        }}
                                    >
                                        <div>
                                            <div
                                                style={{
                                                    marginBottom: 6,
                                                    opacity: 0.7,
                                                    fontWeight: 700
                                                }}
                                            >
                                                <Tooltip title={properName[0]}>
                                                    <FormattedMessage id='locations.name' />:{' '}
                                                    {formattedName || <FormattedMessage id='long_dash' />}
                                                </Tooltip>
                                            </div>

                                            <div
                                                style={{
                                                    marginBottom: 6,
                                                    opacity: 0.7,
                                                    fontWeight: 700
                                                }}
                                            >
                                                <FormattedMessage id='date' />: {properDate}
                                            </div>
                                        </div>
                                        <div>
                                            <div
                                                style={{
                                                    marginBottom: 6,
                                                    opacity: 0.7,
                                                    fontWeight: 700
                                                }}
                                            >
                                                <Tooltip title={prdTxt}>
                                                    <FormattedMessage id='diagnostic-page.code' />:{' '}
                                                    {code || <FormattedMessage id='long_dash' />}
                                                </Tooltip>
                                            </div>

                                            <div
                                                style={{
                                                    marginBottom: 6,
                                                    opacity: 0.7,
                                                    fontWeight: 700
                                                }}
                                            >
                                                <FormattedMessage id='task_page.order' />: {order}
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className={Styles.actionBtnGrid}>
                                          <Tooltip title={<FormattedMessage id='gallary.edit_name' />}>
                                              <Button
                                                  icon={<EditOutlined />}
                                                  onClick={() => {
                                                      setEdit(hash[0]);
                                                      setName(properName[0]);
                                                      setOpenEditName(true);
                                                  }}
                                              />
                                          </Tooltip>
                                          <Tooltip title={<FormattedMessage id='gallary.shrink_size' />}>
                                              <Button icon={<ShrinkOutlined />} onClick={() => resizePhoto(hash[0])} />
                                          </Tooltip>
                                          <Popconfirm
                                              onConfirm={() => {
                                                  deletePhoto(hash[0]);
                                              }}
                                              title={<FormattedMessage id='general_settings_delete_question' />}
                                          >
                                              <Button danger icon={<DeleteOutlined />} type='primary' />
                                          </Popconfirm>
                                      </div> */}
                                    {isMobile && diagnData && diagnData.id && (
                                        <div className={Styles.actionBtnGrid}>
                                            <Tooltip title={<FormattedMessage id='gallary.edit_name' />}>
                                                <Button
                                                    icon={<EditOutlined />}
                                                    onClick={e => {
                                                        setEdit(hash[0]);
                                                        setName(properName[0]);
                                                        setOpenEditName(true);
                                                        e.stopPropagation();
                                                    }}
                                                    style={{
                                                        marginRight: 6
                                                    }}
                                                />
                                            </Tooltip>
                                            <Tooltip title={<FormattedMessage id='gallary.shrink_size' />}>
                                                <Button
                                                    icon={<ShrinkOutlined />}
                                                    onClick={() => resizePhoto(hash[0])}
                                                    style={{
                                                        marginRight: 6
                                                    }}
                                                />
                                            </Tooltip>
                                            <Popconfirm
                                                onConfirm={e => {
                                                    deletePhoto(hash[0]);
                                                    e.stopPropagation();
                                                }}
                                                title={<FormattedMessage id='general_settings_delete_question' />}
                                            >
                                                <Button
                                                    danger
                                                    icon={<DeleteOutlined />}
                                                    style={{
                                                        marginRight: 6
                                                    }}
                                                    type='primary'
                                                />
                                            </Popconfirm>
                                        </div>
                                    )}
                                </div>
                            </div>{' '}
                        </div>
                    );
                })
            ) : (
                <div className={Styles.noFilesText}>
                    <FormattedMessage id='no_files_found' />
                </div>
            )}

            <Modal
                onCancel={async () => {
                    await setOpenEditName(false);
                }}
                onOk={async () => {
                    await editPhoto(edit);
                    setOpenEditName(false);
                }}
                open={openEditName}
                size='small'
                title={<FormattedMessage id='gallary.edit_name' />}
                zIndex={3001}
            >
                <div>
                    <Input
                        onChange={event => setName(event.target.value)}
                        placeholder={formatMessage({
                            id: 'diagnostic-page.name'
                        })}
                        style={{
                            width: '100%'
                        }}
                        value={newName}
                    />
                </div>
            </Modal>
            <div className={Styles.pagination}>
                <Pagination {...pagination} />
            </div>
        </Modal>
    );
};

const mapStateToProps = state => ({
    user: state.auth,
    isMobile: state.ui.views.isMobile
});

export default injectIntl(connect(mapStateToProps)(PreviewMediaFilesModal));
