// declare routes pathes

// __OLD_APP_URL__ will be replaced by webpack build
const OLD_APP_URL = '';

const book = Object.freeze({
    // Public
    login: '/login',
    forgotPassword: '/forgot-password',
    userFeedback: '/s',
    // newPasswordPage:     '/new-password',
    newPassword: '/new-password',
    exception: '/exception',
    exceptionStatusCode: '/exception/:statusCode',

    // Private
    mainMenuPage: '/main-menu',
    userAgreement: '/user-agreement',
    profile: '/profile',
    dashboard: '/dashboard',
    myTasksPage: '/mytasks',
    myTaskPage: '/mytasks/:id',
    newDocumentPage: '/new-document',
    sparePartsWorkplacePage: '/spare-parts-workplace',
    // Orders
    orders: '/orders',
    ordersKanban: '/orders-kanban',
    ordersByStatuses: '/orders/:ordersStatuses',
    ordersAppointments: '/orders/appointments',
    agreement: '/agreement',
    complexes: '/complexes',
    rtl: '/rtl',
    rtlDoc: '/rtl/:id',

    // Order
    order: '/order',
    orderId: '/order/:id',

    // AddOrder
    addOrder: '/add',

    // Reports
    reportsPage: '/reports',
    reports: '/orders/reports',
    reportOrders: '/report/orders',
    reportLoadKPI: '/report/load_kpi',
    reportCashFlow: '/report/cash_flow',
    reportCashOrdersLogs: '/report/cash_orders_logs',
    reportGraphic: '/report/graphic',
    reportTemplateEditor: '/report/template_editor',
    abcReportsPage: '/abc',

    // Barcode
    barcodePage: '/barcode',

    // Interactions
    addInteraction: '/interactions/add',
    interactions: '/interactions',
    interactionId: '/interactions/:id',
    intDirectories: '/interactions_directories',

    // Reference book
    directoriesPage: '/directories',
    settingsDirectoriesPage: '/settings_directories',

    // Clients
    clients: '/clients',
    client: '/client',
    clientId: '/client/:id',
    clientHotOperations: '/client-hot-operations',

    // Vehicles
    vehicles: '/vehicles',
    vehicle: '/vehicle',
    vehicleId: '/vehicle/:id',
    onlineServiceBook: '/online_service_book',
    onlineServiceBookWithHash: '/online_service_book/:base64Data',

    // Aggregates

    aggregatesPage: '/aggregates',
    aggregate: '/aggregate',
    aggregateId: '/aggregate/:id',

    // Employees
    employeesPage: '/employees',
    addEmployee: '/employees/add',

    editEmployee: '/employees/:id',
    employee: '/employees',

    // Crews

    crewsPage: '/crews',

    // Suppliers
    suppliersPage: '/suppliers',
    supplierPage: '/supplier/:id',
    supplier: '/supplier',

    // Catalogue
    laborsPage: '/labors',
    diagnosticPatterns: '/diagnostic-patterns',
    diagnosticsDirectory: '/diagnostics-directory',
    warehouses: '/warehouses',
    analytics: '/report/analytics',
    myNormative: '/mynormative',
    orderCatalogue: '/order-catalogue',

    //  Cash
    cashFlowPage: '/cash/flow',
    accDocs: '/acc',
    accDocId: '/acc/:id',
    receivablesAndPayablesPage: '/receivables_and_payables',
    cashSettingsPage: '/cash/settings',
    cashBankPage: '/cash/bank',
    debtMovement: '/debtMovement',
    closeDebt: '/closeDebt',
    accountPlan: '/account-plan',

    // Storage
    productsGroups: '/products-groups',
    priceGroups: '/price-groups',
    products: '/products',
    product: '/product',
    productPage: '/product/:id',
    exchangeRate: '/exchange-rate',
    storageOrders: '/storage-orders',
    storageIncomes: '/storage-incomes',
    storageExpenses: '/storage-expenses',
    storageOrdersExpenses: '/storage-orders-expenses',
    storageTransfers: '/storage-transfers',
    storageBalance: '/storage-balance',
    storageMovement: '/storage-movement',
    storageInventory: '/storage-inventory',
    storageDocument: '/document-storage',
    storageDocumentId: '/document-storage/:id',
    productsTracking: '/tracking',
    storageIncomeDoc: '/income-document',
    storageIncomeDocId: '/income-document/:id',
    repairMapSetting: '/repair-map',
    wms: '/wms',
    productsWithoutCell: '/products-without-cell',
    waybillsPage: '/waybills',
    units: '/units',
    quick_requests: '/quick_requests',
    source: '/source',

    // Journals
    journalsPage: '/journal-page',
    storageJournal: '/storage-journal',
    storageCalculations: '/storage-calculations',
    storageFunds: '/storage-funds',
    journalVAT: '/journal-vat',
    callsJournal: '/journal-calls',
    journalEntries: '/journal-entries',

    // Tire Storage
    vehicleTypes: '/vehicle-types',
    tirePriceGroups: '/tire-prices',

    // Locations
    locationsPage: '/locations',
    locationSettings: '/location-settings',
    locationsDocument: '/location-document',
    locationsVehicles: '/location-vehicles',
    locationsMovement: '/location-movement',

    // Statistics
    chart: '/chart',
    feedback: '/feedback',
    feedbackId: '/feedback/:id',
    calls: '/calls',
    managerReports: '/manager-reports',

    // Payment
    paymentPage: '/payment',
    subscriptionPackagesPage: '/subscription/packages',
    subscriptionHistoryPage: '/subscription/history',
    referenceBookPage: '/reference-book',
    salariesPage: '/SAL',
    salaryDocumentPageId: '/SAL/:id',

    // Settings
    settings: '/settings',
    webSettings: '/web-settings',
    crosses: '/crosses',
    storage: '/storage',
    packagePage: '/businesses/packages',
    businessPackagePage: '/packages',
    managerRolePage: '/managers/roles',
    rolePage: '/roles',
    requisites: '/requisites',
    smsSettings: '/sms-settings',
    detailStatusSettings: '/detail-status-settings',
    apiSettings: '/api-settings',
    regulations: '/regulations',
    themeSettings: '/theme-settings',
    monetization: '/monetization',

    // Administration
    servicesPage: '/administration/services',
    brandsPage: '/administration/brands',
    availabilitiesPage: '/administration/availabilities',
    syncImportPage: '/administration/sync-import',
    syncExportPage: '/administration/sync-export',
    pricelistsPage: '/pricelists',
    brands: '/brands',
    editMakes: '/administration/edit-makes',
    rights: '/rights',

    // Search

    historyTrackingPage: '/history-tracking',

    // Photo

    gallaryPage: '/gallary',

    // Carhshop

    carshop: '/carshop',

    oldApp: {
        // link to my.cb24.eu
        link: `${OLD_APP_URL}`,
        // dashboard:              `${OLD_APP_URL}/dashboard`,
        tasks: `${OLD_APP_URL}/tasks`,
        // clients:                `${OLD_APP_URL}/clients`,
        // employees:              `${OLD_APP_URL}/employees`,
        controlPanel: `${OLD_APP_URL}/control-panel`,
        // indicators:             `${OLD_APP_URL}/universal-chart`,
        funel: `${OLD_APP_URL}/funel`,
        // reviews:                `${OLD_APP_URL}/reviews`,
        statistics: `${OLD_APP_URL}/statistics`,
        // statisticsCalls:        `${OLD_APP_URL}/statistics/calls`,
        settings: `${OLD_APP_URL}/settings`,
        settingsRequisites: `${OLD_APP_URL}/requisites`,
        settingsSpecialization: `${OLD_APP_URL}/settings/specialization`,
        settingsServices: `${OLD_APP_URL}/settings/services`,
        settingsOffers: `${OLD_APP_URL}/settings/offers`,
        settingsNews: `${OLD_APP_URL}/settings/news`,
        settingsArticles: `${OLD_APP_URL}/settings/articles`,
        settingsGallery: `${OLD_APP_URL}/settings/gallery`,
        settingsManagers: `${OLD_APP_URL}/settings/managers`,
        settingsNotifications: `${OLD_APP_URL}/settings/notifications`,
        feedback: `${OLD_APP_URL}/feedback`
    }
});

export default book;
