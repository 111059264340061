import { Button, DatePicker, Input, Modal, notification, TimePicker } from 'antd';
import dayjs from 'dayjs';
import _ from 'lodash';
import {
    createOrder,
    DEF_DATE_FORMAT,
    DEF_TIME_FORMAT,
    selectBeginDate,
    selectBeginTime,
    selectComment,
    setBeginDate,
    setBeginTime,
    setComment,
    setRegisterForServiceModalVisibility
} from 'pages/OnlineServiceBookPage/redux/duck';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { fetchAPI } from 'utils';

import Styles from './styles.m.css';

const { TextArea } = Input;

const mapStateToProps = state => ({
    beginDate: selectBeginDate(state),
    beginTime: selectBeginTime(state),
    comment: selectComment(state)
});

const mapDispatchToProps = {
    createOrder,

    setBeginDate,
    setBeginTime,
    setComment,
    setRegisterForServiceModalVisibility
};

/**
 * This modal is used to register the user for service.
 * It creates a new order.
 * This is adapted copy of a global model, it works with auth used in this project.
 *
 * @property {*} clientId - ID of a client to create order for
 * @property {*} [vehicleId] - ID of a vehicle of a client
 * @property {*} managerId - ID of a client to create order for
 * @property {*} businessId - ID of a business to create order for
 */
@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class RefactoredRegisterForServiceModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            currentCommentaryProps: {
                problems: []
            },
            cb24data: null
        };

        this.problems = [
            {
                damageType: 'plannedService',
                damageId: 1
            },
            {
                damageType: 'undercarriageDiagnostics',
                damageId: 2
            },
            {
                damageType: 'repair',
                damageId: 3
            },
            {
                damageType: 'brakeReplacement',
                damageId: 4
            },
            {
                damageType: 'generalDiagnostics',
                damageId: 5
            },
            {
                damageType: 'tireFitting',
                damageId: 6
            }
        ];
    }

    componentDidUpdate(prevProps) {
        const { setBeginDate } = this.props;
        const { cb24data } = this.state;
        if (!prevProps.visible && this.props.visible) {
            this.fetchCB24data();
            if (dayjs().day() === 5 && _.get(cb24data, 'schedule[0].saturday', [])) {
                setBeginDate({ beginDate: dayjs().add(1, 'day').format(DEF_DATE_FORMAT) });
            } else if (dayjs().day() === 5 && !_.get(cb24data, 'schedule[0].saturday', [])) {
                setBeginDate({ beginDate: dayjs().add(3, 'day').format(DEF_DATE_FORMAT) });
            }

            if (dayjs().day() !== 5) {
                setBeginDate({ beginDate: dayjs().add(1, 'day').format(DEF_DATE_FORMAT) });
            }
        }
    }

    onClose = () => {
        this.props.hideModal();
        this.setState({
            currentCommentary: undefined,
            currentCommentaryProps: {
                problems: []
            }
        });
    };

    getCommentary = () => {
        const { currentCommentaryProps, problems } = this.state;

        let currentCommentary = this.props.commentary || '';

        if (currentCommentaryProps.problems.length) {
            currentCommentary += `${currentCommentaryProps.problems.map(data => {
                const damageType = `${this.problems.find(problem => problem.damageId === data).damageType}`;

                return `${this.props.intl.formatMessage({
                    id: `register_for_service_modal.${damageType}`
                })}`;
            })};`;
        }

        this.setState({
            currentCommentary
        });
    };

    setCommentaryProblems = value => {
        const { currentCommentaryProps } = this.state;
        const problemIndex = currentCommentaryProps.problems.indexOf(value);
        if (problemIndex === -1) {
            currentCommentaryProps.problems.push(value);
        } else {
            currentCommentaryProps.problems = currentCommentaryProps.problems.filter(
                (value, index) => index !== problemIndex
            );
        }
        this.getCommentary();
    };

    registerForService = async () => {
        const {
            beginDate,
            beginTime,
            vehicle,
            client,
            intl: { formatMessage },
            dataServiceBook,
            token
        } = this.props;

        const { currentCommentary } = this.state;
        await fetchAPI(
            'POST',
            '/orders/by_client',
            undefined,
            {
                businessId: _.get(dataServiceBook, 'businessId'),
                clientId: _.get(client, 'clientId'),
                clientVehicleId: _.get(dataServiceBook, 'vehicleId'),
                date: beginDate ? dayjs(beginDate).format('YYYY-MM-DD') : undefined,
                time: beginTime,
                comment: currentCommentary
            },
            {
                handleErrorInternally: true,
                headers: {
                    Authorization: token,
                    'content-type': 'application/json'
                }
            }
        );
        await notification.success({
            message: this.props.intl.formatMessage({ id: 'barcode.success' })
        });
        this.onClose();
    };

    fetchCB24data = async () => {
        const { dataServiceBook } = this.props;
        const sessionId = _.get(dataServiceBook, 'sessionBussiness');
        this.setState({
            cb24data: null
        });
        const data = await fetchAPI(
            'GET',
            'rest/v1/business',
            {
                sessionId
            },
            null,
            { handleErrorInternally: true, url: __OLD_APP_API_URL__ }
        );
        this.setState({ cb24data: data });
    };

    render() {
        const {
            visible,
            beginDate,
            beginTime,
            comment,
            setBeginDate,
            setBeginTime,
            setComment,
            isMobile,
            client,
            businessId,
            dataServiceBook,
            intl: { formatMessage }
        } = this.props;

        const { currentCommentary, currentCommentaryProps, cb24data } = this.state;

        return (
            <div>
                <Modal
                    destroyOnClose
                    footer={
                        <div>
                            <Button
                                onClick={() => {
                                    this.registerForService();
                                }}
                                type='primary'
                            >
                                <FormattedMessage id='online_service_book_page.register_for_service' />
                            </Button>
                        </div>
                    }
                    onCancel={this.onClose}
                    title={
                        <div className={Styles.title}>
                            <FormattedMessage id='online_service_book_page.register_for_service' />
                        </div>
                    }
                    visible={visible}
                    width={isMobile ? '100%' : '50%'}
                >
                    <div>
                        <div className={Styles.dataNtimeBlock}>
                            <div>
                                <div
                                    style={{
                                        fontWeight: 700
                                    }}
                                >
                                    <FormattedMessage id='register_for_service_modal.choose_date' />
                                </div>
                                <DatePicker
                                    format='DD-MM-YYYY'
                                    inputReadOnly
                                    onChange={date => setBeginDate({ beginDate: date.format(DEF_DATE_FORMAT) })}
                                    popupStyle={{ zIndex: 9999 }}
                                    value={dayjs(beginDate, DEF_DATE_FORMAT)}
                                />
                            </div>

                            <div>
                                <div
                                    style={{
                                        fontWeight: 700
                                    }}
                                >
                                    <FormattedMessage id='register_for_service_modal.choose_time' />
                                </div>
                                <TimePicker
                                    defaultOpenValue={dayjs('09:00:00', 'HH:mm:ss')}
                                    disabledTime={() => ({
                                        disabledHours: () => [
                                            0, 1, 2, 3, 4, 5, 6, 7, 22, 23
                                        ]
                                    })}
                                    format='HH:mm'
                                    getPopupContainer={trigger => trigger.parentNode}
                                    hideDisabledOptions
                                    inputReadOnly
                                    onChange={time => setBeginTime({ beginTime: time.format(DEF_TIME_FORMAT) })}
                                    popupStyle={{ zIndex: 9999 }}
                                    showNow={false}
                                    value={dayjs(beginTime, DEF_TIME_FORMAT)}
                                    minuteStep={15}
                                />
                            </div>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                fontWeight: 700
                            }}
                        >
                            <FormattedMessage id='register_for_service_modal.reason' />
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                marginBottom: 14
                            }}
                        >
                            <div>
                                <div className={!isMobile ? Styles.blockButtonsWrap : undefined}>
                                    {this.problems.map(({ damageType, damageId }) => {
                                        return (
                                            <Button
                                                className={isMobile ? Styles.btnStyleMobile : Styles.btnStyle}
                                                onClick={() => {
                                                    this.setCommentaryProblems(damageId);
                                                    this.setState({
                                                        damageIds: damageId
                                                    });
                                                }}
                                                style={{
                                                    marginBottom: 8
                                                }}
                                                type={
                                                    currentCommentaryProps.problems.findIndex(
                                                        elem => damageId === elem
                                                    ) === -1
                                                        ? 'default'
                                                        : 'primary'
                                                }
                                            >
                                                <span>
                                                    <FormattedMessage id={`register_for_service_modal.${damageType}`} />
                                                </span>
                                            </Button>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>

                        <TextArea
                            ref={this.commentaryInput}
                            autoFocus
                            onChange={event => {
                                this.setState({
                                    currentCommentary: event.target.value
                                });
                            }}
                            placeholder={`${this.props.intl.formatMessage({
                                id: 'comment'
                            })}...`}
                            style={{
                                width: '100%',
                                minHeight: '150px',
                                resize: 'none'
                            }}
                            value={currentCommentary}
                        />
                    </div>
                </Modal>
            </div>
        );
    }
}
