import { FilterFilled } from '@ant-design/icons';
import { Button, Input, Table } from 'antd';
import { Select } from 'antd/lib';
import { Numeral } from 'commons';
import { DateRangePicker } from 'components';
import dayjs from 'dayjs';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import book from 'routes/book';
import Styles from './styles.m.css';

const dateFormat = 'DD.MM.YYYY';
const { Option } = Select;

const AccDocsTable = ({
    intl,
    query,
    reqfilters = {},
    reset,
    shouldReset,
    responsibleFilter,
    fetchAccDocs,
    docs,
    loading,
    setFilters,
    filters
}) => {
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(15);
    const [interactions, setInteractions] = useState([]);
    const [statuses, setStatuses] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [employees, setEmployees] = useState([]);

    useEffect(() => {
        fetchAccDocs();
    }, [page, pageSize, filters]);

    // useEffect(() => {
    //     const func = async () => {
    //         await setPage(1);
    //         fetchAccDocs();
    //     };
    //     func();
    // }, [query]);

    const handleSearch = async (confirm, dataIndex) => {
        confirm();
        await setPage(1);
        fetchAccDocs(query, undefined, filters);
    };

    const handleReset = async (confirm, clearFilters, dataIndex) => {
        confirm();
        clearFilters();
        const tmp = filters;
        if (dataIndex == 'datetime') {
            delete tmp.fromDatetime;
            delete tmp.toDatetime;
        } else if (dataIndex == 'documentDatetime') {
            delete tmp.documentFromDatetime;
            delete tmp.documentToDatetime;
        } else if (dataIndex === 'documentNumber') {
            delete tmp.documentNumber;
        } else if (dataIndex === 'debet') {
            delete tmp.debet;
        } else if (dataIndex === 'credit') {
            delete tmp.credit;
        }
        await setFilters(tmp);
        await setPage(1);
        fetchAccDocs();
    };
    useEffect(() => {
        if (shouldReset === 'reset') {
            reset();
            setFilters({});
            fetchAccDocs({});
        }
    }, [shouldReset, reset, fetchAccDocs, setFilters]);

    const debounceFilters = useMemo(() => {
        const loadOptions = (filters, value) => {
            setFilters(filters, value);
        };

        return _.debounce(loadOptions, 1000);
    }, [setFilters]);

    const getColumnSearchProps = useCallback(
        dataIndex => {
            let filterComponent = (confirm, clearFilters) => (
                <Input
                    onChange={e => {}}
                    onPressEnter={() => handleSearch(confirm, dataIndex)}
                    placeholder={intl.formatMessage({
                        id: 'search'
                    })}
                    style={{ marginBottom: 8, display: 'block', width: 180 }}
                />
            );

            if (dataIndex === 'documentNumber' || dataIndex === 'debet' || dataIndex === 'credit') {
                filterComponent = (confirm, clearFilters) => (
                    <Input
                        onChange={e => debounceFilters({ ...filters, [dataIndex]: e.target.value })}
                        placeholder={intl.formatMessage({
                            id: 'search'
                        })}
                        style={{ marginBottom: 8, display: 'block', width: 180 }}
                    />
                );
            }

            if (dataIndex === 'datetime') {
                filterComponent = (confirm, clearFilters) => (
                    <div className={Styles.filterDatePicker} style={{ backgroundColor: 'white' }}>
                        <DateRangePicker
                            allowClear
                            dateRange={
                                filters.fromDatetime
                                    ? [dayjs(filters.fromDatetime), dayjs(filters.toDatetime)]
                                    : undefined
                            }
                            format={dateFormat}
                            getPopupContainer={trigger => trigger.parentNode}
                            onDateChange={async dateRange => {
                                await setFilters({
                                    ...filters,
                                    fromDatetime: dateRange[0].format('YYYY-MM-DD'),
                                    toDatetime: dateRange[1].format('YYYY-MM-DD')
                                });
                            }}
                            placement='bottomRight'
                            popupStyle={{
                                maxHeight: 400,
                                overflow: 'auto',
                                zIndex: 9999,
                                minWidth: 220
                            }}
                            style={{ width: '100%' }}
                        />
                    </div>
                );
            }

            if (dataIndex === 'documentDatetime') {
                filterComponent = (confirm, clearFilters) => (
                    <div className={Styles.filterDatePicker} style={{ backgroundColor: 'white' }}>
                        <DateRangePicker
                            allowClear
                            dateRange={
                                filters.documentFromDatetime
                                    ? [dayjs(filters.documentFromDatetime), dayjs(filters.documentToDatetime)]
                                    : undefined
                            }
                            format={dateFormat}
                            getPopupContainer={trigger => trigger.parentNode}
                            onDateChange={async dateRange => {
                                await setFilters({
                                    ...filters,
                                    documentFromDatetime: dateRange[0].format('YYYY-MM-DD'),
                                    documentToDatetime: dateRange[1].format('YYYY-MM-DD')
                                });
                            }}
                            popupStyle={{
                                maxHeight: 400,
                                overflow: 'auto',
                                zIndex: 9999,
                                minWidth: 220
                            }}
                            style={{ width: '100%' }}
                        />
                    </div>
                );
            }

            if (dataIndex === 'statusId') {
                filterComponent = (confirm, clearFilters) => (
                    <Select
                        allowClear
                        mode='multiple'
                        onChange={value => {
                            setFilters({ ...filters, [dataIndex]: value });
                        }}
                        showSearch
                        style={{ marginBottom: 8, display: 'block', width: 180 }}
                        value={filters[dataIndex]}
                        optionFilterProp='children'
                        // mode='multiple'
                        placeholder={intl.formatMessage({
                            id: 'search'
                        })}
                    >
                        {statuses.map(({ id, name }) => (
                            <Option key={id} value={id}>
                                {name}
                            </Option>
                        ))}
                    </Select>
                );
            }

            if (dataIndex === 'counterpartyType') {
                filterComponent = (confirm, clearFilters) => (
                    <Select
                        allowClear
                        onChange={value => {
                            setFilters({ ...filters, [dataIndex]: value });
                        }}
                        showSearch
                        style={{ marginBottom: 8, display: 'block', width: 180 }}
                        value={filters[dataIndex]}
                        optionFilterProp='children'
                        // mode='multiple'
                        placeholder={intl.formatMessage({
                            id: 'search'
                        })}
                    >
                        <Option key='CLIENT' value='CLIENT'>
                            <FormattedMessage id='task_page.client' />
                        </Option>
                        <Option key='EMPLOYEE' value='EMPLOYEE'>
                            <FormattedMessage id='employee' />
                        </Option>
                        <Option key='SUPPLIER' value='SUPPLIER'>
                            <FormattedMessage id='suppliers_page.supplier' />
                        </Option>
                    </Select>
                );
            }

            if (dataIndex === 'responsibleId') {
                filterComponent = (confirm, clearFilters) => (
                    <Select
                        allowClear
                        onChange={value => {
                            setFilters({ ...filters, [dataIndex]: value });
                        }}
                        showSearch
                        style={{ marginBottom: 8, display: 'block', width: 180 }}
                        value={filters[dataIndex]}
                        optionFilterProp='children'
                        // mode='multiple'
                        placeholder={intl.formatMessage({
                            id: 'search'
                        })}
                    >
                        {employees
                            .filter(({ disabled, isManager }) => !disabled && isManager)
                            .map(({ managerId, name, surname }) => (
                                <Option key={managerId} value={managerId}>
                                    {surname} {name}
                                </Option>
                            ))}
                    </Select>
                );
            }

            return {
                filterDropdown: ({ confirm, clearFilters }) => (
                    <div style={{ padding: 8 }}>
                        {filterComponent(confirm, clearFilters)}
                        {dataIndex !== '11' && dataIndex !== 'filterCreatedDate' && dataIndex !== 'filtertDoneDate' && (
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-around'
                                }}
                            >
                                <Button onClick={() => handleReset(confirm, clearFilters, dataIndex)} size='small'>
                                    <FormattedMessage id='reset' />
                                </Button>
                            </div>
                        )}
                    </div>
                ),
                filterIcon: () => {
                    let colorFilter;
                    if (filters[dataIndex]) {
                        colorFilter = 'var(--primary)';
                    }
                    if (dataIndex == 'datetime' && filters.fromDatetime) {
                        colorFilter = 'var(--primary)';
                    }

                    if (dataIndex == 'documentDatetime' && filters.documentFromDatetime) {
                        colorFilter = 'var(--primary)';
                    }

                    return (
                        <FilterFilled
                            style={{
                                fontSize: 14,
                                color: colorFilter
                            }}
                        />
                    );
                }
                // onFilterDropdownVisibleChange: visible => {
                //     if (visible) {
                //         setTimeout(() => this.searchInput.select(), 100);
                //     }
                // }
            };
        },
        [intl, handleSearch, debounceFilters, filters, setFilters, statuses, employees, handleReset]
    );

    const columns = useMemo(() => {
        return [
            {
                title: <FormattedMessage id='acc_table_page.acc_number' />,
                key: 'accNumber',
                width: '10%',
                render: row => {
                    return row.accNum ? (
                        <Link to={`${book.accDocs}/${row.id}`}>{row.accNum}</Link>
                    ) : (
                        <FormattedMessage id='long_dash' />
                    );
                }
            },
            {
                title: <FormattedMessage id='acc_table_page.acc_date' />,
                key: 'accDate',
                ...getColumnSearchProps('datetime'),
                render: row => {
                    return row.datetime ? (
                        dayjs(row.datetime).format('DD.MM.YYYY ')
                    ) : (
                        <FormattedMessage id='long_dash' />
                    );
                }
            },
            {
                title: <FormattedMessage id='entry_table.doc_number' />,
                key: 'docNumber',
                ...getColumnSearchProps('documentNumber'),
                render: row => {
                    return row.documentNumber ? row.documentNumber : <FormattedMessage id='long_dash' />;
                }
            },
            {
                title: <FormattedMessage id='order_from_table_date_doc' />,
                key: 'docDate',
                ...getColumnSearchProps('documentDatetime'),
                render: row => {
                    return row.documentDatetime ? (
                        dayjs(row.documentDatetime).format('DD.MM.YYYY ')
                    ) : (
                        <FormattedMessage id='long_dash' />
                    );
                }
            },
            {
                title: <FormattedMessage id='entries_tab.CONTRAGENT' />,
                key: 'counterparty',
                render: row => {
                    return row.counterpartName ? row.counterpartName : <FormattedMessage id='long_dash' />;
                }
            },
            {
                title: <FormattedMessage id='entries_tab.sign.+' />,
                key: 'debet',
                ...getColumnSearchProps('debet'),
                render: row => {
                    return row.debet ? `#${row.debet}` : <FormattedMessage id='long_dash' />;
                }
            },
            {
                title: <FormattedMessage id='entries_tab.sign.-' />,
                key: 'credit',
                ...getColumnSearchProps('credit'),
                render: row => {
                    return row.credit ? `#${row.credit}` : <FormattedMessage id='long_dash' />;
                }
            },
            {
                title: <FormattedMessage id='sum' />,
                key: 'sum',
                render: row => {
                    return row.sum ? <Numeral mask='0,0.00'>{row.sum}</Numeral> : <FormattedMessage id='long_dash' />;
                }
            },
            {
                title: <FormattedMessage id='status' />,
                key: 'sum',
                render: row => {
                    return row.status ? (
                        <FormattedMessage id={`employee_salary_rules.status.${row.status}`} />
                    ) : (
                        <FormattedMessage id='long_dash' />
                    );
                }
            },
            {
                title: <FormattedMessage id='comment' />,
                key: 'comment',
                render: row => {
                    return row.comment ? row.comment : <FormattedMessage id='long_dash' />;
                }
            },
            {
                title: <FormattedMessage id='orders.responsible' />,
                key: 'responsible',
                render: row => {
                    return row.managerName ? row.managerName : <FormattedMessage id='long_dash' />;
                }
            }
        ];
    }, [getColumnSearchProps]);

    const pagination = {
        pageSize,
        total: Math.ceil(totalCount / pageSize) * pageSize,
        hideOnSinglePage: true,
        current: page,
        onChange: async (page, pageSize) => {
            await setPage(page);
            await setPageSize(pageSize);
        }
    };

    return <Table columns={columns} dataSource={docs} loading={loading} pagination={pagination} size='small' />;
};

export default injectIntl(AccDocsTable);
