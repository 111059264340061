import { Flex, Input, InputNumber, Modal, Select, TreeSelect, notification } from 'antd';
import dayjs from 'dayjs';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { fetchAPI } from 'utils';

import { getAccountsList } from 'pages/AccountPlanPage/utils/getAccountsList';
import Styles from './styles.m.css';

const { TextArea } = Input;
const { Option } = Select;

const counterpartyTypeMap = {
    CLIENTS: 'interactions.counterparty_type.CLIENT',
    EMPLOYEES: 'interactions.counterparty_type.EMPLOYEE',
    SUPPLIERS: 'interactions.counterparty_type.SUPPLIER',
    STOCKS: 'entries_tab.STOCK',
    CASHBOXES: 'entries_tab.CASHBOX'
};

const inputWidth = 180;

const AddAccRowModal = ({ open = false, closeModal, intl, id, fetchDocData, rowData, docData }) => {
    const [date, setDate] = useState(dayjs());
    const [subStatus, setSubStatus] = useState();
    const [type, setType] = useState();
    const [comment, setComment] = useState('');
    const [accounts, setAccounts] = useState([]);
    const [tree, setTree] = useState([]);
    const [accountId, setAccountId] = useState();
    const [accountIdSecond, setAccountIdSecond] = useState();
    const [counterpartType, setCounterparty] = useState();
    const [counterpartTypeSecond, setCounterpartySecond] = useState();
    const [amount, setAmount] = useState();
    const [amountSecond, setAmountSecond] = useState();
    const [debit, setDebit] = useState('DEBET');
    const [credit, setCredit] = useState('CREDIT');

    const rowDataCredit = rowData ? rowData.find(({ type }) => type === 'CREDIT') : {};
    const rowDataDebet = rowData ? rowData.find(({ type }) => type === 'DEBET') : {};

    const onClose = async () => {
        setAccountId(undefined);
        setAccountIdSecond(undefined);
        setAmount(undefined);
        setAmountSecond(undefined);
        setCounterparty(undefined);
        setCounterpartySecond(undefined);
        await closeModal();
    };

    const handleFinish = async values => {
        const submitValuesAdd = [
            {
                id: rowDataCredit ? rowDataCredit.id : undefined,
                accountId: accountIdSecond,
                counterpartType: counterpartTypeSecond,
                counterpartId: docData.counterpartId,
                amount: amountSecond,
                type: credit
            },
            {
                id: rowDataDebet ? rowDataDebet.id : undefined,
                accountId,
                counterpartType,
                counterpartId: docData.counterpartId,
                amount,
                type: debit
            }
        ];
        // const submitValuesEdit = {

        //     rows: [
        //         {
        //             id: rowData ? rowData.id : undefined,
        //             nextDate,
        //             comment,
        //             sum,
        //             subStatusId: subStatus,
        //             interactionTypeId: type
        //         }
        //     ]
        // };

        try {
            const response = await fetchAPI(
                'PUT',
                '/general_ledger/acc_document',
                null,
                {
                    id,
                    status: docData.status,
                    documentNumber: docData.documentNumber || undefined,
                    documentDatetime: docData.documentDatetime || null,
                    counterpartId: docData.counterpartId || undefined,
                    counterpartType: docData.counterpartType || undefined,
                    warehouseId: docData.warehouseId || undefined,
                    cashBoxId: docData.cashBoxId || undefined,
                    comment: docData.comment || undefined,
                    operationType: docData.operationType,
                    rows: submitValuesAdd
                },
                {
                    handleErrorInternally: true
                }
            );

            notification.success({ message: intl.formatMessage({ id: 'barcode.success' }) });
        } catch (e) {
            notification.error({ message: intl.formatMessage({ id: 'error' }) });
        }

        await onClose();
        await fetchDocData();
    };

    const setRowData = () => {
        if (rowData) {
            setAccountId(rowDataDebet.accountId);
            setAccountIdSecond(rowDataCredit.accountId);
            setCounterparty(rowDataDebet.counterpartType);
            setCounterpartySecond(rowDataCredit.counterpartType);
            setAmount(rowDataDebet.amount);
            setAmountSecond(rowDataCredit.amount);
        }
    };

    const filterTreeByActive = useCallback(nodes => {
        return nodes.reduce((filteredNodes, node) => {
            if (node.active === true) {
                filteredNodes.push({
                    value: node.id,
                    title: `(#${node.id}) ${node.name} `,
                    disabled: node.level < 4
                });

                if (node.children) {
                    const filteredChildren = filterTreeByActive(node.children);
                    if (filteredChildren.length > 0) {
                        filteredNodes[filteredNodes.length - 1].children = filteredChildren;
                    }
                }
            }

            setTree(filteredNodes);

            return filteredNodes;
        }, []);
    });

    useEffect(() => {
        if (!_.get(tree, 'length') && _.get(accounts, 'length')) {
            filterTreeByActive(accounts);
        }
    }, [tree, accounts]);

    useEffect(() => {
        const fetchAccounts = async query => {
            const accounts = await getAccountsList(query);
            setAccounts(accounts);
        };
        if (open === true) {
            fetchAccounts();
        }

        setRowData();
    }, [rowData, open]);

    return (
        <Modal
            okButtonProps={{
                disabled: !accountId || !accountIdSecond || !amount || !amountSecond
            }}
            onCancel={onClose}
            onOk={handleFinish}
            open={open}
            style={{ minWidth: 1024 }}
        >
            {/* <div className={Styles.formWrapper}> */}

            <Flex className={Styles.formRow} horizontal justify='space-between'>
                <div
                    style={{
                        minWidth: inputWidth
                    }}
                >
                    <div>
                        <FormattedMessage id='requisite-setting.account' />
                        <span className={Styles.rules}>*</span>
                    </div>
                    <TreeSelect
                        filterTreeNode={(input, node) => {
                            return (
                                node.props.title.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                String(node.props.value).indexOf(input.toLowerCase()) >= 0
                            );
                        }}
                        getPopupContainer={trigger => trigger.parentNode}
                        listHeight={440}
                        onSelect={(value, option) => {
                            setAccountIdSecond(value);
                        }}
                        placeholder={
                            <React.Fragment>
                                <FormattedMessage id='receipt_document_modal.bill' />
                            </React.Fragment>
                        }
                        showSearch
                        style={{ color: 'var(--text)', width: 280 }}
                        treeData={tree}
                        treeNodeFilterProp={(input, node) => {
                            return node.active;
                        }}
                        value={accountIdSecond}
                    />
                </div>
                <div
                    style={{
                        minWidth: inputWidth
                    }}
                >
                    <div>
                        <FormattedMessage id='interactions.counterparty_type' />:
                    </div>
                    <Select
                        onChange={value => {
                            setCounterpartySecond(value);
                            if (value === 'CLIENTS' || value === 'EMPLOYEES' || value === 'SUPPLIERS') {
                                if (docData.counterpartAccountId) {
                                    setAccountIdSecond(docData.counterpartAccountId);
                                }
                            } else if (value === 'STOCKS') {
                                if (docData.warehouseAccountId) {
                                    setAccountIdSecond(docData.warehouseAccountId);
                                }
                            } else if (value === 'CASHBOXES') {
                                if (docData.cashBoxAccountId) {
                                    setAccountIdSecond(docData.cashBoxAccountId);
                                }
                            }
                        }}
                        placeholder={intl.formatMessage({
                            id: 'interactions.counterparty_type'
                        })}
                        style={{ width: '100%' }}
                        value={counterpartTypeSecond}
                    >
                        {Object.entries(counterpartyTypeMap).map(([key, value]) => (
                            <Option key={key} value={key}>
                                <FormattedMessage id={value} />
                            </Option>
                        ))}
                    </Select>
                </div>

                <div
                    style={{
                        minWidth: inputWidth
                    }}
                >
                    <div>
                        <FormattedMessage id='cash-table.type' />:
                    </div>
                    <Select
                        disabled
                        placeholder={intl.formatMessage({
                            id: 'interactions.counterparty_type'
                        })}
                        style={{ width: '100%' }}
                        value={credit}
                    >
                        <Option value='CREDIT'>
                            <FormattedMessage id='entries_tab.sign.-' />
                        </Option>
                    </Select>
                </div>

                <div
                    style={{
                        minWidth: inputWidth
                    }}
                >
                    <div>
                        <FormattedMessage id='cash-table.sum' />
                        <span className={Styles.rules}>*</span>
                    </div>
                    <InputNumber
                        decimalSeparator=','
                        onChange={value => {
                            if (docData.operationType === 'DIRECT') {
                                setAmountSecond(value);
                                setAmount(-value);
                            } else {
                                setAmount(Math.abs(value));
                                setAmountSecond(Math.abs(value) * -1);
                            }
                        }}
                        placeholder={intl.formatMessage({
                            id: 'cash-table.sum'
                        })}
                        style={{ width: '100%' }}
                        value={amountSecond}
                    />
                </div>
            </Flex>
            <Flex className={Styles.formRow} horizontal justify='space-between'>
                <div
                    style={{
                        minWidth: inputWidth
                    }}
                >
                    <div>
                        <FormattedMessage id='requisite-setting.account' />
                        <span className={Styles.rules}>*</span>
                    </div>
                    <TreeSelect
                        filterTreeNode={(input, node) => {
                            return (
                                node.props.title.toLowerCase().indexOf(input.toLowerCase()) >= 0 ||
                                String(node.props.value).indexOf(input.toLowerCase()) >= 0
                            );
                        }}
                        getPopupContainer={trigger => trigger.parentNode}
                        listHeight={440}
                        onSelect={(value, option) => {
                            setAccountId(value);
                        }}
                        placeholder={
                            <React.Fragment>
                                <FormattedMessage id='receipt_document_modal.bill' />
                            </React.Fragment>
                        }
                        showSearch
                        style={{ color: 'var(--text)', width: 280 }}
                        treeData={tree}
                        treeNodeFilterProp={(input, node) => {
                            return node.active;
                        }}
                        value={accountId}
                    />
                </div>
                <div
                    style={{
                        minWidth: inputWidth
                    }}
                >
                    <div>
                        <FormattedMessage id='interactions.counterparty_type' />:
                    </div>
                    <Select
                        onChange={value => {
                            setCounterparty(value);
                            if (value === 'CLIENTS' || value === 'EMPLOYEES' || value === 'SUPPLIERS') {
                                if (docData.counterpartAccountId) {
                                    setAccountId(docData.counterpartAccountId);
                                }
                            } else if (value === 'STOCKS') {
                                if (docData.warehouseAccountId) {
                                    setAccountId(docData.warehouseAccountId);
                                }
                            } else if (value === 'CASHBOXES') {
                                if (docData.cashBoxAccountId) {
                                    setAccountId(docData.cashBoxAccountId);
                                }
                            }
                        }}
                        placeholder={intl.formatMessage({
                            id: 'interactions.counterparty_type'
                        })}
                        style={{ width: '100%' }}
                        value={counterpartType}
                    >
                        {Object.entries(counterpartyTypeMap).map(([key, value]) => (
                            <Option key={key} value={key}>
                                <FormattedMessage id={value} />
                            </Option>
                        ))}
                    </Select>
                </div>

                <div
                    style={{
                        minWidth: inputWidth
                    }}
                >
                    <div>
                        <FormattedMessage id='cash-table.type' />:
                    </div>
                    <Select
                        disabled
                        placeholder={intl.formatMessage({
                            id: 'interactions.counterparty_type'
                        })}
                        style={{ width: '100%' }}
                        value={debit}
                    >
                        <Option value='DEBET'>
                            <FormattedMessage id='entries_tab.sign.+' />
                        </Option>
                    </Select>
                </div>

                <div
                    style={{
                        minWidth: inputWidth
                    }}
                >
                    <div>
                        <FormattedMessage id='cash-table.sum' />
                        <span className={Styles.rules}>*</span>
                    </div>
                    <InputNumber
                        decimalSeparator=','
                        disabled
                        onChange={value => {}}
                        placeholder={intl.formatMessage({
                            id: 'cash-table.sum'
                        })}
                        style={{ width: '100%' }}
                        value={amount}
                    />
                </div>
            </Flex>
        </Modal>
    );
};

export default injectIntl(AddAccRowModal);
