import { Col, Modal, Radio, Row, Select, Switch } from 'antd';
import { DateRangePicker } from 'components';
import { fetchClients, setClientsSearchFilter } from 'core/clients/duck';
import { MODALS, resetModal, selectModal } from 'core/modals/duck';
import _ from 'lodash';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import {
    detailingValues,
    downloadReport,
    fetchBusinessRequisites,
    resetReportFilters,
    selectActButtonDisabled,
    selectBusinessRequisites,
    selectClientRequisites,
    selectReportFilters,
    setActButtonDisabled,
    setClientRequisites,
    setReportFilters
} from './redux/duck';
import Styles from './styles.m.css';

const { Option } = Select;

const message_prefix = 'accounts_receivable_report.';

const mapStateToProps = state => ({
    visible: selectModal(state),
    filters: selectReportFilters(state),
    clients: state.clients.clients,
    clientRequisites: selectClientRequisites(state),
    businessRequisites: selectBusinessRequisites(state),
    actButtonDisabled: selectActButtonDisabled(state)
});

const mapDispatchToProps = {
    resetModal,
    downloadReport,
    setReportFilters,
    resetReportFilters,
    setClientRequisites,
    fetchBusinessRequisites,
    setActButtonDisabled,
    fetchClients,
    setClientsSearchFilter
};

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class AccountsReceivableReportModal extends Component {
    constructor(props) {
        super(props);

        this.handleSearchClients = _.debounce(value => {
            const { setClientsSearchFilter, fetchClients } = this.props;
            setClientsSearchFilter(value);
            fetchClients();
        }, 500).bind(this);
    }

    componentDidMount() {
        this.props.fetchClients();
        this.props.fetchBusinessRequisites();
    }

    render() {
        const {
            visible,
            filters,
            clients,
            clientRequisites,
            businessRequisites,
            actButtonDisabled,

            resetModal,
            downloadReport,
            setReportFilters,
            resetReportFilters,
            setClientRequisites,
            setActButtonDisabled
        } = this.props;

        return (
            <div>
                <Modal
                    cancelText={<FormattedMessage id='stock_table.button.cancel' />}
                    destroyOnClose
                    okText={<FormattedMessage id='navigation.print' />}
                    onCancel={() => {
                        resetModal();
                        resetReportFilters();
                    }}
                    onOk={() => {
                        downloadReport();
                        resetModal();
                        resetReportFilters();
                    }}
                    title={
                        <div className={Styles.title}>
                            <FormattedMessage id={`${message_prefix}accounts_receivable_report`} />
                        </div>
                    }
                    visible={visible === MODALS.ACCOUNTS_RECEIVABLE_REPORT_MODAL}
                    width='50vw'
                >
                    <Row className={Styles.row}>
                        <Col className={Styles.boldCol} span={8}>
                            <FormattedMessage id={`${message_prefix}client`} />
                        </Col>
                        <Col span={16}>
                            <Select
                                allowClear
                                dropdownStyle={{ zIndex: '9999' }}
                                filterOption={false}
                                onChange={(value, option) => {
                                    setReportFilters({ clientId: value });
                                    setClientRequisites(clients[option.index].requisites);
                                    setActButtonDisabled(!value);
                                }}
                                onSearch={this.handleSearchClients}
                                placeholder={<FormattedMessage id={`${message_prefix}client`} />}
                                showSearch
                            >
                                {clients.map((client, index) => (
                                    <Option key={client.clientId} index={index} value={client.clientId}>
                                        {`${client.surname || ''} ${client.name} ${client.middleName || ''} ${_.get(
                                            client,
                                            'phones[0]',
                                            ''
                                        )}`}
                                    </Option>
                                ))}
                            </Select>
                        </Col>
                    </Row>

                    <Row className={Styles.row}>
                        <Col className={Styles.boldCol} span={8}>
                            <FormattedMessage id={`${message_prefix}client_requisite`} />
                        </Col>
                        <Col span={16}>
                            <Select
                                allowClear
                                dropdownStyle={{ zIndex: '9999' }}
                                filterOption={(input, option) => {
                                    return (
                                        option.props.children &&
                                        String(option.props.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    );
                                }}
                                onChange={value => {
                                    setReportFilters({ clientRequisiteId: value });
                                }}
                                placeholder={<FormattedMessage id={`${message_prefix}client_requisite`} />}
                                showSearch
                            >
                                {clientRequisites.map(elem => (
                                    <Option key={elem.id} value={elem.id}>
                                        {elem.name}
                                    </Option>
                                ))}
                            </Select>
                        </Col>
                    </Row>

                    <Row className={Styles.row}>
                        <Col className={Styles.boldCol} span={8}>
                            <FormattedMessage id='storage.business_requisites' />
                        </Col>
                        <Col span={16}>
                            <Select
                                allowClear
                                dropdownStyle={{ zIndex: '9999' }}
                                filterOption={(input, option) => {
                                    return (
                                        option.props.children &&
                                        String(option.props.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    );
                                }}
                                onChange={businessRequisiteId => {
                                    setReportFilters({ businessRequisiteId });
                                }}
                                placeholder={<FormattedMessage id='storage.business_requisites' />}
                                showSearch
                            >
                                {businessRequisites.map(elem => (
                                    <Option key={elem.id} value={elem.id}>
                                        {elem.name}
                                    </Option>
                                ))}
                            </Select>
                        </Col>
                    </Row>

                    <Row className={Styles.rowData}>
                        <Col className={Styles.boldCol} span={4}>
                            <FormattedMessage id='calls-table.date' />
                        </Col>
                        <Col offset={4} span={16}>
                            <DateRangePicker
                                dateRange={[_.get(filters, 'date.from'), _.get(filters, 'date.to')]}
                                maximize
                                onDateChange={daterange => {
                                    const [from, to] = daterange;
                                    setReportFilters({ date: { from, to } });
                                }}
                                overlayStyle={{ zIndex: '5000' }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col className={Styles.boldCol} span={4}>
                            <FormattedMessage id={`${message_prefix}detailing`} />
                        </Col>
                        <Col offset={4} span={8}>
                            <Radio.Group
                                className={Styles.radioButtons}
                                onChange={e => setReportFilters({ detailing: e.target.value })}
                                value={filters.detailing}
                            >
                                <div>
                                    <Radio value={detailingValues.withoutDetainling}>
                                        <FormattedMessage id={`${message_prefix}without_detailing`} />
                                    </Radio>

                                    <Radio value={detailingValues.docsDetailing}>
                                        <FormattedMessage id={`${message_prefix}docs_detailing`} />
                                    </Radio>

                                    <Radio disabled={actButtonDisabled} value={detailingValues.actDetailing}>
                                        <FormattedMessage id={`${message_prefix}act_detailing`} />
                                    </Radio>
                                </div>
                            </Radio.Group>
                        </Col>
                    </Row>
                    <Row
                        style={{
                            marginTop: 4
                        }}
                    >
                        <Col className={Styles.boldCol} span={4} style={{ fontWeight: 'bold' }}>
                            <FormattedMessage id='do_not_show_the_dispersal' />
                        </Col>
                        <Col offset={4} span={8}>
                            <Switch
                                checked={filters.withoutReturning}
                                onChange={value => setReportFilters({ withoutReturning: value })}
                                value={filters.withoutReturning}
                            />
                        </Col>
                    </Row>
                    <Row
                        style={{
                            marginTop: 4
                        }}
                    >
                        <Col className={Styles.boldCol} span={4} style={{ fontWeight: 'bold' }}>
                            <FormattedMessage id='date' />
                        </Col>
                        <Col offset={4} span={8}>
                            <Radio.Group
                                className={Styles.radioButtons}
                                onChange={e => setReportFilters({ filterByDeliveryDate: e.target.value })}
                                value={filters.filterByDeliveryDate || false}
                            >
                                <div>
                                    <Radio value={false}>
                                        <FormattedMessage id='of_success' />
                                    </Radio>

                                    <Radio value>
                                        <FormattedMessage id='of_delivery' />
                                    </Radio>
                                </div>
                            </Radio.Group>
                        </Col>
                    </Row>
                </Modal>
            </div>
        );
    }
}
