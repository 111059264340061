import { Flex, Modal, notification, Table } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { fetchAPI } from 'utils';

const ChassisCalculationModal = ({
    open,
    unitsInOrder = [],
    onCancel,
    orderId,
    selectedDiagnosticSchemeId,
    orderUnits = [],
    setUnitsChange,
    reloadOrderForm,
    intl: { formatMessage }
}) => {
    const [selectedRowKeys, setSelectedRowKeys] = useState({ labors: [], storeGroups: [] });
    const [selectedRows, setSelectedRows] = useState({ labors: [], storeGroups: [] });
    const labors = useMemo(() => unitsInOrder.map(({ labors }) => labors).flat(), [unitsInOrder]);
    const storeGroups = useMemo(() => unitsInOrder.map(({ storeGroups }) => storeGroups).flat(), [unitsInOrder]);

    useEffect(() => {
        if (!open) return;
        setSelectedRowKeys({
            labors: labors.map(record => `${record.laborId}.${record.unitId}`),
            storeGroups: storeGroups.map(record => `${record.storeGroupId}.${record.unitId}`)
        });
        setSelectedRows({ labors, storeGroups });
    }, [labors, open, storeGroups]);

    const serviceColumns = useMemo(
        () => [
            {
                title: <FormattedMessage id='status_requests.LABOR' />,
                render: record => (
                    <Flex vertical>
                        <div>{record.laborName}</div>
                        <div style={{ fontWeight: 500, fontSize: 12 }}>{record.laborId}</div>
                    </Flex>
                )
            }
        ],
        []
    );
    const detailColumns = useMemo(
        () => [
            {
                title: <FormattedMessage id='add_order_form.details' />,
                render: record => (
                    <Flex vertical>
                        <div>{record.storeGroupName}</div>
                        <div style={{ fontWeight: 500, fontSize: 12 }}>{record.storeGroupId}</div>
                    </Flex>
                )
            }
        ],
        []
    );

    const rowSelection = useCallback(
        type => ({
            selectedRowKeys: selectedRowKeys[type],
            onChange: (keys, rows) => {
                setSelectedRowKeys(prev => ({ ...prev, [type]: keys }));
                setSelectedRows(prev => ({ ...prev, [type]: rows }));
            }
        }),
        [selectedRowKeys]
    );

    const handleAddToOrder = useCallback(async () => {
        const fetch = async () => {
            const payload = {
                insertMode: true,
                services: selectedRows.labors.map(
                    ({ laborId, laborName, laborHour, laborPrice, measureUnitId, comment }) => ({
                        serviceId: laborId,
                        serviceName: `${laborName} - ${comment.comment}` || laborName,
                        count: laborHour,
                        servicePrice: laborPrice,
                        laborUnitId: measureUnitId,
                        comment
                    })
                ),
                details: selectedRows.storeGroups.map(({ storeGroupId, storeGroupName, measureUnitId, comment }) => ({
                    storeGroupId,
                    name: `${storeGroupName} - ${comment.comment}` || storeGroupName,
                    partUnitId: measureUnitId,
                    comment
                }))
            };

            if (!payload.services.length) {
                delete payload.services;
            }
            if (!payload.details.length) {
                delete payload.details;
            }

            await fetchAPI('PUT', `orders/${orderId}`, null, payload);
            await fetchAPI(
                'PUT',
                '/order_scheme_diagnostics',
                null,
                {
                    schemes: orderUnits.map(unit => ({
                        id: unit.id,
                        status: unit.status,
                        blocked: true,
                        orderId,
                        schemeId: selectedDiagnosticSchemeId,
                        unitId: unit.unitId || unit.id,
                        comment: unit.comment
                    }))
                },
                {
                    handleErrorInternally: true
                }
            );
            setUnitsChange(true);
            notification.success({
                message: formatMessage({
                    id: 'barcode.success'
                })
            });
        };
        await fetch();
        onCancel();
        reloadOrderForm();
    }, [
        formatMessage,
        onCancel,
        orderId,
        orderUnits,
        reloadOrderForm,
        selectedDiagnosticSchemeId,
        selectedRows.labors,
        selectedRows.storeGroups,
        setUnitsChange
    ]);

    useEffect(() => {
        setSelectedRowKeys({ labors: [], storeGroups: [] });
        setSelectedRows({ labors: [], storeGroups: [] });
    }, [unitsInOrder]);

    return (
        <Modal
            okButtonProps={{
                disabled: !selectedRowKeys.labors.length && !selectedRowKeys.storeGroups.length
            }}
            onCancel={onCancel}
            onOk={handleAddToOrder}
            open={open}
            title={formatMessage({ id: 'barcode.add_to_order' })}
            width={768}
        >
            <Flex gap={16}>
                <Table
                    bordered
                    columns={serviceColumns}
                    dataSource={labors}
                    pagination={false}
                    rowKey={record => `${record.laborId}.${record.unitId}`}
                    rowSelection={rowSelection('labors')}
                    size='small'
                    style={{
                        width: '50%'
                    }}
                />
                <Table
                    bordered
                    columns={detailColumns}
                    dataSource={storeGroups}
                    pagination={false}
                    rowKey={record => `${record.storeGroupId}.${record.unitId}`}
                    rowSelection={rowSelection('storeGroups')}
                    size='small'
                    style={{
                        width: '50%'
                    }}
                />
            </Flex>
        </Modal>
    );
};

export default injectIntl(ChassisCalculationModal);
