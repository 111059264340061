import { Button, Modal, notification, Popconfirm, Select, Table } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';

import { DeleteOutlined } from '@ant-design/icons';
import { get, isEmpty, uniqBy } from 'lodash';
import { FormattedMessage, injectIntl } from 'react-intl';
import { fetchAPI } from 'utils';
import { grants, isGrantAccessed } from 'utils/grants';
import Styles from './styles.m.css';

const { Option } = Select;

const detalizationDisplayControl = {
    LOW: {
        bgColor: '#44982ba6',
        labelId: 'order_form_table.diagnostic.detalization.low'
    },
    MEDIUM: {
        bgColor: '#fdc50070',
        labelId: 'order_form_table.diagnostic.detalization.medium'
    },
    HIGH: {
        bgColor: '#e72c1c78',
        labelId: 'order_form_table.diagnostic.detalization.high'
    }
};

const detalizationOptions = ['LOW', 'MEDIUM', 'HIGH'];

const DiagnosticConfigureModal = ({
    open,
    handleClose,
    orderId,
    orderDiagnostics = [],
    orderImgVehicleType,
    intl,
    user
}) => {
    const [systems, setSystems] = useState([]);
    const [defaultTemplates, setDefaultTemplates] = useState([]);
    const [currentDiagnostic, setCurrentDiagnostic] = useState([]);

    const [params, setParams] = useState({});
    const [relatedParams, setRelatedParams] = useState({});

    useEffect(() => {
        setCurrentDiagnostic(uniqBy(orderDiagnostics, 'systemId').map(el => ({ ...el, current: true })));
    }, [orderDiagnostics]);

    const fetchDefaultTemplates = async () => {
        const data = await fetchAPI(
            'GET',
            'default_diagnostics',
            { type: orderImgVehicleType || 'PC' },
            {},
            { handleErrorInternally: true }
        );

        setDefaultTemplates(data);
    };

    const getDiagnosticsByParams = async () => {
        try {
            const formattedParams = {
                templateId: params.template.templateId,
                groupIds: params.groups.map(group => group.groupId),
                systemIds: params.systems.map(system => system.systemId),
                detalization: params.detalization
            };
            const { insert } = await fetchAPI(
                'POST',
                'order_diagnostic',
                null,
                {
                    ...formattedParams,
                    orderId,
                    isList: true,
                    isUpdate: false
                },
                { handleErrorInternally: true }
            );
            setSystems(insert);
        } catch (err) {
            notification.error({ message: err.response.message });
        }
    };

    const deleteDiagnosticData = async ({ templateId, groupId, systemId }) => {
        await fetchAPI(
            'DELETE',
            'order_diagnostic',
            null,
            {
                orderId,
                templateId,
                groupIds: groupId ? [groupId] : undefined,
                systemIds: systemId ? [systemId] : undefined
            },
            { handleErrorInternally: true }
        );
    };

    const postDiagnosticsByParams = async () => {
        try {
            const formattedSystems = [...currentDiagnostic, ...systems].map(
                ({ templateId, groupId, systemId, systemDetalization }) => {
                    return {
                        templateId,
                        groupId,
                        systemId,
                        systemDetalization
                    };
                }
            );
            await fetchAPI(
                'POST',
                'order_diagnostic',
                null,
                {
                    rows: [...formattedSystems],
                    orderId
                },
                { handleErrorInternally: true }
            );
            notification.success({ message: <FormattedMessage id='diagnostics_successfully_added'/> });
            setSystems([]);
            setParams({});
            handleClose();
        } catch (err) {
            notification.error({ message: err.response.message });
        }
    };

    const handleTemplateIdChange = (newTemplate, { template }) => {
        const relatedTemplates = defaultTemplates.filter(({ templateId }) => templateId === newTemplate);

        const groups = uniqBy(relatedTemplates, 'groupId');
        const systems = uniqBy(relatedTemplates, 'systemId');
        const { systemDetalization } = relatedTemplates[0];

        setRelatedParams({ template, systems, groups, detalization: systemDetalization });
        setParams({ template, systems, groups, detalization: systemDetalization });
    };

    const handleGroupIdsChange = (_, options) => {
        const newGroups = options.map(({ group }) => group);
        let newSystems = [];
        if (params.systems) {
            if (newGroups.length < params.groups.length) {
                const deletedGroupId = params.groups.filter(
                    paramGroup => !newGroups.map(({ groupId }) => groupId).includes(paramGroup.groupId)
                )[0].groupId;
                newSystems = params.systems.filter(({ groupId }) => deletedGroupId !== groupId);
            } else {
                const addedGroup = newGroups[newGroups.length - 1].groupId;
                newSystems = params.systems.concat(
                    relatedParams.systems.filter(({ groupId }) => addedGroup === groupId)
                );
            }
        } else newSystems = params.systems;

        setParams({ ...params, groups: newGroups, systems: newSystems });
    };

    const handleSystemIdsChange = (_, options) => {
        const newSystems = options.map(({ system }) => system);
        const newSystemGroupIds = newSystems.map(({ groupId }) => groupId);
        const newGroups = params.groups.filter(group => newSystemGroupIds.includes(group.groupId));
        setParams({ ...params, systems: newSystems, groups: newGroups });
    };

    useEffect(() => {
        fetchDefaultTemplates();
    }, []);

    const systemColumns = useMemo(
        () => [
            {
                title: <FormattedMessage id='order_form_table.diagnostic.diagnostics' />,
                key: 'templateName',
                dataIndex: 'templateName'
            },
            {
                title: <FormattedMessage id='order_form_table.diagnostic.stage' />,
                key: 'groupName',
                dataIndex: 'groupName'
            },
            {
                title: <FormattedMessage id='order_form_table.diagnostic.element_title' />,
                key: 'systemName',
                dataIndex: 'systemName'
            },
            {
                title: <FormattedMessage id='order_form_table.diagnostic.detalization' />,
                key: 'systemDetalization',
                render: row => {
                    return (
                        <Select
                            onChange={systemDetalization => {
                                if (row.current) {
                                    setCurrentDiagnostic(
                                        currentDiagnostic.map(el => {
                                            if (row.systemId === el.systemId) {
                                                return {
                                                    ...el,
                                                    systemDetalization
                                                };
                                            }

                                            return el;
                                        })
                                    );
                                }

                                setSystems(
                                    systems.map(el => {
                                        if (row.systemId === el.systemId) {
                                            return {
                                                ...el,
                                                systemDetalization
                                            };
                                        }

                                        return el;
                                    })
                                );
                            }}
                            placeholder='System'
                            value={row.systemDetalization}
                        >
                            {detalizationOptions.map(option => (
                                <Option key={option} value={option}>
                                    <FormattedMessage id={detalizationDisplayControl[option].labelId} />
                                </Option>
                            ))}
                        </Select>
                    );
                },
                onCell: row => {
                    const { bgColor } = detalizationDisplayControl[row.systemDetalization];

                    return { style: { borderBottom: `2px solid ${bgColor}`, boxSizing: 'border-box' } };
                }
            },
            {
                title: <FormattedMessage id='order_form_table.diagnostic.positions' />,
                key: 'quantity',
                render: row => row.attributes[row.systemDetalization].quantity
            },
            {
                title: <FormattedMessage id='order_form_table.diagnostic.duration' />,
                key: 'partDuration',
                render: row => Number(row.attributes[row.systemDetalization].partDuration.toFixed(2))
            },
            {
                title: <FormattedMessage id='order_form_table.actions' />,
                render: row => (
                    <Popconfirm
                        cancelText={intl.formatMessage({ id: 'no' })}
                        description={intl.formatMessage({
                            id: 'order_form_table.diagnostic.delete_confirm_description'
                        })}
                        okText={intl.formatMessage({ id: 'yes' })}
                        onConfirm={() => {
                            if (row.current) {
                                deleteDiagnosticData(row);
                                setCurrentDiagnostic(
                                    currentDiagnostic.filter(system => system.systemId !== row.systemId)
                                );

                                return;
                            }

                            setSystems(systems.filter(system => system.systemId !== row.systemId));
                        }}
                        placement='top'
                        title={intl.formatMessage({ id: 'order_form_table.diagnostic.delete_confirm_title' })}
                    >
                        <Button icon={<DeleteOutlined style={{ fontSize: 12 }} />} type='text' />
                    </Popconfirm>
                ),
                onCell: () => {
                    return { className: Styles.deleteColumn };
                }
            }
        ],

        [currentDiagnostic, systems]
    );

    return (
        <Modal
            destroyOnClose
            disabledButton
            okButtonProps={!systems.length && !currentDiagnostic.length && { disabled: true }}
            onCancel={handleClose}
            onOk={postDiagnosticsByParams}
            open={open}
            title={<FormattedMessage id='order_form_table.diagnostic.add_diagnostic' />}
            width='80%'
        >
            <div className={Styles.content}>
                <div className={Styles.actionsContainer}>
                    <Select
                        className={Styles.diagnosticsFilter}
                        onChange={handleTemplateIdChange}
                        placeholder={intl.formatMessage({ id: 'order_form_table.diagnostic.diagnostics' })}
                        value={get(params, 'template.templateId')}
                    >
                        {uniqBy(defaultTemplates, 'templateId')
                            .reverse()
                            .map(template => (
                                <Option key={template.templateId} template={template} value={template.templateId}>
                                    {template.templateName}
                                </Option>
                            ))}
                    </Select>
                    <div className={Styles.filterSelect}>
                        <Select
                            // className={Styles.filterSelect}
                            disabled={!params.template}
                            mode='multiple'
                            onChange={handleGroupIdsChange}
                            placeholder={intl.formatMessage({ id: 'order_form_table.diagnostic.stage' })}
                            style={{ width: '40%' }}
                            value={params.groups && params.groups.map(group => group.groupId)}
                        >
                            {get(relatedParams, 'groups', []).map(group => (
                                <Option key={group.groupId} group={group} value={group.groupId}>
                                    {group.groupName}
                                </Option>
                            ))}
                        </Select>
                        <Select
                            // className={Styles.filterSelect}
                            disabled={isEmpty(params.groups)}
                            mode='multiple'
                            onChange={handleSystemIdsChange}
                            placeholder={intl.formatMessage({ id: 'order_form_table.diagnostic.system' })}
                            style={{ width: '60%' }}
                            value={params.systems && params.systems.map(system => system.systemId)}
                        >
                            {uniqBy(
                                defaultTemplates.filter(
                                    ({ templateId, groupId }) =>
                                        templateId === get(params, 'template.templateId') &&
                                        get(params, 'groups', [])
                                            .map(({ groupId }) => groupId)
                                            .includes(groupId)
                                ),
                                'systemId'
                            ).map(system => (
                                <Option key={system.systemId} system={system} value={system.systemId}>
                                    {system.systemName}
                                </Option>
                            ))}
                        </Select>
                    </div>
                    <Select
                        dropdownMatchSelectWidth={false}
                        onChange={detalization => setParams({ ...params, detalization })}
                        placeholder={intl.formatMessage({ id: 'order_form_table.diagnostic.detalization' })}
                        value={params.detalization}
                    >
                        {detalizationOptions.map(systemDetalization => (
                            <Option key={systemDetalization} value={systemDetalization}>
                                <FormattedMessage id={detalizationDisplayControl[systemDetalization].labelId} />
                            </Option>
                        ))}
                    </Select>
                    {/* <Button
                        disabled={Object.keys(params).length !== 4}
                        icon={<DownloadOutlined style={{ fontSize: 12 }} />}
                        onClick={getDiagnosticsByParams}
                        type='text'
                    /> */}
                    <Button
                        disabled={
                            Object.keys(params).length !== 4 ||
                            !get(params, 'systems.length') ||
                            !isGrantAccessed(user, grants.OPERATIONS_ORDER_DOCUMENT_DIAGNOSTICS_DIAGNOSTICS_CREATION)
                        }
                        onClick={getDiagnosticsByParams}
                        type='primary'
                    >
                        <FormattedMessage id='order_form_table.diagnostic.add_diagnostic' />
                    </Button>
                </div>
                <Table
                    bordered
                    className={Styles.table}
                    columns={systemColumns}
                    dataSource={[...currentDiagnostic, ...systems]}
                    pagination={false}
                    size='small'
                    summary={data => {
                        let totalQuantity = 0;
                        let totalTime = 0;
                        data.forEach(row => {
                            const attributes = get(row, `attributes[${row.systemDetalization}]`, {});
                            totalQuantity += attributes.quantity;
                            totalTime += attributes.partDuration;
                        });

                        const summaryRow = !data.length ? (
                            false
                        ) : (
                            <React.Fragment>
                                <Table.Summary.Row className={Styles.summaryRow}>
                                    <Table.Summary.Cell index={0}>
                                        <FormattedMessage id='order_form_table.diagnostic.total' />
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell index={1}>
                                        {
                                            [...uniqBy(currentDiagnostic, 'groupId'), ...uniqBy(systems, 'groupId')]
                                                .length
                                        }
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell index={2}>
                                        {
                                            [...uniqBy(currentDiagnostic, 'systemId'), ...uniqBy(systems, 'systemId')]
                                                .length
                                        }
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell index={3}></Table.Summary.Cell>
                                    <Table.Summary.Cell index={4}>{totalQuantity}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={5}>{Number(totalTime.toFixed(2))}</Table.Summary.Cell>
                                    <Table.Summary.Cell index={6}></Table.Summary.Cell>
                                </Table.Summary.Row>
                            </React.Fragment>
                        );

                        return summaryRow;
                    }}
                />
            </div>
        </Modal>
    );
};

export default injectIntl(DiagnosticConfigureModal);
