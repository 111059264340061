import { PlusOutlined } from '@ant-design/icons';
import { Button, Input, Radio } from 'antd';
import { Layout } from 'commons';
import _ from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { fetchAPI } from 'utils';
import AccDocsTable from './components/AccDocsTable';
import AddAccDocsModal from './components/AddAccDocsModal';

const mapStateToProps = state => ({
    user: state.auth
});

const mapDispatchToProps = {};

const ACCDocumentsPage = connect(
    mapStateToProps,
    mapDispatchToProps
)(({ intl, user }) => {
    const [query, setQuery] = useState('');
    const [shouldReset, setShouldReset] = useState();
    const [open, setOpen] = useState(false);
    const [docs, setDocs] = useState([]);
    const [loading, setLoading] = useState(false);
    const [filters, setFilters] = useState({});
    const [searchStatus, setSearchStatus] = useState(undefined);
    const history = useHistory();

    const reset = useCallback(() => setShouldReset(null), []);
    const startReset = () => setShouldReset('reset');

    const fetchAccDocs = useCallback(
        async (query, st, filt = filters) => {
            setLoading(true);
            const response = await fetchAPI(
                'GET',
                '/general_ledger/acc_documents',
                { query, status: st, ...filt },
                null,
                {
                    handleErrorInternally: true
                }
            );

            setDocs(response);
            setLoading(false);
        },
        [filters]
    );

    const debounceFetcher = useMemo(() => {
        const loadOptions = value => {
            fetchAccDocs(value);
        };

        return _.debounce(loadOptions, 1000);
    }, []);

    return (
        <Layout
            className='interactions_page'
            // description={<FormattedMessage id='navigation.relationships' />}
            controls={<Button icon={<PlusOutlined />} onClick={() => setOpen(true)} />}
            title={<FormattedMessage id='navigation.acc_docs' />}
        >
            <div>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginBottom: 12
                    }}
                >
                    <Input
                        allowClear
                        onChange={event => {
                            setQuery(event.target.value);
                            debounceFetcher(event.target.value);
                        }}
                        // mode='multiple'
                        placeholder={intl.formatMessage({
                            id: 'acc_page.search_by'
                        })}
                        style={{ marginBottom: 8, width: '50%' }}
                        value={query}
                    />

                    <div>
                        <Radio.Group
                            buttonStyle='solid'
                            onChange={event => {
                                setSearchStatus(event.target.value);
                                fetchAccDocs(undefined, event.target.value);
                            }}
                            optionType='button'
                        >
                            <Radio value={undefined}>
                                <FormattedMessage id='report-orders-table.reset' />
                            </Radio>
                            <Radio value='NEW'>
                                <FormattedMessage id='storage_document.status_created' />
                            </Radio>
                            <Radio value='DONE'>
                                <FormattedMessage id='storage_document.status_confirmed' />
                            </Radio>
                        </Radio.Group>
                    </div>
                </div>
            </div>
            <AccDocsTable
                docs={docs}
                fetchAccDocs={fetchAccDocs}
                filters={filters}
                query={query}
                reset={reset}
                responsibleFilter={user.id}
                setFilters={setFilters}
                shouldReset={shouldReset}
            />
            <AddAccDocsModal
                fetchAccDocs={fetchAccDocs}
                hideModal={() => setOpen(false)}
                loading={loading}
                open={open}
            />
        </Layout>
    );
});

export default injectIntl(ACCDocumentsPage);
