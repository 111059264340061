import { Modal, Radio, Select } from 'antd';
import { DateRangePicker } from 'components';
import { fetchCashboxes } from 'core/cash/duck';
import { fetchEmployees, setEmployeesStatus } from 'core/employees/duck';
import { MODALS, resetModal, selectModal, selectModalProps } from 'core/modals/duck';
import { fetchReportAnalytics } from 'core/reports/reportAnalytics/duck';
import dayjs from 'dayjs';
import { saveAs } from 'file-saver';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { fetchAPI } from 'utils';

const { Option } = Select;

const mapStateToProps = state => ({
    modalProps: selectModalProps(state),
    visible: selectModal(state),
    user: state.auth,
    cashboxes: state.cash.cashboxes,
    analytics: state.reportAnalytics.analytics,
    employees: state.employees.employees,
    status: state.employees.status
});

const mapDispatchToProps = {
    resetModal,
    fetchCashboxes,
    fetchReportAnalytics,
    setEmployeesStatus,
    fetchEmployees
};

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class CashBoxReportModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            date: dayjs(),
            fromDate: dayjs().startOf('month'),
            toDate: dayjs().endOf('month'),
            detailing: 'without'
        };
    }

    handleOk = async () => {
        const { resetModal } = this.props;
        const { cashBoxId, fromDate, toDate, analyticsId, employeeId, detailing } = this.state;

        const response = await fetchAPI(
            'GET',
            'report/balance/excel/byCashBox',
            {
                cashBoxId,
                analyticsId,
                createdFromDate: dayjs(fromDate).format('YYYY-MM-DD'),
                createdToDate: dayjs(toDate).format('YYYY-MM-DD'),
                cashierId: employeeId,
                detailing
            },
            null,
            { rawResponse: true }
        );

        const reportFile = await response.blob();

        const contentDispositionHeader = response.headers.get('content-disposition');
        const fileName = contentDispositionHeader.match(/^attachment; filename="(.*)"/)[1];
        await saveAs(reportFile, fileName);
        this.handleCancel();
    };

    componentDidMount() {
        this.props.fetchCashboxes();
        this.props.fetchReportAnalytics();
        this.props.fetchEmployees();
    }

    handleCancel = () => {
        this.setState({
            cashBoxId: undefined,
            analyticsId: undefined,
            employeeId: undefined
        });
        this.props.resetModal();
    };

    render() {
        const { resetModal, visible, cashboxes, analytics, employees } = this.props;

        const { cashBoxId, fromDate, toDate, analyticsId, employeeId, detailing } = this.state;

        return (
            <div>
                <Modal
                    cancelText={<FormattedMessage id='stock_table.button.cancel' />}
                    destroyOnClose
                    okText={<FormattedMessage id='navigation.print' />}
                    onCancel={this.handleCancel}
                    onOk={this.handleOk}
                    title={<FormattedMessage id='universal_report.by_cashboxes' />}
                    visible={visible === MODALS.CASH_BOX_REPORT_MODAL}
                    width='fit-content'
                >
                    <Select
                        allowClear
                        dropdownStyle={{ overflow: 'auto', zIndex: '9999' }}
                        onChange={cashBoxId => {
                            this.setState({ cashBoxId });
                        }}
                        optionFilterProp='children'
                        placeholder={this.props.intl.formatMessage({
                            id: 'cash-order-form.cashbox'
                        })}
                        showSearch
                        style={{
                            marginBottom: 8
                        }}
                        value={cashBoxId}
                    >
                        {cashboxes
                            ? cashboxes
                                  .filter(({ active }) => active)
                                  .map(({ name, id }) => (
                                      <Option key={id} id={id}>
                                          {name}
                                      </Option>
                                  ))
                            : undefined}
                    </Select>

                    {detailing !== 'without' && (
                        <Select
                            allowClear
                            dropdownStyle={{ overflow: 'auto', zIndex: '9999' }}
                            onChange={analyticsId => {
                                this.setState({ analyticsId });
                            }}
                            optionFilterProp='children'
                            placeholder={this.props.intl.formatMessage({
                                id: 'store_doc_service.analytics'
                            })}
                            showSearch
                            style={{
                                marginBottom: 8
                            }}
                            value={analyticsId}
                        >
                            {analytics.map(({ analyticsUniqueId, analyticsName }) => (
                                <Option key={analyticsUniqueId} value={analyticsUniqueId}>
                                    {analyticsName}
                                </Option>
                            ))}
                        </Select>
                    )}

                    {detailing === 'documents' && (
                        <Select
                            allowClear
                            dropdownStyle={{ zIndex: '9999' }}
                            onChange={employeeId => {
                                this.setState({ employeeId });
                            }}
                            optionFilterProp='children'
                            placeholder={this.props.intl.formatMessage({
                                id: 'employee.is_cashier'
                            })}
                            showSearch
                            style={{
                                marginBottom: 8
                            }}
                            value={employeeId}
                        >
                            {(employees || [])
                                .filter(({ isCashier }) => isCashier)
                                .map(({ id, name, surname }) => (
                                    <Option key={`employee-${id}`} value={id}>
                                        {`${surname} ${name} `}
                                    </Option>
                                ))}
                        </Select>
                    )}
                    <DateRangePicker
                        dateRange={[fromDate, toDate]}
                        onDateChange={arr => {
                            this.setState({ fromDate: arr[0] });
                            this.setState({ toDate: arr[1] });
                        }}
                        style={{
                            width: '100%'
                        }}
                    />
                    <div>
                        <div>
                            <FormattedMessage id='date_detailing' />
                        </div>
                        <Radio.Group onChange={e => this.setState({ detailing: e.target.value })} value={detailing}>
                            <Radio value='without'>
                                <FormattedMessage id='date_without_detailing' />
                            </Radio>
                            <Radio value='analytics'>
                                <FormattedMessage id='by_analysts' />
                            </Radio>
                            <Radio value='documents'>
                                <FormattedMessage id='by_documents' />
                            </Radio>
                        </Radio.Group>
                    </div>
                </Modal>
            </div>
        );
    }
}
