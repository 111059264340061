import { Button, Checkbox, Col, Radio, Row, Select, Tabs } from 'antd';
import { DateRangePicker, SalaryRulesTable } from 'components';
import { StorageFundsContainer, StorageFundsEmployeeContainer } from 'containers';
import {
    fetchEmployees,
    onChangeSalaryReportForm,
    salaryReportValues,
    selectEmployees
} from 'core/forms/salaryReportForm/duck';
import { fetchAnnualSalaryReport, fetchSalaryReport } from 'core/forms/settingSalaryForm/duck';
import dayjs from 'dayjs';
import { saveAs } from 'file-saver';
import { DecoratedCheckbox, DecoratedRadio, DecoratedSelect } from 'forms/DecoratedFields';
import _ from 'lodash';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { withRouter } from 'react-router';
import { fetchAPI, withReduxForm } from 'utils';
import { v4 } from 'uuid';
import { SalarySettingTab } from './components/salarySettindsTab';
import Styles from './styles.m.css';

const { Option } = Select;
const { TabPane } = Tabs;

/**
 * This form is used to generated and download salaries report
 * employeeId - id of selected employee
 *
 * @property { Array } [employeesIds] - Ids of employees to be included in the report, will be overwriten if a new one selected
 */
@injectIntl
@withReduxForm({
    name: 'salaryReportForm',
    actions: {
        change: onChangeSalaryReportForm,
        fetchAnnualSalaryReport,
        fetchSalaryReport,
        fetchEmployees
    },
    mapStateToProps: state => ({
        employees: selectEmployees(state)
    })
})
@withRouter
export class SalaryReportForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            employees: []
        };
    }

    componentDidMount() {
        this.getAllEmployees();

        if (this.props.location.state && this.props.location.state.downloadReport) {
            const { form, intl, employeesIds } = this.props;
            const { employees } = this.state;
            const employeeId = form.getFieldValue('employeeId');
            const employeesToBeIncluded = employeeId ? [employeeId] : employeesIds;

            const stringifiedEmployeesIds = employeesToBeIncluded
                ? JSON.stringify(employeesToBeIncluded.map(Number))
                : void 0;

            const [startDate, endDate] = form.getFieldValue('filterRangeDate');

            const detailing = form.getFieldValue('detailing');

            const advance = form.getFieldValue('advance');

            const parameters = {
                startDate: startDate.toISOString(),
                endDate: endDate.toISOString(),
                employeesIds: stringifiedEmployeesIds,
                detailing,
                advance
            };

            // this.props.fetchSalaryReport(parameters);
        }
    }

    handleSearchEmployees = _.debounce(value => {
        this.getAllEmployees(value);
    }, 1000);

    getAllEmployees = async query => {
        const employees = await fetchAPI('GET', 'employees', { query }, null, { handleErrorInternally: true });
        this.setState({
            employees
        });
    };

    newReport = async parameters => {
        const data = await fetchAPI('GET', 'employees_salaries/rules/report', { ...parameters }, null, {
            handleErrorInternally: true,
            rawResponse: true
        });
        const reportFile = await data.blob();
        const contentDispositionHeader = data.headers.get('content-disposition');
        const fileName = contentDispositionHeader.match(/^attachment; filename="(.*)"/)[1];
        await saveAs(reportFile, fileName);
    };

    render() {
        const { form, table, employeesIds, hideSettings, positions, specificUnderTab } = this.props;
        const { employees } = this.state;
        const employeeId = form.getFieldValue('employeeId');
        const employeesToBeIncluded = employeeId ? [employeeId] : employeesIds;

        const stringifiedEmployeesIds = employeesToBeIncluded
            ? JSON.stringify(employeesToBeIncluded.map(Number))
            : void 0;

        const years = Array(new Date().getFullYear() - 1970 + 1)
            .fill(1970)
            .map((val, index) => val + index)
            .reverse();

        const initValues = {
            employeeId: _.get(employeesIds, '[0]') ? String(_.get(employeesIds, '[0]')) : undefined
        };

        return (
            <div>
                <Tabs
                    defaultActiveKey={
                        this.props.location.state && this.props.location.state.downloadReport
                            ? '3'
                            : '1' || specificUnderTab || '0'
                    }
                >
                    {!hideSettings && (
                        <TabPane
                            key='0'
                            tab={this.props.intl.formatMessage({
                                id: 'navigation.settings'
                            })}
                        >
                            <SalarySettingTab employeeId={_.get(employeesIds, '[0]')} positions={positions} />
                        </TabPane>
                    )}
                    {hideSettings && (
                        <TabPane
                            key='1'
                            tab={this.props.intl.formatMessage({
                                id: 'setting-salary.salary rules'
                            })}
                        >
                            <SalaryRulesTable />
                        </TabPane>
                    )}
                    {hideSettings ? (
                        <TabPane
                            key='5'
                            disabled
                            tab={this.props.intl.formatMessage({
                                id: 'navigation.storage_funds'
                            })}
                        >
                            <StorageFundsContainer />
                        </TabPane>
                    ) : (
                        <TabPane
                            key='5'
                            disabled
                            tab={this.props.intl.formatMessage({
                                id: 'setting-salary.salary_calc'
                            })}
                        >
                            <StorageFundsEmployeeContainer employee={employeesIds} />
                        </TabPane>
                    )}
                    {/* {!hideSettings && (
                        <TabPane
                            key='empDebtContainer'
                            tab={this.props.intl.formatMessage({
                                id: 'navigation.receivables_and_payables'
                            })}
                        >
                            <EmployeeDebtContainer employeeId={_.get(employeesIds, '[0]')} />
                        </TabPane>
                    )} */}
                    <TabPane
                        key='3'
                        tab={this.props.intl.formatMessage({
                            id: 'salary-report-form.period_report_title'
                        })}
                    >
                        <Row className={Styles.row}>
                            <Col span={6} />
                            <Col className={Styles.colAlignCenter} span={12}>
                                <DecoratedSelect
                                    cnStyles={Styles.salaryReportSelect}
                                    disabled={Boolean(employeesIds)}
                                    field='employeeId'
                                    getFieldDecorator={form.getFieldDecorator}
                                    initialValue={initValues.employeeId}
                                    onSearch={input => this.handleSearchEmployees(input)}
                                    placeholder={<FormattedMessage id='cash-order-form.select_employee' />}
                                    showSearch
                                >
                                    {employees.map(eml =>
                                        !eml.disabled ? (
                                            <Option key={v4()} value={eml.id ? String(eml.id) : ''}>
                                                {eml.name || eml.surname
                                                    ? String(`${eml.name || ''} ${eml.surname || ''}`)
                                                    : 'NO NAME'}
                                            </Option>
                                        ) : null
                                    )}
                                </DecoratedSelect>
                            </Col>
                            <Col span={6} />
                        </Row>

                        <Row className={Styles.row}>
                            <Col span={6} />
                            <Col className={Styles.colAlignCenter} span={12}>
                                {form.getFieldDecorator('filterRangeDate', {
                                    valuePropName: 'dateRange',
                                    trigger: 'onDateChange',
                                    initialValue: [dayjs().startOf('month'), dayjs().endOf('month')]
                                })(<DateRangePicker />)}
                            </Col>
                            <Col span={6} />
                        </Row>

                        <Row className={Styles.row}>
                            <Col span={6} />
                            <Col className={Styles.colAlignCenter} span={12}>
                                <DecoratedRadio
                                    defaultValue={salaryReportValues.withOrders}
                                    field='detailing'
                                    formItem
                                    getFieldDecorator={form.getFieldDecorator}
                                >
                                    <Radio value={salaryReportValues.withoutDetailing}>
                                        <FormattedMessage id='date_without_detailing' />
                                    </Radio>
                                    <Radio value={salaryReportValues.withOrders}>
                                        <FormattedMessage id='salary-report-form_detailing_orders' />
                                    </Radio>
                                    <Radio value={salaryReportValues.withRows}>
                                        <FormattedMessage id='salary-report-form_detailing_rows' />
                                    </Radio>
                                </DecoratedRadio>
                            </Col>
                            <Col span={6} />
                        </Row>
                        <Row className={Styles.row}>
                            <Col span={7} />
                            <Col className={Styles.colAlignLeft} span={12}>
                                <DecoratedCheckbox
                                    field='advance'
                                    fields={{}}
                                    formItem
                                    getFieldDecorator={form.getFieldDecorator}
                                    hidden
                                >
                                    <FormattedMessage id='salary-report-form_advance_amount' />
                                </DecoratedCheckbox>
                                <Checkbox
                                    checked={form.getFieldValue('advance')}
                                    onChange={() => form.setFieldsValue({ advance: !form.getFieldValue('advance') })}
                                >
                                    <FormattedMessage id='salary-report-form_advance_amount' />
                                </Checkbox>
                            </Col>
                            <Col span={5} />
                        </Row>

                        <Row className={Styles.row}>
                            <Col className={Styles.colCenter} span={24}>
                                <Button
                                    disabled={!form.getFieldValue('filterRangeDate')}
                                    onClick={() => {
                                        const [startDate, endDate] = form.getFieldValue('filterRangeDate');

                                        const detailing = form.getFieldValue('detailing');

                                        const advance = form.getFieldValue('advance');

                                        const parameters = {
                                            startDate: startDate.format('YYYY-MM-DD'),
                                            endDate: endDate.format('YYYY-MM-DD'),
                                            employeesIds: stringifiedEmployeesIds,
                                            detailing,
                                            advance
                                        };

                                        this.newReport(parameters);
                                    }}
                                    type='primary'
                                >
                                    <FormattedMessage id='setting-salary.calculate' />
                                </Button>
                            </Col>
                        </Row>
                    </TabPane>
                    {/* {!hideSettings && (
                            <TabPane
                                key='-1'
                                tab={`${this.props.intl.formatMessage({
                                    id: 'navigation.settings'
                                })}`}
                            >
                                {table}
                        </TabPane>
                    )}

                    <TabPane
                        key='2'
                        tab={this.props.intl.formatMessage({
                            id: 'salary-report-form.period_report_title'
                        })}
                    >
                        <Row className={Styles.row}>
                            <Col span={6} />
                            <Col className={Styles.colAlignCenter} span={12}>
                                <DecoratedSelect
                                    cnStyles={Styles.salaryReportSelect}
                                    disabled={Boolean(employeesIds)}
                                    field='employeeId'
                                    getFieldDecorator={form.getFieldDecorator}
                                    initialValue={initValues.employeeId}
                                    placeholder={<FormattedMessage id='cash-order-form.select_employee' />}
                                    showSearch
                                >
                                    {employees.map(eml =>
                                        !eml.disabled ? (
                                            <Option key={v4()} value={eml.id ? String(eml.id) : ''}>
                                                {eml.name || eml.surname
                                                    ? String(`${eml.name || ''} ${eml.surname || ''}`)
                                                    : 'NO NAME'}
                                            </Option>
                                        ) : null)}
                                </DecoratedSelect>
                            </Col>
                            <Col span={6} />
                        </Row>

                        <Row className={Styles.row}>
                            <Col span={6} />
                            <Col className={Styles.colAlignCenter} span={12}>
                                {form.getFieldDecorator('filterRangeDate', {
                                    valuePropName: 'dateRange',
                                    trigger: 'onDateChange',
                                    initialValue: [dayjs().startOf('month'), dayjs().endOf('month')]
                                })(<DateRangePicker />)}
                            </Col>
                            <Col span={6} />
                        </Row>

                        <Row className={Styles.row}>
                            <Col span={6} />
                            <Col className={Styles.colAlignCenter} span={12}>
                                <DecoratedRadio
                                    defaultValue={salaryReportValues.withOrders}
                                    field='detailing'
                                    formItem
                                    getFieldDecorator={form.getFieldDecorator}
                                >
                                    <Radio value={salaryReportValues.withoutDetailing}>
                                        <FormattedMessage id='date_without_detailing' />
                                    </Radio>
                                    <Radio value={salaryReportValues.withOrders}>
                                        <FormattedMessage id='salary-report-form_detailing_orders' />
                                    </Radio>
                                    <Radio value={salaryReportValues.withLabors}>
                                        <FormattedMessage id='salary-report-form_detailing_labors' />
                                    </Radio>
                                </DecoratedRadio>
                            </Col>
                            <Col span={6} />
                        </Row>
                        <Row className={Styles.row}>
                            <Col span={7} />
                            <Col className={Styles.colAlignLeft} span={12}>
                                <DecoratedCheckbox
                                    field='advance'
                                    fields={{}}
                                    formItem
                                    getFieldDecorator={form.getFieldDecorator}
                                >
                                    <FormattedMessage id='salary-report-form_advance_amount' />
                                </DecoratedCheckbox>
                            </Col>
                            <Col span={5} />
                        </Row>

                        <Row className={Styles.row}>
                            <Col className={Styles.colCenter} span={24}>
                                <Button
                                    disabled={!form.getFieldValue('filterRangeDate')}
                                    onClick={() => {
                                        const [startDate, endDate] = form.getFieldValue('filterRangeDate');

                                        const detailing = form.getFieldValue('detailing');

                                        const advance = form.getFieldValue('advance');

                                        const parameters = {
                                            startDate: startDate.toISOString(),
                                            endDate: endDate.toISOString(),
                                            employeesIds: stringifiedEmployeesIds,
                                            detailing,
                                            advance
                                        };

                                        this.props.fetchSalaryReport(parameters);
                                    }}
                                    type='primary'
                                >
                                    <FormattedMessage id='setting-salary.calculate' />
                                </Button>
                            </Col>
                        </Row>
                    </TabPane>

                    <TabPane
                        key='4'
                        tab={this.props.intl.formatMessage({
                            id: 'salary-report-form.annual_report_title'
                        })}
                    >
                        <Row>
                            <Col span={6}>
                                <DecoratedSelect
                                    cnStyles={Styles.salaryReportSelect}
                                    field='year'
                                    getFieldDecorator={form.getFieldDecorator}
                                    initialValue={_.first(years)}
                                >
                                    {years.map(year => (
                                        <Option key={String(year)} value={year}>
                                            {String(year)}
                                        </Option>
                                    ))}
                                </DecoratedSelect>
                            </Col>
                            <Col span={6}>
                                <DecoratedSelect
                                    cnStyles={Styles.salaryReportSelect}
                                    field='type'
                                    formItem
                                    getFieldDecorator={form.getFieldDecorator}
                                    initialValue='FULL'
                                >
                                    {['FULL', 'ABOUT_RATE', 'ABOUT_PERCENTS'].map(type => (
                                        <Option key={type} value={type}>
                                            {this.props.intl.formatMessage({
                                                id: `salary-report-form.${type}`
                                            })}
                                        </Option>
                                    ))}
                                </DecoratedSelect>
                            </Col>
                            <Col span={8}>
                                <Button
                                    onClick={() => {
                                        const formValues = form.getFieldsValue(['type', 'year']);
                                        this.props.fetchAnnualSalaryReport({
                                            ...formValues,
                                            employeesIds: stringifiedEmployeesIds
                                        });
                                    }}
                                    type='primary'
                                >
                                    <FormattedMessage id='setting-salary.calculate' />
                                </Button>
                            </Col>
                        </Row>
                    </TabPane>  */}
                </Tabs>
            </div>
        );
    }
}
