import { Modal } from 'antd';
import { MODALS, resetModal, selectModal, selectModalProps } from 'core/modals/duck';
import _ from 'lodash';
import { SelectOrderModal } from 'modals';
import { StoreDocServiceContainer } from 'modals/StoreDocServiceModal/Forms';
import React, { Component } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import {
    createStoreDocService,
    resetStoreDocServiceFields,
    setStoreDocServiceFields,
    updateStoreDocService
} from './redux/duck';
import Styles from './styles.m.css';

const mapStateToProps = state => ({
    modalProps: selectModalProps(state),
    visible: selectModal(state),
    isMobile: state.ui.views.isMobile
});

const mapDispatchToProps = {
    resetModal,
    setStoreDocServiceFields,
    createStoreDocService,
    updateStoreDocService,
    resetStoreDocServiceFields
};

const modes = {
    ADD: 'ADD',
    EDIT: 'EDIT'
};

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class StoreDocServiceModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            destroyOnClose: false // when true modal will be unmounted else it will not
        };
    }

    /** Use this if some modalProps are not initialized */
    defaultModalProps = {
        mode: modes.ADD
    };

    /**
     * Handle submit depending on mode that is currently used
     */
    handleSubmit = e => {
        e.preventDefault();

        const { modalProps } = this.props;
        const mode = _.get(modalProps, 'mode', this.defaultModalProps.mode);
        const storeDocId = _.get(modalProps, 'storeDocId');

        switch (mode) {
            case modes.ADD:
                this.props.setStoreDocServiceFields({ storeDocId });
                this.props.createStoreDocService();
                break;
            case modes.EDIT:
                this.props.updateStoreDocService();
                break;
        }

        // TODO validations

        this.handleSubmitCallback();
        this.resetAllFormsAndCloseModal();
    };

    /**
     * Handle submitting callback function, used to provide store doc service back to the caller object
     */
    handleSubmitCallback = () => {
        const {
            //     fields,
            modalProps
        } = this.props;

        const onSubmit = _.get(modalProps, 'onSubmit');

        onSubmit && onSubmit();
    };

    /**
     * This is used to reset all form's fields, clear all fetched data and close modal
     */
    resetAllFormsAndCloseModal = () => {
        // change destroyOnClose to true and execute closing actions after destroyOnClose
        this.setState({ destroyOnClose: true }, () => {
            const { resetModal, resetStoreDocServiceFields, setStoreDocServiceFields, modalProps } = this.props;

            resetModal();
            resetStoreDocServiceFields();

            const onClose = _.get(modalProps, 'onClose');

            onClose && onClose();
        });
    };

    componentDidUpdate(prevProps) {
        const prevVisible = _.get(prevProps, 'visible');

        const currentVisible = _.get(this.props, 'visible');

        // Change destroyOnClose to false to stop component unmount after visible is changed
        if (!_.isEqual(prevVisible, currentVisible) && currentVisible === MODALS.STORE_DOC_SERVICE) {
            this.setState({ destroyOnClose: false });
        }
    }

    render() {
        const { visible, modalProps, mapperData, isMobile } = this.props;

        const mode = _.get(modalProps, 'mode', this.defaultModalProps.mode);

        const storeDocServiceEntity = _.get(modalProps, 'storeDocServiceEntity');

        return (
            <div>
                <Modal
                    destroyOnClose={this.state.destroyOnClose}
                    onCancel={this.resetAllFormsAndCloseModal}
                    onOk={this.handleSubmit}
                    visible={visible === MODALS.STORE_DOC_SERVICE}
                    width={!isMobile ? '70vw' :'70%'}
                >
                    <div>
                        {(() => {
                            switch (mode) {
                                case modes.ADD:
                                    return (
                                        <div className={Styles.formContainer}>
                                            <StoreDocServiceContainer
                                                mapperData={mapperData}
                                                units={this.props.units}
                                            />
                                        </div>
                                    );

                                case modes.EDIT:
                                    return (
                                        <div className={Styles.formContainer}>
                                            <StoreDocServiceContainer
                                                mapperData={mapperData}
                                                storeDocServiceEntity={storeDocServiceEntity}
                                                units={this.props.units}
                                            />
                                        </div>
                                    );

                                default:
                                    return 'Invalid mode provided, available modes are: EDIT, ADD, VIEW';
                            }
                        })()}
                    </div>
                </Modal>

                <SelectOrderModal />
            </div>
        );
    }
}
