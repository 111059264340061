import { FullscreenOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Image, Modal, Select, Upload, notification } from 'antd';
import imageCompression from 'browser-image-compression';
import { selectBrands } from 'core/brands/duck';
import { fetchSuppliers } from 'core/suppliers/duck';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { fetchAPI, getToken } from 'utils';
import { accesses, grants, isGrantAccessed } from 'utils/grants';
import Styles from '../styles.m.css';

const mapStateToProps = state => ({
    brands: selectBrands(state),
    user: state.auth,
    warehouses: state.warehouses.warehouses,
    suppliers: state.suppliers.suppliers,
    clients: state.clients.clients,
    isMobile: state.ui.views.isMobile
});

const mapDispatchToProps = {
    fetchSuppliers
};

const { Option } = Select;

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export class AddPhotoSchemeModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fileList: []
        };
    }

    // deletePhotoScheme = async () => {
    //     const { file, makeId, type } = this.state;

    //     await fetchAPI(
    //         'DELETE',
    //         'orders/vehicle/photo',
    //         undefined,
    //         {
    //             file,
    //             makeId,
    //             type
    //         },
    //         { handleErrorInternally: true }
    //     );
    // };

    getPhotoPreview = async (hash, typeId, imgVehicleType, previewHash, previewId) => {
        const photo = await fetchAPI('GET', 'orders/vehicle/photo', { hash, typeId, imgVehicleType, previewId }, null, {
            handleErrorInternally: true,
            rawResponse: true
        });

        const reportFile = await photo.blob();
        const url = URL.createObjectURL(reportFile);

        const preview = await fetchAPI('GET', 'orders/vehicle/photo', { previewHash, typeId, imgVehicleType }, null, {
            handleErrorInternally: true,
            rawResponse: true
        });

        const previewFile = await preview.blob();
        const previewUrl = URL.createObjectURL(previewFile);

        const file = {
            key: hash,
            uid: hash,
            status: 'done',
            previewUrl,
            url,
            name: imgVehicleType,
            id: previewId,
            thumbUrl: url
        };

        return file;
    };

    postPhotoScheme = async file => {
        const { type, imageType } = this.state;

        const formData = new FormData();
        const compressedFile = await imageCompression(file, {
            maxSizeMB: 2
        });
        formData.append('file', compressedFile);
        formData.append('type', type);
        formData.append('imageType', imageType);

        await fetch(`${__API_URL__}/orders/vehicle/photo`, {
            method: 'POST',
            headers: {
                Authorization: getToken()
            },
            body: formData
        })
            .then(rsp => Promise.resolve(rsp))
            .then(rsp => rsp.json())
            .then(data => this.getHash(data))
            .catch(e => {});
    };

    handleCancel = () => {
        const { hideModal } = this.props;

        hideModal();
    };

    componentDidUpdate = async prevProps => {
        const { list } = this.props;
        const { fileList } = this.state;
        if (!prevProps.visible && this.props.visible && !fileList.length) {
            const fileList = await Promise.all(
                list.map(
                    ({ imgHash, id, imgVehicleType, previewHash, previewId }) =>
                        this.getPhotoPreview(imgHash, id, imgVehicleType, previewHash, previewId)
                    // eslint-disable-next-line function-paren-newline
                )
            );
            this.setState({
                fileList
            });
        }
    };

    updateImageId = async imgId => {
        const { orderId, getPhotoList } = this.props;
        await fetchAPI('POST', 'orders/vehicle/damage/info', null, { orderId, imgId });

        getPhotoList(imgId);
    };

    render() {
        const {
            visible,
            isMobile,
            hideModal,
            fetchData,
            user,
            intl: { formatMessage }
        } = this.props;
        const { fileList, type, imageType } = this.state;

        const uploadButton = (
            <React.Fragment>
                <UploadOutlined />
                <FormattedMessage id='upload' />
            </React.Fragment>
        );

        return (
            <Modal
                className={Styles.photoModal}
                footer={null}
                loading={fetchData}
                onCancel={this.handleCancel}
                title={<FormattedMessage id='order_form_table_intake_tab.add_photo_scheme' />}
                visible={visible}
                width={isMobile ? '95%' : '50%'}
                zIndex={1000}
            >
                <div>
                    <Upload
                        action={this.postPhotoScheme}
                        beforeUpload={file => {
                            const isPNG = file.type === 'image/png';
                            const isJPG = file.type === 'image/jpeg';
                            if (!isPNG && !isJPG) {
                                notification.error({
                                    message: `${file.name} ${formatMessage({
                                        id: 'photo_modal.is_not_an_image'
                                    })}!`
                                });
                            }

                            return isPNG || isJPG;
                        }}
                        fileList={fileList}
                        itemRender={(original, file) => {
                            return (
                                <div
                                    style={{
                                        marginLeft: isMobile ? 0 : 10
                                    }}
                                >
                                    <Image
                                        preview={{
                                            src: file.url,
                                            mask: (
                                                <Button>
                                                    <FullscreenOutlined />
                                                </Button>
                                            )
                                        }}
                                        src={file.previewUrl}
                                    />

                                    <div
                                        style={{
                                            width: 200,
                                            display: 'flex',
                                            justifyContent: 'center'
                                        }}
                                    >
                                        <span
                                            className={Styles.fileName}
                                            onClick={async () => {
                                                await this.updateImageId(file.id);
                                                hideModal();
                                            }}
                                        >{`${file.name}`}</span>
                                    </div>
                                </div>
                            );
                        }}
                        listType='picture-card'
                        onRemove={false}
                    >
                        {isGrantAccessed(user, grants.OPERATIONS_ORDER_DOCUMENT_INSPECTION, accesses.ROWO) ? uploadButton : undefined}
                    </Upload>
                </div>
                {isGrantAccessed(user, grants.OPERATIONS_ORDER_DOCUMENT_INSPECTION, accesses.ROWO) ? (
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between'
                        }}
                    >
                        <Select
                            onChange={imageType => {
                                this.setState({
                                    imageType
                                });
                            }}
                            style={{
                                width: 150
                            }}
                            value={imageType}
                        >
                            <Option key='Image' value='Image'>
                                Image
                            </Option>
                            <Option key='Preview' value='Preview'>
                                Preview
                            </Option>
                        </Select>
                        <Select
                            onChange={type => {
                                this.setState({
                                    type
                                });
                            }}
                            style={{
                                width: 150
                            }}
                            value={type}
                        >
                            <Option key='mini' value='Міні'>
                                Міні
                            </Option>
                            <Option key='hatchback' value='Хетчбек'>
                                Хетчбек
                            </Option>
                            <Option key='sedan' value='Седан'>
                                Седан
                            </Option>
                            <Option key='kombi' value='Комбі'>
                                Комбі
                            </Option>
                            <Option key='kupe' value='Купе'>
                                Купе
                            </Option>
                            <Option key='cabriolet' value='Кабріолет'>
                                Кабріолет
                            </Option>
                            <Option key='suv' value='SUV'>
                                SUV
                            </Option>
                            <Option key='Off-road' value='Off-Road'>
                                Off-Road
                            </Option>
                            <Option key='MPV' value='MPV'>
                                MPV
                            </Option>
                            <Option key='limousine' value='Лімузин'>
                                Лімузин
                            </Option>
                            <Option key='pickup' value='Пікап'>
                                Пікап
                            </Option>
                            <Option key='minivan' value='Мінівен'>
                                Мінівен
                            </Option>
                            <Option key='camper' value='Кемпер'>
                                Кемпер
                            </Option>
                            <Option key='minibus' value='Мінібус'>
                                Мінібус
                            </Option>
                            <Option key='minitruck' value='Мінівантажівка'>
                                Мінівантажівка
                            </Option>
                            <Option key='truck' value='Вантажівка'>
                                Вантажівка
                            </Option>
                            <Option key='dumptruck' value='Самоскид'>
                                Самоскид
                            </Option>
                            <Option key='trashtruck' value='Сміттєвоз'>
                                Сміттєвоз
                            </Option>
                            <Option key='cistern' value='Цистерна'>
                                Цистерна
                            </Option>
                            <Option key='lkwtruck' value='Тягач'>
                                Тягач
                            </Option>
                            <Option key='minicv' value='Міні-автобус'>
                                Міні-автобус
                            </Option>
                            <Option key='lkwtruck-trailer' value='Тягач з напівпричепом'>
                                Тягач з напівпричепом
                            </Option>
                            <Option key='lkwtruck-halftrailer' value='Напівпричіп'>
                                Напівпричіп
                            </Option>
                            <Option key='bus' value='Автобус'>
                                Автобус
                            </Option>
                            <Option key='scooter' value='Скутер'>
                                Скутер
                            </Option>
                            <Option key='roadster' value='Родстер'>
                                Родстер
                            </Option>
                            <Option key='sport-bike' value='Спортбайк'>
                                Спортбайк
                            </Option>
                            <Option key='traktor' value='Трактор'>
                                Трактор
                            </Option>
                        </Select>
                    </div>
                ) : undefined}
            </Modal>
        );
    }
}
