import { Modal, Select } from 'antd';
import { MODALS, resetModal, selectModal, selectModalProps } from 'core/modals/duck';
import _ from 'lodash';
import { AddFormBrandCross, EditFormBrandCross } from 'modals/BrandsCrossModal/Forms';
import { createBrandCross, updateBrandCross } from 'modals/BrandsCrossModal/redux/duck';
import { modes } from 'modals/BrandsModal/redux/duck';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import Styles from './styles.m.css';

const { Option } = Select;

const mapStateToProps = state => ({
    modalProps: selectModalProps(state),
    visible: selectModal(state)
});

const mapDispatchToProps = {
    resetModal,
    createBrandCross,
    updateBrandCross
};

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class BrandsCrossModal extends Component {
    handleSubmit = e => {
        e.preventDefault();

        const mode = _.get(this.props, 'modalProps.mode');

        switch (mode) {
            case modes.ADD:
                this.props.createBrandCross(this.props.modalProps.brandId);
                break;
            case modes.EDIT:
                this.props.updateBrandCross();
                break;
        }

        this.props.resetModal();
    };

    render() {
        const { resetModal, visible, modalProps } = this.props;

        const mode = _.get(modalProps, 'mode');

        return (
            <div>
                <Modal
                    cancelText={<FormattedMessage id='stock_table.button.cancel' />}
                    destroyOnClose
                    okText={<FormattedMessage id='submit' />}
                    onCancel={() => {
                        resetModal();
                    }}
                    onOk={this.handleSubmit}
                    title={
                        <div className={Styles.title}>
                            <FormattedMessage id='brand_crosses_modal_add_or_edit' />
                        </div>
                    }
                    visible={visible === MODALS.BRANDS_CROSS_MODAL}
                    width='40%'
                >
                    <div style={{ minHeight: '40vh' }}>
                        {(() => {
                            switch (mode) {
                                case modes.ADD:
                                    return (
                                        <div className={Styles.formContainer}>
                                            <AddFormBrandCross />
                                        </div>
                                    );

                                case modes.EDIT:
                                    return (
                                        <div className={Styles.formContainer}>
                                            <EditFormBrandCross
                                                cross={_.get(modalProps, 'cross')}
                                            />
                                        </div>
                                    );

                                default:
                                    return 'Invalid mode provided, available modes are: EDIT, ADD, VIEW';
                            }
                        })()}
                    </div>
                </Modal>
            </div>
        );
    }
}
