import { Button, Modal } from 'antd';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';

const mapStateToProps = state => ({
    user: state.auth
});

const mapDispatchToProps = {};

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class UnpairAllActionsBtnsModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { visible, hideModal, unpairAll } = this.props;

        return (
            <Modal
                footer={null}
                onCancel={hideModal}
                title={<FormattedMessage id='navigation.paired_documents' />}
                visible={visible}
                width='15%'
            >
                <FormattedMessage id='navigation.unpair_all_rows' />
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginTop: 8
                    }}
                >
                    <div>
                        <Button
                            onClick={() => {
                                unpairAll();
                                hideModal();
                            }}
                            type='primary'
                        >
                            <FormattedMessage id='navigation.unpair_all_btn_ok' />
                        </Button>
                    </div>
                    <div>
                        <Button onClick={() => hideModal()}>
                            <FormattedMessage id='navigation.unpair_all_btn_cancel' />
                        </Button>
                    </div>
                </div>
            </Modal>
        );
    }
}
