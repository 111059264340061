import { Modal } from 'antd';
import { MODALS } from 'core/modals/duck';
import { ToSuccessForm } from 'forms';
import React, { Component } from 'react';

export default class ToSuccessModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            disabled: false
        };
    }
    // _handleToSuccessModalSubmit = () => {
    //    onStatusChange;
    // };

    render() {
        const {
            visible,
            onStatusChange,
            remainPrice,
            resetModal,
            clientId,
            orderId,
            storeDocId,
            onSubmit,
            reload,
            businessRequisiteId,
            precision
        } = this.props;
        const { disabled } = this.state;

        return (
            <Modal
                destroyOnClose
                // onOk={ () => this._handleToSuccessModalSubmit() }
                footer={null}
                maskClosable={false}
                okButtonProps={{
                    disabled
                }}
                onCancel={() => resetModal()}
                visible={visible === MODALS.TO_SUCCESS}
            >
                <ToSuccessForm
                    // handleToSuccessModalSubmit={
                    //    this._handleToSuccessModalSubmit
                    // }
                    businessRequisiteId={businessRequisiteId}
                    clientId={clientId}
                    onStatusChange={onStatusChange}
                    onSubmit={onSubmit}
                    orderId={orderId}
                    precision={precision}
                    reload={reload}
                    remainPrice={remainPrice}
                    resetModal={resetModal}
                    storeDocId={storeDocId}
                />
            </Modal>
        );
    }
}
