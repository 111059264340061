import { DatePicker, Modal, Select } from 'antd';
import { DateRangePicker } from 'components';
import { fetchClients, setClientsSearchFilter } from 'core/clients/duck';
import { MODALS, resetModal, selectModal, selectModalProps } from 'core/modals/duck';
import dayjs from 'dayjs';
import { saveAs } from 'file-saver';
import _ from 'lodash';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { fetchAPI } from 'utils';

const { Option } = Select;
const { RangePicker } = DatePicker;

const mapStateToProps = state => ({
    modalProps: selectModalProps(state),
    visible: selectModal(state),
    clients: state.clients.clients,
    user: state.auth
});

const mapDispatchToProps = {
    resetModal,
    fetchClients,
    setClientsSearchFilter
};

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class ConsolidateOrderReportModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fromDate: dayjs().startOf('month'),
            toDate: dayjs().endOf('month')
        };

        this.handleSearchClients = _.debounce(value => {
            const { setClientsSearchFilter, fetchClients } = this.props;
            setClientsSearchFilter(value);
            fetchClients();
        }, 500).bind(this);
    }

    handleOk = async () => {
        const { modalProps } = this.props;
        const { type } = modalProps;

        const { vehicleId, fromDate, toDate, clientId, businessRequisiteId } = this.state;

        const getResponse = () => {
            return fetchAPI(
                'GET',
                '/report/summary/excel/byOrders',
                {
                    createdFromDate: dayjs(fromDate).format('YYYY-MM-DD'),
                    createdToDate: dayjs(toDate).format('YYYY-MM-DD'),
                    clientId,
                    vehicleId,
                    businessRequisiteId
                },
                null,
                { rawResponse: true }
            );
        };
        const response = await getResponse();
        const reportFile = await response.blob();
        const contentDispositionHeader = response.headers.get('content-disposition');
        const fileName = contentDispositionHeader.match(/^attachment; filename="(.*)"/)[1];
        saveAs(reportFile, fileName);
        this.handleCancel();
    };

    handleCancel = () => {
        this.props.resetModal();
        this.setState({
            fromDate: dayjs().startOf('month'),
            toDate: dayjs().endOf('month'),
            vehicleId: undefined,
            clientId: undefined,
            businessRequisiteId: undefined
        });
    };

    fetchRequisites = async () => {
        const requisites = await fetchAPI('GET', 'businesses/requisites', null, null, {
            handleErrorInternally: true
        });
        this.setState({
            requisites
        });
    };

    componentDidMount() {
        this.fetchRequisites();
        this.props.fetchClients();
    }

    render() {
        const {
            modalProps,
            resetModal,
            visible,
            clients,
            intl: { formatMessage }
        } = this.props;

        const { vehicleId, fromDate, toDate, clientId, vehicles, requisites, businessRequisiteId } =
            this.state;

        const { type } = modalProps;

        return (
            <div>
                <Modal
                    cancelText={<FormattedMessage id='stock_table.button.cancel' />}
                    destroyOnClose
                    okText={<FormattedMessage id='navigation.print' />}
                    onCancel={this.handleCancel}
                    onOk={this.handleOk}
                    title={<FormattedMessage id='reports_page.consolidate_by_orders' />}
                    visible={visible === MODALS.CONSOLIDATE_ORDER_REPORT_MODAL}
                    width='fit-content'
                >
                    <Select
                        allowClear
                        getPopupContainer={trigger => trigger.parentNode}
                        onChange={businessRequisiteId => {
                            this.setState({
                                businessRequisiteId
                            });
                        }}
                        optionFilterProp='children'
                        placeholder={formatMessage({ id: 'navigation.requisites' })}
                        showSearch
                        style={{
                            marginBottom: 8
                        }}
                        value={businessRequisiteId}
                    >
                        {(requisites || []).map(({ id, name, ifi }) => (
                            <Option key={id} value={id}>
                                {name} {ifi && ` (${ifi})`}
                            </Option>
                        ))}
                    </Select>
                    <Select
                        allowClear
                        filterOption={false}
                        getPopupContainer={trigger => trigger.parentNode}
                        onChange={(clientId, option) => {
                            if (option) {
                                this.setState({
                                    clientId,
                                    vehicles: option.props.vehicles
                                });
                            } else {
                                this.setState({
                                    clientId: undefined,
                                    vehicles: undefined
                                });
                            }
                        }}
                        onSearch={this.handleSearchClients}
                        placeholder={formatMessage({ id: 'add_order_form.client' })}
                        showSearch
                        style={{
                            marginBottom: 8
                        }}
                        value={clientId}
                    >
                        {clients.map(client => (
                            <Option
                                key={client.clientId}
                                phone={_.get(client, 'phones[0]')}
                                value={client.clientId}
                                vehicles={_.get(client, 'vehicles')}
                            >
                                {`${client.surname || ''} ${client.name} ${
                                    client.middleName || ''
                                } ${_.get(client, 'phones[0]', '')}`}
                            </Option>
                        ))}
                    </Select>
                    <Select
                        allowClear
                        disabled={!vehicles}
                        getPopupContainer={trigger => trigger.parentNode}
                        onChange={vehicleId => {
                            this.setState({ vehicleId });
                        }}
                        optionFilterProp='children'
                        placeholder={formatMessage({ id: 'locations.vehicle' })}
                        showSearch
                        style={{
                            marginBottom: 8
                        }}
                        value={vehicleId}
                    >
                        {(vehicles || []).map(({ id, make, model }) => (
                            <Option key={id} value={id}>
                                {make} {model}
                            </Option>
                        ))}
                    </Select>
                    <DateRangePicker
                        dateRange={[fromDate, toDate]}
                        onDateChange={arr => {
                            this.setState({ fromDate: arr[0] });
                            this.setState({ toDate: arr[1] });
                        }}
                    />
                </Modal>
            </div>
        );
    }
}
