import { ClockCircleFilled } from '@ant-design/icons';
import { Input, Modal, Table } from 'antd';
import { Numeral } from 'commons';
import { MODALS, resetModal, selectModal, selectModalProps } from 'core/modals/duck';
import dayjs from 'dayjs';
import { debounce } from 'lodash';
import { EXPENSE } from 'pages/Storage/constants';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import book from 'routes/book';
import { fetchAPI, getCurrency } from 'utils';
import Styles from './styles.m.css';

const modes = {
    DOCUMENT_SELECT: 'DOCUMENT_SELECT'
};

const mapStateToProps = state => ({
    modalProps: selectModalProps(state),
    modal: selectModal(state)
});

const mapDispatchToProps = {
    resetModal
};

const AddProductToStoreDocModal = ({
    modal,
    resetModal,
    modalProps: { products, onConfirm, mode },
    intl: { formatMessage }
}) => {
    const open = modal === MODALS.ADD_PRODUCT_TO_STORE_DOC;
    const [docs, setDocs] = useState([]);
    const [count, setCount] = useState(0);
    const [query, setQuery] = useState();
    const [loading, setLoading] = useState(false);
    const [selectedDocId, setSelectedDocId] = useState();
    const [pagination, setPagination] = useState({ page: 1, pageSize: 10 });

    const fetchStoreDocs = useCallback(async () => {
        setLoading(true);
        const { list, stats } = await fetchAPI('GET', '/store_docs', { ...pagination, status: 'NEW', query }, null, {
            handleErrorInternally: true
        });
        setLoading(false);
        setDocs(list);
        setCount(stats.count);
    }, [pagination, query]);

    useEffect(() => {
        fetchStoreDocs();
    }, [fetchStoreDocs]);

    const handleCancel = () => {
        resetModal();
    };

    const handleQuery = debounce(e => setQuery(e.target.value), 400);

    const handleOk = async () => {
        if (mode === modes.DOCUMENT_SELECT) {
            onConfirm(selectedDocId);
            setLoading(false);
            handleCancel();

            return;
        }
        setLoading(true);
        await fetchAPI(
            'PUT',
            `/store_docs/${selectedDocId}`,
            null,
            { docProducts: products, insertMode: true },
            { handleErrorInternally: true }
        );
        setLoading(false);
        handleCancel();
        window.open(`${book.storageDocument}/${selectedDocId}`, '_blank');
    };

    const columns = useMemo(
        () => [
            { title: formatMessage({ id: 'document' }), key: 'documentNumber', dataIndex: 'documentNumber' },
            {
                title: <FormattedMessage id='storage_document.date_done' />,
                dataIndex: 'doneDatetime',
                key: 'doneDatetime',
                width: 80,
                render: (_, document) => (
                    <div>
                        {document.doneDatetime ? (
                            dayjs(document.doneDatetime).format('DD.MM.YYYY HH:mm')
                        ) : document.createdDatetime ? (
                            dayjs(document.createdDatetime).format('DD.MM.YYYY HH:mm')
                        ) : (
                            <FormattedMessage id='long_dash' />
                        )}
                    </div>
                )
            },
            {
                title: <FormattedMessage id='storage_document.counterparty' />,
                render: document => (
                    <div>
                        {document.counterpartBusinessSupplierName ||
                            document.counterpartClientName ||
                            document.counterpartEmployeeName || <FormattedMessage id='long_dash' />}
                    </div>
                )
            },
            {
                title: <FormattedMessage id='storage_document.counterparty_type' />,
                dataIndex: 'counterpartBusinessSupplierId',
                key: 'counterpartBusinessSupplierId',
                width: 80,
                render: (_, document) => (
                    <div>
                        {document.counterpartBusinessSupplierId ? (
                            <FormattedMessage id='storage_document.supplier' />
                        ) : document.counterpartClientId || document.clientId ? (
                            <FormattedMessage id='storage_document.client' />
                        ) : document.counterpartEmployeeId ? (
                            <FormattedMessage id='storage_document.own_consumpty' />
                        ) : document.counterpartWarehouseId && document.type == EXPENSE ? (
                            <FormattedMessage id='storage_document.inventory' />
                        ) : (
                            <FormattedMessage id='long_dash' />
                        )}
                    </div>
                )
            },
            {
                title: <FormattedMessage id='purchase_sum' />,
                dataIndex: 'sum',
                key: 'sum',
                width: 60,
                render: (_, document) => (
                    <Numeral
                        // TODO
                        currency={getCurrency()}
                        mask='0,0.00'
                        nullText='0'
                    >
                        {document.sum || document.totalsum}
                    </Numeral>
                )
            },
            {
                title: <FormattedMessage id='storage_document.document_status' />,
                dataIndex: 'status',
                key: 'status',
                width: 80,
                render: () => (
                    <div>
                        <React.Fragment>
                            <FormattedMessage id='storage_document.status_created' />{' '}
                            <ClockCircleFilled style={{ color: 'var(--orange)' }} />
                        </React.Fragment>
                    </div>
                )
            },
            {
                title: <FormattedMessage id='storage_document.storage_expenses' />,
                dataIndex: 'expense',
                key: 'expense',
                width: 80,
                render: (_, document) => <div>{document.warehouseName}</div>
            },
            {
                title: <FormattedMessage id='storage_document.storage_income' />,
                dataIndex: 'income',
                key: 'income',
                width: 80,
                render: (_, document) => <div>{document.counterpartWarehouseName}</div>
            }
        ],
        [formatMessage]
    );

    return (
        <Modal
            onCancel={handleCancel}
            onOk={handleOk}
            open={open}
            title={formatMessage({ id: 'add_product_to_store_doc.title' })}
            width={900}
        >
            <Input onChange={handleQuery} />
            <Table
                bordered
                columns={columns}
                current={pagination.page}
                dataSource={docs}
                loading={loading}
                onRow={product => {
                    return {
                        onClick: () => setSelectedDocId(product.id)
                    };
                }}
                pagination={{
                    ...pagination,
                    total: count,
                    onChange: page => setPagination(prev => ({ ...prev, page }))
                }}
                rowClassName={product => {
                    return product.id == selectedDocId ? Styles.selectedRow : Styles.tableRow;
                }}
                rowKey='id'
                showSizeChanger={false}
                size='small'
            />
        </Modal>
    );
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(AddProductToStoreDocModal));
