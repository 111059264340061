import { Modal, Select } from 'antd';
import { MODALS, resetModal, selectModal, selectModalProps } from 'core/modals/duck';
import _ from 'lodash';
import { AddBrandForm, EditBrandForm } from 'modals/BrandsModal/Forms';
import { createBrand, modes, selectBrandField, updateBrand } from 'modals/BrandsModal/redux/duck';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import Styles from './styles.m.css';

const { Option } = Select;

const mapStateToProps = state => ({
    modalProps: selectModalProps(state),
    visible: selectModal(state),
    fields: selectBrandField(state)
});

const mapDispatchToProps = {
    resetModal,
    createBrand,
    updateBrand
};

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export default class BrandsModal extends Component {
    handleSubmit = e => {
        e.preventDefault();

        const mode = _.get(this.props, 'modalProps.mode');

        switch (mode) {
            case modes.ADD:
                this.props.createBrand();
                break;
            case modes.EDIT:
                this.props.updateBrand();
                break;
        }

        this.props.resetModal();
    };

    render() {
        const { resetModal, visible, modalProps, fields } = this.props;

        const mode = _.get(modalProps, 'mode');
        const brandId = _.get(modalProps, 'brandId');

        return (
            <div>
                <Modal
                    cancelText={<FormattedMessage id='stock_table.button.cancel' />}
                    destroyOnClose
                    okButtonProps={{
                        disabled: !(
                            _.get(fields, 'brandId') &&
                            String(fields.brandId).match(/^[0-9]+$/) != null
                        )
                    }}
                    okText={<FormattedMessage id='submit' />}
                    onCancel={() => {
                        resetModal();
                    }}
                    onOk={this.handleSubmit}
                    title={
                        <div className={Styles.title}>
                            <FormattedMessage id='brand_modal_add_or_edit' />
                        </div>
                    }
                    visible={visible === MODALS.BRANDS_MODAL}
                    width='40%'
                >
                    <div style={{ minHeight: '40vh' }}>
                        {(() => {
                            switch (mode) {
                                case modes.ADD:
                                    return (
                                        <div className={Styles.formContainer}>
                                            <AddBrandForm
                                                getFormRefCB={this.saveVehicleFormRef} // Get form refference
                                            />
                                        </div>
                                    );

                                case modes.EDIT:
                                    return (
                                        <div className={Styles.formContainer}>
                                            <EditBrandForm
                                                brandId={brandId} // Get form refference
                                                getFormRefCB={this.saveVehicleFormRef}
                                            />
                                        </div>
                                    );

                                default:
                                    return 'Invalid mode provided, available modes are: EDIT, ADD, VIEW';
                            }
                        })()}
                    </div>
                </Modal>
            </div>
        );
    }
}
