import { Button, Modal, Select, notification } from 'antd';
import React, { useEffect, useState } from 'react';

import { pick } from 'lodash';
import { FormattedMessage, injectIntl } from 'react-intl';
import { fetchAPI } from 'utils';

const { Option } = Select;

const DiagnosticAddCustomPartsDrawer = ({
    open,
    handleClose,
    orderId,
    templateData,
    fetchDiagnosticData,
    intl,
    uniquePlaceNames,
    defaultPlaces,
    fetchDefaultPlaces
}) => {
    const [diagnosticParts, setDiagnosticParts] = useState([]);
    const [partId, setPartId] = useState();
    const [coordinateSchemeId, setCoordinateSchemeId] = useState();

    const fetchDiagnosticsParts = async () => {
        const data = await fetchAPI('GET', 'directory_of_diagnostics', null, {}, { handleErrorInternally: true });

        setDiagnosticParts(data);
    };

    const addCustomParts = async () => {
        const picked = pick(templateData, [
            'templateId',
            'groupId',
            'schemeId',
            'systemId',
            'systemDetalization',
            'coordinateSchemeId',
            'elementDetalization'
        ]);

        try {
            await fetchAPI(
                'POST',
                'order_custom_diagnostic_parts',
                null,
                {
                    orderId,
                    rows: [
                        {
                            ...picked,
                            partId,
                            coordinateSchemeId
                        }
                    ]
                },
                { handleErrorInternally: true }
            );
            await fetchDiagnosticData();
            notification.success({ message: intl.formatMessage({ id: 'barcode.success' }) });
            handleClose();
        } catch (e) {
            notification.error({ message: intl.formatMessage({ id: 'error' }) });
        }
    };

    useEffect(() => {
        if (open) {
            fetchDiagnosticsParts();
            fetchDefaultPlaces();
            setCoordinateSchemeId(undefined);
            setPartId(undefined);
        }
    }, [open]);

    return (
        <Modal
            // okButtonProps={!selectedRows.length && !currentDiagnostic.length && { disabled: true }}
            footer={
                <Button
                    disabled={!partId}
                    onClick={() => {
                        addCustomParts();
                    }}
                    type='primary'
                >
                    <FormattedMessage id='add' />
                </Button>
            }
            onCancel={handleClose}
            open={open}
            title={<FormattedMessage id='diagnostics_directories.title.add_part' />}
        >
            <Select
                allowClear
                onChange={setPartId}
                optionFilterProp='children'
                placeholder={intl.formatMessage({
                    id: 'row'
                })}
                showSearch
                style={{ minWidth: 300, maxWidth: 500, marginBottom: 14 }}
                value={partId}
            >
                {diagnosticParts.map(({ partId, partName }) => {
                    return (
                        <Option key={partId} value={partId}>
                            {partName}
                        </Option>
                    );
                })}
            </Select>
            <Select
                allowClear
                onChange={setCoordinateSchemeId}
                optionFilterProp='children'
                placeholder={intl.formatMessage({
                    id: 'order_form_table.diagnostic.position_title'
                })}
                showSearch
                style={{ minWidth: 300, maxWidth: 500 }}
                value={coordinateSchemeId}
            >
                {(defaultPlaces || []).map(({ placeName, placeId }) => {
                    return (
                        <Option key={placeId} value={placeId}>
                            {placeName}
                        </Option>
                    );
                })}
            </Select>
        </Modal>
    );
};

export default injectIntl(DiagnosticAddCustomPartsDrawer);
