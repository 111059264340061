import { CloseOutlined, PrinterOutlined, SaveOutlined, SnippetsOutlined, SwapOutlined } from '@ant-design/icons';
import { Button, Dropdown, Form, Modal, Space, Tooltip, notification } from 'antd';
import { Layout } from 'commons';
import React, { useCallback, useEffect, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

import { MODALS, resetModal, setModal } from 'core/modals/duck';
import dayjs from 'dayjs';
import { AccountingForm } from 'forms';
import _ from 'lodash';
import { AddClientModal } from 'modals';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import book from 'routes/book';
import { fetchAPI, goTo } from 'utils';
import { accesses, grants, isGrantAccessed } from 'utils/grants';
import AccountingRowsTable from './components/AccountingRowsTable';
import Styles from './styles.m.css';

const mapStateToProps = state => ({
    user: state.auth,
    modal: state.modals.modal
});

const mapDispatchToProps = {
    setModal,
    resetModal
};

const AccPage = connect(
    mapStateToProps,
    mapDispatchToProps
)(({ intl: { formatMessage }, setModal, resetModal, modal, user }) => {
    const { id } = useParams();
    const [form] = Form.useForm();
    const [docData, setDocData] = useState({
        counterpartType: 'CLIENT'
    });
    const [previousData, setPreviousData] = useState({});
    const [addClientVisible, setAddClientVisible] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [employees, setEmployees] = useState([]);
    const [clients, setClients] = useState([]);
    const [sources, setSources] = useState([]);
    const [types, setTypes] = useState([]);
    const [categories, setCategories] = useState([]);
    const [suppliers, setSuppliers] = useState([]);
    const [statuses, setStatuses] = useState([]);
    const [cashboxes, setCashboxes] = useState([]);
    const [warehouses, setWarehouses] = useState([]);

    const openAddClient = () => {
        setModal(MODALS.ADD_CLIENT, { initialPhoneNumber: docData.counterpartySearch });
    };
    const onClientAdded = async value => {
        const { clients } = await fetchAPI('GET', '/clients', null, null, {
            handleErrorInternally: true
        });

        setClients(clients);

        const client = clients.find(({ clientId }) => clientId == value) || {};
        form.setFieldsValue({
            counterpartyId: value,
            sourceId: client.sourceId
        });
        setDocData({
            ...docData,
            client: `${client.name} ${client.surname || ''} ${_.get(client.phones, '[0]', ' ')}`,
            counterpartyId: value,
            sourceId: client.sourceId
        });
    };

    const fetchDocData = useCallback(async () => {
        if (id) {
            const response = await fetchAPI('GET', '/general_ledger/acc_document_rows/', { id }, null, {
                handleErrorInternally: true
            });

            setDocData(response[0]);
            setPreviousData(response[0]);
            setTableData(response[0].rows);
        }
    }, [id]);

    const fetchEmployees = useCallback(async query => {
        const responseEmps = await fetchAPI('GET', 'employees', { query }, null, {
            handleErrorInternally: true
        });

        setEmployees(responseEmps);

        return responseEmps
            .filter(({ disabled }) => !disabled)
            .map(({ id, name, surname }) => ({ label: `${name} ${surname}`, value: id }));
    }, []);

    const fetchClients = useCallback(async query => {
        const responseClients = await fetchAPI('GET', '/clients', { filters: { query } }, null, {
            handleErrorInternally: true
        });

        setClients(responseClients.clients);

        return responseClients.clients.map(({ clientId, name, surname, phones }) => ({
            label: `${name} ${surname || ''} ${_.get(phones, '[0]', ' ')}`,
            value: clientId
        }));
    }, []);

    const fetchSuppliers = useCallback(async query => {
        const responseSuppliers = await fetchAPI('GET', '/business_suppliers_dept', { filters: { query } }, null, {
            handleErrorInternally: true
        });

        setSuppliers(responseSuppliers.businessSuppliers);

        return responseSuppliers.businessSuppliers.map(({ businesssupplierid, businessSupplierName }) => ({
            label: businessSupplierName,
            value: businesssupplierid
        }));
    }, []);
    const fetchCashboxes = useCallback(async query => {
        const response = await fetchAPI(
            'GET',
            'cash_boxes',
            {
                query,
                disabled: isGrantAccessed(user, grants.SETTINGS_CASH_REGISTERS_HIDDEN_CASH_BOX, accesses.ROWO)
                    ? true
                    : undefined
            },
            null,
            {
                handleErrorInternally: true
            }
        );

        setCashboxes(response);
    }, []);

    const fetchWarehouses = useCallback(async query => {
        const response = await fetchAPI(
            'GET',
            'warehouses',
            {
                query,
                disabled: isGrantAccessed(user, grants.SETTINGS_STORES_HIDDEN_WAREHOUSE, accesses.ROWO)
                    ? true
                    : undefined
            },
            null,
            {
                handleErrorInternally: true
            }
        );

        setWarehouses(response);
    }, []);

    const debouncedFilter = (query, type) => {
        const funcMap = {
            CLIENTS: fetchClients,
            EMPLOYEES: fetchEmployees,
            SUPPLIERS: fetchSuppliers
        };

        return funcMap[type](query);
    };

    const handleSave = useCallback(
        async st => {
            const {
                counterpartId,
                comment,
                documentDatetime,
                status,
                responsibleId,
                rows,
                documentNumber,
                counterpartType,
                warehouseId,
                cashBoxId,
                operationType
            } = docData;

            const submitValues = {
                id: Number(id),
                status: st || status,
                counterpartId,
                counterpartType,
                comment: comment || ' ',
                documentDatetime: documentDatetime ? dayjs(documentDatetime).format('YYYY-MM-DD') : undefined,
                responsibleId,
                documentNumber: String(documentNumber),
                warehouseId: warehouseId || null,
                cashBoxId: cashBoxId || null,
                operationType,
                rows: rows
                    ? rows.map(el =>
                          _.omit(el, [
                              'managerId',
                              'managerName',
                              'businessId',
                              'accountName',
                              'accDocumentId',
                              'counterpartName',
                              'operationId'
                          ]))
                    : [] || []
            };
            const validation = await form.validateFields();
            if (!validation.errorFields) {
                try {
                    await fetchAPI('PUT', '/general_ledger/acc_document', null, submitValues, {
                        handleErrorInternally: true
                    });
                    notification.success({ message: formatMessage({ id: 'barcode.success' }) });

                    window.location.reload();
                } catch (e) {
                    notification.error({ message: formatMessage({ id: 'error' }) });
                }
            }
        },
        [docData, formatMessage, id]
    );

    const changeStatus = status => {
        handleSave(status);
    };

    useEffect(() => {
        fetchDocData();
        // fetchEmployees();
        // fetchClients();
        // fetchSuppliers();
        fetchCashboxes();
        fetchWarehouses();
    }, [id]);

    const items = [
        {
            key: 'NEW',
            disabled: _.get(docData, 'status') === 'NEW',
            label: (
                <div
                    onClick={() => {
                        if (_.get(docData, 'status') !== 'NEW') {
                            changeStatus('NEW');
                        }
                    }}
                >
                    <FormattedMessage id='employee_salary_rules.status.NEW' />
                </div>
            )
        },
        {
            key: 'DONE',
            disabled:
                !_.get(docData.rows, 'length') ||
                _.get(docData, 'status') === 'CANCEL' ||
                _.get(docData, 'status') === 'DONE',
            label: (
                <div
                    onClick={() => {
                        if (
                            _.get(docData.rows, 'length') &&
                            _.get(docData, 'status') !== 'CANCEL' &&
                            _.get(docData, 'status') !== 'DONE'
                        ) {
                            changeStatus('DONE');
                        }
                    }}
                >
                    <FormattedMessage id='employee_salary_rules.status.DONE' />
                </div>
            )
        },

        {
            key: 'CANCEL',
            disabled: _.get(docData, 'status') === 'DONE' || _.get(docData, 'status') === 'CANCEL',
            label: (
                <div
                    onClick={() => {
                        if (_.get(docData, 'status') !== 'DONE' && _.get(docData, 'status') !== 'CANCEL') {
                            changeStatus('CANCEL');
                        }
                    }}
                >
                    <FormattedMessage id='employee_salary_rules.status.CANCEL' />
                </div>
            )
        }
    ];

    return (
        <Layout
            className='addInteractionsPage'
            controls={
                <Space size='small'>
                    {isGrantAccessed(
                        user,
                        grants.ACCOUNTING_ACCOUNTING_DOCUMENTS_LIST_ACCOUNTING_DOCUMENT_ACC_UNPOSTING
                    ) && (
                        <div className={Styles.headerActionBtns}>
                            <Dropdown menu={{ items }}>
                                <Button
                                    icon={
                                        <SwapOutlined
                                            style={{
                                                fontSize: 24
                                            }}
                                        />
                                    }
                                    type='text'
                                >
                                    <span style={{ verticalAlign: 'text-bottom' }}>
                                        <FormattedMessage id='change_status_dropdown.change_status' />
                                    </span>
                                </Button>
                            </Dropdown>
                        </div>
                    )}
                    <div className={Styles.headerActionBtns}>
                        <Button
                            disabled
                            icon={
                                <PrinterOutlined
                                    style={{
                                        fontSize: 24
                                    }}
                                />
                            }
                            onClick={() => {}}
                            type='text'
                        />
                    </div>
                    {isGrantAccessed(
                        user,
                        grants.ACCOUNTING_ACCOUNTING_DOCUMENTS_LIST_ACCOUNTING_DOCUMENT_ACC_UNPOSTING
                    ) && (
                        <div className={Styles.headerActionBtns}>
                            <Tooltip title={<FormattedMessage id='navigation.entries' />}>
                                <Link
                                    to={{
                                        pathname: book.journalEntries,
                                        state: {
                                            operationId: String(docData.operationId)
                                        }
                                    }}
                                >
                                    <Button
                                        icon={
                                            <SnippetsOutlined
                                                style={{
                                                    fontSize: 24
                                                }}
                                            />
                                        }
                                        type='text'
                                    />
                                </Link>
                            </Tooltip>
                        </div>
                    )}
                    <div className={Styles.headerActionBtns}>
                        <Button
                            icon={
                                <SaveOutlined
                                    style={{
                                        fontSize: 24
                                    }}
                                />
                            }
                            onClick={() => {
                                if (previousData.counterpartId !== docData.counterpartId) {
                                    Modal.confirm({
                                        title: formatMessage({
                                            id: 'acc_page.acc_doc_save_notific'
                                        }),
                                        onOk() {
                                            handleSave();
                                        },
                                        okType: 'default',
                                        onCancel() {}
                                    });
                                } else {
                                    handleSave();
                                }
                            }}
                            type='text'
                        />
                    </div>
                    <div className={Styles.headerActionBtns}>
                        <Button
                            icon={
                                <CloseOutlined
                                    style={{
                                        fontSize: 24
                                    }}
                                />
                            }
                            onClick={async () => {
                                goTo(book.accDocs);
                            }}
                            type='text'
                        />
                    </div>
                </Space>
            }
            title={
                <div>
                    <FormattedMessage id={`employee_salary_rules.status.${docData.status}`} />{' '}
                    <FormattedMessage id='acc_page.acc_doc' /> {docData.accNum}
                </div>
            }
        >
            <AccountingForm
                cashboxes={cashboxes}
                categories={categories}
                clients={clients}
                debouncedFilter={debouncedFilter}
                docData={docData}
                employees={employees}
                fetchClients={fetchClients}
                fetchEmployees={fetchEmployees}
                fetchSuppliers={fetchSuppliers}
                form={form}
                id={id}
                openAddClient={openAddClient}
                previousData={previousData}
                setData={setDocData}
                sources={sources}
                suppliers={suppliers}
                types={types}
                warehouses={warehouses}
            />
            {id && (
                <AccountingRowsTable
                    disabled={docData.status === 'DONE'}
                    docData={docData}
                    fetchDocData={fetchDocData}
                    id={id}
                    tableData={tableData}
                />
            )}
            <AddClientModal func={onClientAdded} resetModal={resetModal} visible={modal} />
        </Layout>
    );
});

export default injectIntl(AccPage);
