import { DeleteOutlined } from '@ant-design/icons';
import { Button, Checkbox, Input, InputNumber, Modal, Popconfirm, Select, Space, Table, TimePicker } from 'antd';
import { DateRangePicker } from 'components';
import dayjs from 'dayjs';
import { get } from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { fetchAPI } from 'utils';
import Styles from './styles.m.css';

const { Option } = Select;
const { confirm } = Modal;
const format = 'HH:mm';

const newRowTemplate = {
    dayOff: false,
    countOfDay: 1
};

const ScheduleModal = ({ modalProps, employeeId, fetchCalendar, fetchTableCalendar, intl: { formatMessage } }) => {
    const [dateRange, setDateRange] = useState([]);
    const [scheduleDays, setScheduleDays] = useState([]);
    const [schemeTemplates, setSchemeTemplates] = useState([]);
    const [dataSource, setDataSource] = useState([]);
    const [name, setName] = useState('');
    const [scheme, setScheme] = useState();
    const [confirmOpen, setConfirmOpen] = useState(false);
    const [nameConfirmOpen, setNameConfirmOpen] = useState(false);
    const [planFact, setPlanFact] = useState(false);

    const onDateChange = dateRange => setDateRange(dateRange);

    const normalizePatterns = useCallback(() => {
        const filler = new Array(5).fill(0);
        const mapRes = filler.map((_, index) => {
            const ds = dataSource[index];

            return [
                `pattern${index + 1}`,
                ds
                    ? {
                          dayOff: ds.dayOff,
                          countOfDay: ds.countOfDay,
                          ...(ds.dayOff
                              ? { startTime: null, endTime: null }
                              : { startTime: ds.startTime.format(format), endTime: ds.endTime.format(format) })
                      }
                    : null
            ];
        });

        return Object.fromEntries(mapRes);
    }, [dataSource]);

    const fetchSchemes = useCallback(async () => {
        const schemes = await fetchAPI('GET', '/schedule_scheme', null, null, { handleErrorInternally: true });
        setSchemeTemplates(
            schemes.map(template => ({
                ...template,
                patterns: Object.entries(template)
                    .filter(([key, value]) => key.includes('pattern') && value !== null)
                    .map(([key, value]) => {
                        if (value.startTime && value.endTime) {
                            return {
                                ...value,
                                startTime: dayjs()
                                    .set('h', value.startTime.split(':')[0])
                                    .set('m', value.startTime.split(':')[1]),
                                endTime: dayjs()
                                    .set('h', value.endTime.split(':')[0])
                                    .set('m', value.endTime.split(':')[1])
                            };
                        }

                        return value;
                    })
            }))
        );
    }, []);

    const saveScheme = async isRewrite => {
        const body = { ...normalizePatterns(), isRewrite, name };
        await fetchAPI('POST', '/schedule_scheme', null, body, {
            handleErrorInternally: true
        });
        await fetchSchemes();
        setNameConfirmOpen(false);
        setConfirmOpen(false);
    };

    const deleteScheme = async id => {
        await fetchAPI(
            'DELETE',
            '/schedule_scheme',
            null,
            { id },
            {
                handleErrorInternally: true
            }
        );
        await fetchSchemes();
    };

    useEffect(() => {
        fetchSchemes();
    }, [fetchSchemes]);

    const columns = useMemo(
        () => [
            { title: '№', render: (data, row, index) => index + 1 },
            {
                title: formatMessage({ id: 'reports_page.day_number' }),
                render: (data, row, i) => (
                    <InputNumber
                        onChange={value => {
                            setDataSource(prev =>
                                prev.map((record, index) => (index === i ? { ...record, countOfDay: value } : record)));
                        }}
                        value={data.countOfDay}
                    />
                )
            },
            {
                title: formatMessage({ id: 'reports_page.day_off' }),
                render: (row, data, i) => (
                    <Checkbox
                        checked={row.dayOff}
                        onChange={() => {
                            setDataSource(
                                dataSource.map((record, index) =>
                                    index === i ? { ...record, dayOff: !record.dayOff } : record)
                            );
                        }}
                    />
                ),
                width: 40
            },
            {
                title: formatMessage({ id: 'reports_page.work_start' }),
                render: (row, data, i) => (
                    <TimePicker
                        format={format}
                        disabled={row.dayOff}
                        // disabledTime={() => {
                        //     if (!workEnd) return null;
                        //     const boundary = dayjs(workEnd).subtract(1, 'minute');

                        //     return {
                        //         disabledHours: () => generateRange(boundary.hour() + 1, 24)
                        //         // disabledMinutes: () => generateRange(boundary.minute() + 1, 60)
                        //     };
                        // }}
                        minuteStep={30}
                        onChange={value =>
                            setDataSource(
                                dataSource.map((record, index) =>
                                    index === i ? { ...record, startTime: value } : record
                                )
                            )
                        }
                        value={row.startTime}
                    />
                )
            },
            {
                title: formatMessage({ id: 'reports_page.work_end' }),
                render: (row, data, i) => (
                    <TimePicker
                        format={format}
                        disabled={row.dayOff}
                        // disabledTime={() => {
                        //     if (!workEnd) return null;
                        //     const boundary = dayjs(workEnd).subtract(1, 'minute');

                        //     return {
                        //         disabledHours: () => generateRange(boundary.hour() + 1, 24)
                        //         // disabledMinutes: () => generateRange(boundary.minute() + 1, 60)
                        //     };
                        // }}
                        minuteStep={30}
                        onChange={value =>
                            setDataSource(
                                dataSource.map((record, index) =>
                                    index === i ? { ...record, endTime: value } : record
                                )
                            )
                        }
                        value={row.endTime}
                    />
                )
            },
            {
                title: formatMessage({ id: 'delete' }),
                render: (row, data, i) => (
                    <Button
                        icon={<DeleteOutlined />}
                        onClick={() => setDataSource(dataSource.filter((record, index) => index !== i))}
                        type='text'
                    />
                )
            }
        ],
        [dataSource, formatMessage]
    );

    const handleCancel = () => {
        setPlanFact(false);
        setScheduleDays([])
        setDataSource([])
        setDateRange([])
        setScheme(undefined);
        modalProps.onCancel();
    }

    const okDisabled =
        dataSource.map(record => record.dayOff || (!!record.startTime && !!record.endTime)).includes(false) ||
        !dataSource.length;

    const handleOk = async () => {
        const scheme = dataSource.map(el => ({
            countOfDay: el.countOfDay,
            dayOff: el.dayOff,
            endTime: el.endTime ? dayjs(el.endTime).format(format) : null,
            startTime: el.startTime ? dayjs(el.startTime).format(format) : null
        }));
        const body = {
            employeeId,
            startDate: get(dateRange, '[0]').format('YYYY-MM-DD'),
            endDate: get(dateRange, '[1]').format('YYYY-MM-DD'),
            scheme,
            planFact
        };
        await fetchAPI('POST', 'set_employee_schedule', null, body, {
            handleErrorInternally: true
        });
        handleCancel();
        fetchCalendar();
        if (fetchTableCalendar) fetchTableCalendar();
    };

    const modalFooter = (
        <Space>
            <Popconfirm
                onCancel={() => {
                    setConfirmOpen(false);
                    setNameConfirmOpen(true);
                }}
                onConfirm={() => saveScheme(true)}
                onOpenChange={newOpen => {
                    if (!newOpen) {
                        setConfirmOpen(newOpen);
                        setNameConfirmOpen(true);
                    }
                }}
                open={confirmOpen}
                title={formatMessage({ id: 'reports_page.scheme_already_exists' })}
            >
                <Popconfirm
                    description={
                        <Input
                            onChange={e => setName(e.target.value)}
                            placeholder={formatMessage({ id: 'reports_page.scheme_name' })}
                            value={name}
                        />
                    }
                    onCancel={() => setNameConfirmOpen(false)}
                    onConfirm={
                        schemeTemplates.map(({ name }) => name).includes(name)
                            ? () => setConfirmOpen(true)
                            : () => saveScheme(false)
                    }
                    onOpenChange={newOpen => !confirmOpen && setNameConfirmOpen(newOpen)}
                    open={nameConfirmOpen || confirmOpen}
                    title={formatMessage({ id: 'reports_page.scheme_name' })}
                >
                    <Button disabled={okDisabled} onClick={() => setNameConfirmOpen(true)}>
                        <FormattedMessage id='reports_page.save_scheme' />
                    </Button>
                </Popconfirm>
            </Popconfirm>
            <Button onClick={handleCancel}>
                <FormattedMessage id='cancel' />
            </Button>
            <Button disabled={okDisabled || !dateRange || !dateRange.length} onClick={handleOk} type='primary'>
                <FormattedMessage id='ok' />
            </Button>
        </Space>
    );

    

    return (
        <Modal {...modalProps} onCancel={handleCancel} footer={modalFooter} width={700}>
            <div className={Styles.container}>
                <div className={Styles.inputWrapper}>
                    <FormattedMessage id='setting-salary.period' />
                    <DateRangePicker allowClear dateRange={dateRange} onDateChange={onDateChange} />
                </div>
                <div className={Styles.inputWrapper}>
                    <FormattedMessage id='reports_page.work_scheme' />
                    <div style={{ width: 240 }}>
                        <Select
                            value={scheme}
                            onSelect={(id, { patterns }) => {setDataSource(patterns.filter(p => p)); setScheme(id)}}
                            popupMatchSelectWidth
                        >
                            {schemeTemplates.map(({ id, name, patterns, businessId }) => (
                                <Option key={id} patterns={patterns} value={id}>
                                    <div className={Styles.item}>
                                        {name}
                                        {businessId && (
                                            <Popconfirm
                                                onConfirm={e => {
                                                    e.stopPropagation();
                                                    deleteScheme(id);
                                                }}
                                            >
                                                <DeleteOutlined onClick={e => e.stopPropagation()} />
                                            </Popconfirm>
                                        )}
                                    </div>
                                </Option>
                            ))}
                        </Select>
                    </div>
                </div>
                <div className={Styles.inputWrapper}>
                    <FormattedMessage id='employee-page.plan_eq_fact' />
                    <Checkbox checked={planFact} onChange={e => setPlanFact(e.target.checked)} />
                </div>
                <Table
                    columns={columns}
                    dataSource={dataSource}
                    footer={() => (
                        <Button
                            onClick={() =>
                                setDataSource(prev =>
                                    prev.length < 5
                                        ? prev.concat({
                                              ...structuredClone(newRowTemplate),
                                              startTime: dayjs().hour(9).minute(0),
                                              endTime: dayjs().hour(19).minute(0)
                                          })
                                        : prev
                                )
                            }
                            type='primary'
                        >
                            <FormattedMessage id='regulations.add_row' />
                        </Button>
                    )}
                    pagination={false}
                    rowKey='id'
                />
            </div>
        </Modal>
    );
};

export default injectIntl(ScheduleModal);
